import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, CheckboxGroup, FormControl, FormLabel, GridItem, Input, SimpleGrid, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, useColorModeValue, useToast } from '@chakra-ui/react'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { observer } from 'mobx-react';
import OrgLookupComponent from '../../../lookups/orgLookup';
import { UserDetail } from '../../../../models/user';
import ProgressLoader from '../../../progressLoader';
import UserRoles from '../Roles';


const UserForm = (props: any) => {

    const [isProcessing, SetIsProcessing] = useState(false);
    const [userDetails, setUserDetails] = useState<UserDetail>(props.user || undefined);

    const textColor = useColorModeValue('navy.700', 'white');
    const textColorSecondary = 'gray.400';
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const brandStars = useColorModeValue('brand.500', 'brand.400');

    const toast = useToast();

    const validationSchema = yup.object({
        username: yup
            .string()
            .email('Enter a valid email')
            .required('Email is required'),



        password: props.user ? yup.string().nullable() :
            yup
                .string()
                .min(8, 'Password should be of minimum 8 characters length')
                .required('Password is required')


        ,

        firstName: yup
            .string()
            .min(1, 'First Name should be of minimum 8 characters length')
            .required('First Name is required'),
        lastName: yup
            .string()
            .min(1, 'Last Name should be of minimum 8 characters length')
            .required('Last Name is required'),

        organisationId: yup.number().required('Please Select an Organisation'),

        phoneNumber: yup
            .string()
            .required('Please Select an Valid Phone Number starting with +44')
    });

    const handleCreateUpdateUser = async (userDet: UserDetail) => {
        SetIsProcessing(true);
        debugger;
        var userResponse = {} as UserDetail;

        if (props.isNew) {
            userResponse = await props.userStore.createUser(
                userDet,
                null
            ).finally(() => { SetIsProcessing(false); });
        }
        else {
            userResponse = props.userStore.updateUser(

                userDet.userId,
                userDet

            ).finally(() => { SetIsProcessing(false); });
        }

        if (userResponse) {
            setUserDetails(userResponse);
            SetIsProcessing(false);
        }


    };

    const formik = useFormik({
        initialValues: {
            username: props.user !== undefined ? (props.user.username as string) : '',
            password: null,
            phoneNumber: '+44',
            firstName: props.user !== undefined ? (props.user.firstName as string) : '',
            lastName: props.user !== undefined ? (props.user.lastName as string) : '',
            isOrgAdmin: props.user !== undefined ? (props.user.isOrgAdmin as boolean) : undefined,
            isEnterpriseAdmin: props.user !== undefined ? (props.user.isEnterpriseAdmin as boolean) : undefined,
            organisationId:
                props.user !== undefined
                    ? (props.user.organisationId as number)
                    : undefined
        },
        validationSchema: validationSchema,
        validateOnChange: true,

        onSubmit: (values) => {
            console.log(values);
            handleCreateUpdateUser(values as UserDetail);
        }
    });

    return (

        <>
            {isProcessing ? (
                <ProgressLoader />
            ) : (
                <Tabs variant='enclosed'>
                    <TabList mb='1em'>
                        <Tab>User Details</Tab>
                        <Tab>User Roles</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <form onSubmit={formik.handleSubmit}>
                                <SimpleGrid columns={{ base: 2, sm: 1, md: 2, lg: 2 }} columnGap={3} rowGap={6} >
                                    <GridItem>
                                        <FormControl isRequired >
                                            <FormLabel>First Name</FormLabel>
                                            <Input
                                                isRequired={true}
                                                id="firstName"
                                                name="firstName"
                                                fontSize="sm"
                                                ms={{ base: '0px', md: '0px' }}
                                                type="text"
                                                placeholder="First Name"
                                                // color={textColor}
                                                fontWeight="500"
                                                size="lg"
                                                onChange={formik.handleChange}
                                                value={formik.values.firstName}
                                            />
                                        </FormControl>
                                    </GridItem>
                                    <GridItem>
                                        <FormControl isRequired >
                                            <FormLabel>Last Name</FormLabel>
                                            <Input
                                                isRequired={true}
                                                id="lastName"
                                                name="lastName"
                                                fontSize="sm"
                                                ms={{ base: '0px', md: '0px' }}
                                                type="text"
                                                placeholder="Last Name"
                                                fontWeight="500"
                                                size="lg"
                                                onChange={formik.handleChange}
                                                value={formik.values.lastName}
                                            />
                                        </FormControl>
                                    </GridItem>

                                    <GridItem>
                                        <FormControl isRequired >
                                            <FormLabel>Email</FormLabel>
                                            <Input
                                                isRequired={true}
                                                id="username"
                                                name="username"
                                                fontSize="sm"
                                                ms={{ base: '0px', md: '0px' }}
                                                type="text"
                                                placeholder="Email"
                                                fontWeight="500"
                                                size="lg"
                                                onChange={formik.handleChange}
                                                value={formik.values.username}
                                            />
                                        </FormControl>
                                    </GridItem>
                                    <GridItem>
                                        <FormControl isRequired={props.user == undefined} >
                                            <FormLabel>Password</FormLabel>
                                            <Input
                                                isRequired={true}
                                                id="password"
                                                name="password"
                                                type="password"
                                                fontSize="sm"
                                                ms={{ base: '0px', md: '0px' }}
                                                placeholder="Password"
                                                fontWeight="500"
                                                size="lg"
                                                disabled={props.user}
                                                onChange={formik.handleChange}
                                                value={formik.values.password}
                                            />
                                        </FormControl>
                                    </GridItem>
                                    <GridItem>
                                        <FormControl isRequired>
                                            <OrgLookupComponent
                                                id="organisationId"
                                                name="organisationId"
                                                label="Organisation"
                                                isRequired={true}
                                                size={"lg"}
                                                fontSize={"sm"}
                                                fontWeight={"500"}
                                                value={formik.values.organisationId}
                                                onChange={formik.handleChange}
                                                organisations={props.currentOrgs}
                                            />
                                        </FormControl>
                                    </GridItem>
                                    <GridItem>
                                        <FormControl >
                                            <FormLabel>User Type</FormLabel>
                                            <CheckboxGroup>
                                                <Stack padding={2} spacing={[1, 5]} direction={['column', 'row']}>
                                                    <Checkbox id="isOrgAdmin" onChange={formik.handleChange} name="isOrgAdmin" isChecked={formik.values.isOrgAdmin} >Organisation Admin</Checkbox>
                                                    <Checkbox id="isEnterpriseAdmin" onChange={formik.handleChange} name="isEnterpriseAdmin" isChecked={formik.values.isEnterpriseAdmin}>Enterprise Admin</Checkbox>
                                                </Stack>
                                            </CheckboxGroup>
                                        </FormControl>
                                    </GridItem>
                                </SimpleGrid>
                                <FormControl>
                                    <Button type="submit" isLoading={isProcessing} loadingText="Processing" color={brandStars} float={'right'} variant={'outline'} mt={'24px'}> {props.isNew ? "Create" : "Update"} </Button>
                                </FormControl>
                            </form >
                        </TabPanel>
                        <TabPanel>
                            <GridItem colSpan={2}>
                                <UserRoles user={userDetails} currentOrgs={props.currentOrgs} currentUser={props.currentUser} userStore={props.userStore} />
                            </GridItem>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            )}
        </>
    )
}

UserForm.propTypes = {
    user: PropTypes.any,
    currentUser: PropTypes.any,
    userStore: PropTypes.any,
    currentOrgs: PropTypes.any,
    isNew: PropTypes.bool

}

export default observer(UserForm)

