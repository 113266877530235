import { createContext, ReactNode, useContext } from 'react';
import { RootStore } from '../stores/rootStore';


let store: RootStore;
const StoreContext = createContext<RootStore | undefined>(undefined);
StoreContext.displayName = 'StoreContext';

export function useRootStore() {
  const context = useContext(StoreContext);

  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider');
  }

  return context;
}

// export function useSharedProjectLinkStore() {
//   const { sharedProjectLinkStore } = useRootStore();
//   return sharedProjectLinkStore;
// }

export function useBuilderStore() {
  const { builderStore } = useRootStore();
  return builderStore;
}

export function useProjectStore() {
  const { projectStore } = useRootStore();
  return projectStore;
}

// export function useAssetStore() {
//   const { assetStore } = useRootStore();
//   return assetStore;
// }


export function useUserStore() {
  const { userStore } = useRootStore();
  return userStore;
}

export function useOrgStore() {
  const { orgStore } = useRootStore();
  return orgStore;
}

export function useEViewerStore() {
  const { evStore } = useRootStore();
  return evStore;
}

export function RootStoreProvider({ children }: { children: ReactNode }) {
  const root = store ?? new RootStore();

  return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>;
}
