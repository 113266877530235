// Chakra imports
import { Box, Center, Flex, Image } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import Footer from 'components/footer/FooterAuthDefault';
import bgImge from 'assets/img/layout/background.png';


function AuthIllustration(props: {
  children: JSX.Element;
  illustrationBackground: string;
}) {
  const { children, illustrationBackground } = props;
  // Chakra color mode
  return (
    <Flex position="relative" h="max-content">
      <Flex
        h={{
          sm: 'initial',
          md: 'unset',
          lg: '100vh',
          xl: '97vh',
        }}
        w="100%"
        maxW={{ md: '66%', lg: '1313px' }}
        mx="auto"
        pt={{ sm: '50px', md: '0px' }}
        px={{ lg: '30px', xl: '0px' }}
        ps={{ xl: '70px' }}
        justifyContent="start"
        direction="column"
      >

        {children}
        <Box
          display={{ base: 'none', md: 'block' }}
          
          h="100%"
          minH="100vh"
          w={{ lg: '50vw', '2xl': '44vw' }}
          position="absolute"
          right="0px"
          
          bg={`url(${bgImge})` } 
          // //backgroundBlendMode={'color'}
          // bgColor={'grey'}
          // bgGradient="linear(to-b, brand.400, brand.600)" 
          alignContent={"center"} justifyContent={"center"}
        >

          <Center>
            <Image
              objectFit='cover'
              src={illustrationBackground}
              alt='Chakra UI'
            />

          </Center>
          {/* <Flex

            bg={`url(${illustrationBackground})` } 
          


            //     bg={`url(${illustrationBackground})`}
            justify="center"
            align="end"
            w="100%"
            h="100%"
            // bgSize="initial"
            // bgPosition="0%"
            position="absolute"
          /> */}
        </Box>
        <Footer />
      </Flex>
      {/* <FixedPlugin /> */}
    </Flex>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
