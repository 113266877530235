import {useContext, useEffect, useState } from 'react'
import ReactFlow, { Background, ConnectionLineType, Controls, MiniMap, useEdgesState, useNodesState } from 'reactflow';
import ProgressLoader from '../../progressLoader';
import dagre from 'dagre';
import OrganisationNodeTemplate from './OrganisationNodeTemplate';
import { AuthContext } from '../../../contexts/auth-context';
import { GetOrgChartResponse } from '../../../models/admin-api/dto';
import { useOrgStore } from '../../../contexts/RootStoreProvider';
import { Card, Flex, Text, Box } from '@chakra-ui/react';
import OrgButton from '../addNewButton/addNewOrgButton';


const OrganisationViewer = props => {

    const [isLoading, setIsLoading] = useState(false);
    const [nodes, setNodes] = useNodesState([]);
    const [edges, setEdges] = useEdgesState([]);
    const { currentUser, currentOrgs } = useContext(AuthContext);
    const orgStore = useOrgStore();
    const textColor = "navy.700";

    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const nodeWidth = 500;
    const nodeHeight = 500;

    const getLayoutedElements = (nodes, edges, direction = 'TB') => {
        const isHorizontal = direction === 'LR';
        dagreGraph.setGraph({ rankdir: direction });

        nodes.forEach((node) => {
            dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
        });

        edges.forEach((edge) => {
            dagreGraph.setEdge(edge.source, edge.target);
        });

        dagre.layout(dagreGraph);

        nodes.forEach((node) => {
            const nodeWithPosition = dagreGraph.node(node.id);
            node.targetPosition = isHorizontal ? 'left' : 'top';
            node.sourcePosition = isHorizontal ? 'right' : 'bottom';

            // We are shifting the dagre node position (anchor=center center) to the top left
            // so it matches the React Flow node anchor point (top left).

            node.position = {
                x: nodeWithPosition.x - nodeWidth / 2,
                y: nodeWithPosition.y - nodeHeight / 2
            };

            return node;
        });

        return { nodes, edges };
    };

    const nodeTypes = {
        orgNode: OrganisationNodeTemplate
    };

    const { } = getLayoutedElements(nodes, edges);

    const nodeClassName = (node) => node.type;

    const proOptions = { hideAttribution: true };

    useEffect(() => {

        setIsLoading(true);

        if (currentUser !== undefined && currentOrgs !== undefined) {
            orgStore
                .createFlowOrgChart(currentUser.uid)
                .then((result: GetOrgChartResponse) => {
                    setNodes(result.result.nodes);
                    setEdges(result.result.edges);
                    setIsLoading(false);
                });
        }
    }, []);

    return (
        <>
            {isLoading ? (
                <ProgressLoader />
            ) : (
                <Card>
                    <Flex direction='column' w='100%' >
                        <Flex align={{ lg: 'center' }} paddingTop={5} justify={{ base: 'space-between' }} w='100%' px='20px' mb='20px'>
                            <Text color={textColor} fontSize='xl' fontWeight='600' lineHeight='100%'>
                                Organisation Hierachy
                            </Text>
                            <OrgButton buttonLabel='Create New Organisation' currentUser={currentUser} currentOrgs={currentOrgs} />
                        </Flex>
                        <Box>

                            <div
                                style={{
                                    height: '650px', padding: 20
                                }}>
                                <ReactFlow
                                    fitView
                                    nodes={nodes}
                                    edges={edges}
                                    connectionLineType={ConnectionLineType.SmoothStep}
                                    nodeTypes={nodeTypes}
                                    proOptions={proOptions}
                                    className="overview"
                                >
                                    <MiniMap zoomable pannable nodeClassName={nodeClassName} />
                                    <Controls />
                                    <Background />
                                </ReactFlow>
                            </div>

                        </Box>
                    </Flex></Card>



            )}
        </>
    )
}

OrganisationViewer.propTypes = {}

export default OrganisationViewer