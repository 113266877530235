import PropTypes from 'prop-types'
import { Box, Tab, Image, TabList, TabPanel, TabPanels, Tabs, Alert, AlertIcon, SimpleGrid, GridItem, } from '@chakra-ui/react';
import FileUploader from '../../../../fileUploader';
import { observer } from 'mobx-react';
import MediaViewer from '../../../../mediaViewer';
import { useState } from 'react';
import ProgressLoader from '../../../../progressLoader';

const HotspotDialogMediaContent = (props) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isMediaLoading, setMediaIsLoading] = useState<boolean>(false);

    const handleLinkMedia = async (e: string, type: string) => {
        setIsLoading(true);
        await props.builderStore.setHotSpotLinkedData(e, type).then(() => { setIsLoading(false) });
    };

    const refreshLibrary = () => {
        setMediaIsLoading(true);
        setMediaIsLoading(false);
    }

    return (

        <>
            {isLoading ? (<ProgressLoader />) : (

                <Tabs isFitted variant='enclosed'>
                    <TabList mb='1em'>
                        <Tab>Media Viewer</Tab>
                        <Tab>Media Library</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            {props.selectedHotspot.linkedAsset !== "" ? (
                                <Image width={'100%'} src={props.selectedHotspot.linkedAsset}></Image>) : (

                                <Alert status='warning'>
                                    <AlertIcon />
                                    No media has been selected for this hotspot!
                                </Alert>

                            )}
                        </TabPanel>
                        <TabPanel>


                            <SimpleGrid columns={{ base: 1, sm: 1, md: 1, lg: 1 }} spacing={5}>

                                <GridItem colSpan={1} >
                                    <FileUploader userId={props.userId} projectId={props.builderStore.editorProjectFSId} refreshItems={() => refreshLibrary()} />
                                </GridItem>
                                <GridItem colSpan={1} >
                                    {isMediaLoading ? (<ProgressLoader />) : (
                                        <MediaViewer selectedItem={props.selectedHotspot.linkedAsset} handleLinkMedia={handleLinkMedia} userId={props.userId} projectId={props.builderStore.editorProjectFSId} refreshLibrary={refreshLibrary} />
                                    )}
                                </GridItem>
                            </SimpleGrid>


                        </TabPanel>
                    </TabPanels>
                </Tabs>)}




        </>

    );
}


HotspotDialogMediaContent.propTypes = {
    handleClose: PropTypes.any,
    userId: PropTypes.any,
    builderStore: PropTypes.any,
    selectedHotspot: PropTypes.any
}

export default observer(HotspotDialogMediaContent)