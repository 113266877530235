import { Handle, Position } from 'reactflow';
import { Card, CardBody, Image, Text, Stack, Box, Tooltip, CardHeader, Icon, Center } from '@chakra-ui/react';
import { MdList, MdPeople } from 'react-icons/md';
import OrgButton from '../../addNewButton/addNewOrgButton';


export default ({ data, styles }: any) => {


  return (
    <>
      <Card >
        <Handle
          type="target"
          position={Position.Top}
          onConnect={(params) => console.log('handle onConnect', params)}
        />
        <Handle type="source" position={Position.Bottom} id="a" />
        <CardHeader alignContent={'center'}>
          <Text fontWeight={'bold'} fontSize='4xl'>{data.label}</Text>
        </CardHeader>
        <CardBody>

          <Center>
            <Box padding={3} boxSize={'250px'}>
              <Image src={data.logo} />
            </Box>
          </Center>

          {/* <Button width={'100%'} fontSize={'lg'} variant={'brand'} >Edit Details</Button> */}
          
          {data.organisation.parentOrgId !== 0 ? (

            <Box width={'100%'} fontSize={'lg'} >
              <OrgButton buttonLabel='Update Details' currentOrgs={[]} currentOrg={data.organisation} />
            </Box>) : (<></>)}

        </CardBody>
        <Stack
          style={{ padding: 10 }}
          direction="row"
          alignItems="center"
          spacing={2}
          justifyContent="space-around"
        >
          <Tooltip title="Users">
            <>
              <Icon as={MdPeople} boxSize={50} />
              <Text fontSize='3xl'>
                {data.userCount || 0}</Text>
            </>
          </Tooltip>
          <Tooltip title="Projects">
            <>
              <Icon as={MdList} boxSize={50} />
              <Text fontSize='3xl'>
                {data.projectCount || 0}
              </Text>
            </>
          </Tooltip>
        </Stack>


      </Card>




    </>
  );
};
