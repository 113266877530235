import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, ButtonGroup, IconButton, Tooltip, useDisclosure } from "@chakra-ui/react"
import React from "react"
import { FaSync, FaTrash } from "react-icons/fa"
import PropTypes from 'prop-types'

const RefreshSharedProjectButton = props => {


    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()


    const handleAction = async () => {


        props.handleAction().then(() => {

            onClose();

        });
    }

    return (
        <>



            <Tooltip label='Regenerate'>
                <IconButton icon={<FaSync />} aria-label={'Update'} size='sm' colorScheme='brand' variant='outline' onClick={onOpen} />
            </Tooltip>


            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Refresh Shared Project
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Refreshing this project will overwrite and regenerate the tour based on the latest changes.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <ButtonGroup>
                                <Button colorScheme='red' isLoading={props.isLoading} onClick={() => handleAction()} ml={3}>
                                    Regenerate
                                </Button>
                                <Button ref={cancelRef} onClick={onClose}>
                                    Cancel
                                </Button>
                            </ButtonGroup>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

RefreshSharedProjectButton.propTypes = {
    handleAction: PropTypes.any,
    isLoading: PropTypes.any
}

export default RefreshSharedProjectButton