import React, { useEffect, useState } from 'react'
import { GridItem, useToast } from '@chakra-ui/react'
import PropTypes from 'prop-types'

import 'ag-grid-enterprise';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

import AssetList from './list';
import admin from '../../../api/admin';
import { GetAssetsResponse } from '../../../models/admin-api/dto';
import { IAsset } from '../../../models/asset';



const AssetLibrary = (props: any) => {

    const [siteAssets, setSiteAssets] = useState<IAsset[]>(props.assets);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const toast = useToast();

    useEffect(() => {


        if (props.siteId == undefined) {

            toast({
                title: 'No Site Selected for Project!',
                description: 'There is no Site Associated to this Project to Obtain Assets!',
                status: 'warning',
                duration: 2000,
                isClosable: true,
            });

        }
    }, []);


    const loadSiteAssets = () => {
        setIsLoading(true);
        admin.Assets.GetAssets(props.siteId)
            .then((response: GetAssetsResponse) => {
                setSiteAssets(response.result);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <>

            <GridItem height={'450px'} colSpan={12}>
                <AssetList assets={siteAssets} handleAction={props.handleAction} />
            </GridItem>


        </>
    )
}

AssetLibrary.propTypes = {
    siteId: PropTypes.any,
    handleAction: PropTypes.any,
    assets: PropTypes.any
}



export default AssetLibrary
