import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Divider, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Show, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import NewProjectForm from '../../forms/project/new/content/newProjectForm'
import NewProjectScenes from '../../forms/project/scenes'

const AddNewProjectButton = (props) => {
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [activeStep, setActiveStep] = useState(1);

    const setCurrentStep = (stepNo: number) => {
        setActiveStep(stepNo)
        console.log("Set Active Step to " + stepNo)
    }

    const getActiveStepName = (step: number) => {
        var stepName = activeStep == 1 ? stepName = "Enter Details" : "Upload Scenes";
        return stepName;
    }


    return (
        <>
            <Button onClick={onOpen} variant={'outline'} color={textColorBrand}>New Project</Button>
            <Modal closeOnOverlayClick={false} size={'6xl'} isOpen={isOpen} isCentered onClose={onClose} motionPreset='slideInBottom' scrollBehavior={'inside'}>
                <ModalOverlay />
                <ModalContent
                >
                    <ModalHeader>New Project - {getActiveStepName(activeStep)}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {activeStep == 1 ? (
                            <NewProjectForm currentUser={props.currentUser} currentOrgs={props.currentOrgs} setActiveStep={setCurrentStep} />
                        ) : (
                            <NewProjectScenes source={'Project'}  currentUser={props.currentUser} handleClose={onClose} />
                        )}
                    </ModalBody>  <Divider />
                    {/* <ModalFooter>
                        <Hide below='md'>
                            <ProjectStepper step={activeStep} />
                        </Hide>
                    </ModalFooter> */}
                </ModalContent>
            </Modal >
        </>
    )
}

AddNewProjectButton.propTypes = {
    currentUser: PropTypes.any,
    currentOrgs: PropTypes.any
}

export default AddNewProjectButton