import { IconButton, Stack, Tooltip } from "@chakra-ui/react";
import { CustomCellRendererProps } from "ag-grid-react";
import { FaCheckCircle, FaExclamationTriangle, } from "react-icons/fa";
import ProgressLoader from "../../../../components/progressLoader";
import { useState } from "react";

import DeleteSharedProjectButton from "./ConfirmButtons/deleteSharedProjectButton";
import RefreshSharedProjectButton from "./ConfirmButtons/refreshSharedProjectButton";
import { ISharedProjectLink } from "../../../../models/SharedProjectLink";
import AddNewSharedProjectButton from "../../../../components/sharedprojects/addNewButton/addNewProjectButton";
import { checkOrgAccess } from "../../../../helpers/authHelper";


export default (params: CustomCellRendererProps) => {
   
    const [isLoading, SetIsLoading] = useState(false);
    var projectStore = params["projectStore"];

    const handleOnClickRefresh = async (spLink: ISharedProjectLink) => {
        SetIsLoading(true);
        await projectStore.regenerateSharedProject(spLink.sharedProjectLinkId).finally(() => {
            SetIsLoading(false);
        });
    }

    const handleOnClickEnableDisable = (projectId: string, enabled: boolean) => {
      alert("Not Implemented in this version")
    }

    const handleOnClickDelete = async (projectId: string) => {
        SetIsLoading(true);
        projectStore.deleteSharedProject(projectId).finally(() => {
            SetIsLoading(false);
        });
    };

    return (

        <>

            {isLoading ? <ProgressLoader /> :

                <Stack direction="row" padding={1} spacing={2}>
                    {
                        checkOrgAccess(params["currentUserRoles"], params.data?.organisationId, "share") == true ? (
                            <Tooltip label='Edit'>
                                <AddNewSharedProjectButton projectId={params.value} projectStore={params["projectStore"]} isUpdate={true} sharedProject={params.data} />
                            </Tooltip>

                        ) : (<></>)}

                    {
                        checkOrgAccess(params["currentUserRoles"], params.data?.organisationId, "share") == true ? (

                            <RefreshSharedProjectButton handleAction={() => handleOnClickRefresh(params.data)} isLoading={isLoading} />

                        ) : (<></>)}


                    {params.data.disabled ?
                        (
                            <Tooltip label='Enable (Currently Disabled)'>
                                <IconButton icon={<FaCheckCircle />} aria-label={'Enable'} size='sm' colorScheme='green' variant='outline' onClick={() => handleOnClickEnableDisable(params.value, true)} />
                            </Tooltip>
                        )
                        :
                        (
                            <Tooltip label='Disable URL '>
                                <IconButton icon={<FaExclamationTriangle />} aria-label={'Disable'} size='sm' colorScheme='orange' variant='outline' onClick={() => handleOnClickEnableDisable(params.value, false)} />
                            </Tooltip>
                        )}

                    {
                        checkOrgAccess(params["currentUserRoles"], params.data.organisationId, "delete") == true ? (

                            <DeleteSharedProjectButton handleAction={() => handleOnClickDelete(params.value)} />
                        ) : (<></>)}
                </Stack>
            }

        </>




    )
}