import axios from 'axios';

const headers = {
  'Content-Type': 'application/json',
};

export const submitSupportTicket = async (newTicketRequest: any) => {
  var supportTicketUrl =
    'https://prod-30.uksouth.logic.azure.com:443/workflows/305005cf303945879ead414eebacbf14/triggers/Fire_when_a_request_has_been_sent/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FFire_when_a_request_has_been_sent%2Frun&sv=1.0&sig=w3HrPxU2raUa9IgyrxqpYDCMjrcpdddqRsTmBYoYV7g';

  var submitResponse = await axios.post(supportTicketUrl, newTicketRequest, {
    headers: headers,
  });

  if (submitResponse.status == 200) {
    return true;
  } else {
    console.log('Error Submitting Ticket', submitResponse);
    return false;
  }
};
