import { makeAutoObservable } from 'mobx';
import admin from '../api/admin';
import { RootStore } from './rootStore';
import { AxiosError } from 'axios';
import { useToast } from '@chakra-ui/react';

export class ExternalViewerStore {
  viewData: string = '';
  isLoading: boolean = false;
  root: RootStore;
  toast = useToast();

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  getExternalViewingData = async (id: string) => {
    this.isLoading = true;
    await admin.SharedProjects.GetViewData(id)

      .then((response: any) => {
        this.viewData = response.result;
      })
      .catch((error: AxiosError) => {
        console.log(error);
        debugger;

        if (error?.response?.data! !== undefined) {
          console.log(error);

          this.toast({
            title: 'Error accessing Theia360 Shared Project!',
            description:
              'Please ensure the shared project is enabled and the URL is corrrect',
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  };
}
