// Chakra imports
import {
    Button,
    Flex,
    IconButton,
    Text,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { IMedia } from '../../../models/media';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FaCheck } from 'react-icons/fa';


export default function MediaCard(props: {
    mediaObj: IMedia;
    handleLinkMedia: any;
    selectedItem: boolean
}) {

    const textColor = 'navy.700';

    return (
        <Card p="20px">
            <Flex direction={{ base: 'column' }} justify="center">



                <LazyLoadImage
                    src={props.mediaObj.url}

                    alt={props.mediaObj.name}
                    width={'150px'}

                />

                <Flex flexDirection="column" justify="space-between" h="100%">

                    <Text mb={2} fontWeight="700" fontSize="sm" color={textColor}>
                        {props.mediaObj.name} 
                    </Text>


                    {props.selectedItem  ? (

                        <IconButton width={'full'} disabled colorScheme='green' icon={<FaCheck />} aria-label={'Selected'}>
                            Selected
                        </IconButton>


                    ) : (<Button
                        variant="brand"
                        color="white"
                        fontSize="sm"
                        fontWeight="500"
                        borderRadius="6px"
                        onClick={() => props.handleLinkMedia(props.mediaObj.thumbnailUrl, 'media')}
                    >
                        Use Media
                    </Button>
                    )}




                </Flex>
            </Flex>
        </Card>
    );
}
