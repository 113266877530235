// Chakra imports
import { FormEvent, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Text,
  useColorModeValue,
  Input,
  useToast,
} from '@chakra-ui/react';
// Assets
import illustration from 'assets/img/logos/logo.png';
import DefaultAuth from 'layouts/auth/variants/Default';
import { useNavigate } from 'react-router-dom';
import { finishEnrollMultiFactor } from '../../../../../firebase/firebase';



function ConfirmMFAEnrollment() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');

  const [mfaCode, setMfaCode] = useState('')
  const navigate = useNavigate()
  const toast = useToast();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      await finishEnrollMultiFactor(mfaCode)


      toast({
        title: 'Success!',
        description: "Phone number enrolled successfuly!",
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      navigate('/dashboard')

    } catch (error) {


      console.log(error);

      toast({
        title: 'Error!',
        description: "Unable to Validate, please ensure the code is correct.!",
        status: 'success',
        duration: 2000,
        isClosable: true,
      })

      setMfaCode('')


    }
  }

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        w="100%"
        maxW="max-content"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px', lg: '90px', xl: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', lg: '14vh', xl: '22vh' }}
        flexDirection="column"
      >
        <Box me="auto" mb="34px">
          <Heading
            color={textColor}
            fontSize="36px"
            mb="16px"
            mx={{ base: 'auto', lg: 'unset' }}
            textAlign={{ base: 'center', lg: 'left' }}
          >
            2-Step Verification
          </Heading>
          <Text
            color="gray.400"
            fontSize="md"
            maxW={{ base: '95%', md: '100%' }}
            mx={{ base: 'auto', lg: 'unset' }}
            textAlign={{ base: 'center', lg: 'left' }}
          >
            Enter your 2-Step Verification email code to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '395px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <form onSubmit={handleSubmit} >
            <FormControl>
              <Flex justify="center">

                <Input
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'

                  pattern="^[0-9]*$"
                  placeholder="Enter Mfa Code"
                  value={mfaCode}
                  mb='24px'
                  size='lg'
                  name="mfaCode"
                  onChange={(e) => setMfaCode(e.target.value)}
                />

              </Flex>
              <Button
                type='submit'
                fontSize="14px"
                variant="brand"
                borderRadius="6px"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                mt="12px"
              >
                Sign In
              </Button>
            </FormControl>
          </form>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text
              color={textColorDetails}
              fontWeight="400"
              fontSize="14px"
              mx={{ base: 'auto', lg: 'unset' }}
              textAlign={{ base: 'center', lg: 'left' }}
            >
              Haven't received it?
              <Text color={textColorBrand} as="span" ms="5px" fontWeight="500" onClick={() => navigate("/auth/mfa-enrollment")}>
                Go back and try again
              </Text>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default ConfirmMFAEnrollment;


