import React from 'react'
import PropTypes from 'prop-types'
import { Button, FormControl, FormLabel, GridItem, Input, Select, SimpleGrid, Textarea, useColorModeValue, useToast } from '@chakra-ui/react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { submitSupportTicket } from '../../../helpers/supportHelpers';

const SupportForm = (props: any) => {

    const [isLoading, setIsLoading] = React.useState(false);
    const toast = useToast();
    const location = useLocation();

    const textColor = useColorModeValue('navy.700', 'white');
    const textColorSecondary = 'gray.400';
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    const textColorBrand = useColorModeValue('brand.500', 'white');


    const validationSchema = yup.object({
        title: yup.string().required('Title is required'),
        description: yup.string().required('Description is required'),
        area: yup.string().required('Issue Area is required'),
    });



    const formik = useFormik(
        {

            initialValues: {
                title: "",
                description: "",
                area: ""
            },
            validationSchema: validationSchema,
            onSubmit: (values) => {
                console.log(values);
                createSupportTicket(values);
            }
        });

    const createSupportTicket = (values: { title: string; description: string; area: string; }) => {

        setIsLoading(true);

        var ticketRequest = {
            "issueArea": values.area,
            "title": values.title,
            "description": values.description,
            "userName": props.username,
            "userEmail": props.useremail,
            "currentPage": location.pathname
        }

        console.log("Sending", ticketRequest);

        submitSupportTicket(ticketRequest).then((response: boolean) => {


            setIsLoading(false);

            if (response == true) {


                toast({
                    title: 'Success',
                    description: "Support Ticket Successfully Sent",
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                });

                props.onClose();


            }
            else {
                toast({
                    title: 'Error',
                    description: "Support Ticket Failed to Send - Please Try Again",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
            }
        })

    }


    return (
        <form onSubmit={formik.handleSubmit}>
            <SimpleGrid columns={1} columnGap={3} rowGap={6} >
                <GridItem>
                    <FormControl isRequired >
                        <FormLabel>Title</FormLabel>
                        <Input
                            isRequired={true}
                            id="title"
                            name="title"
                            fontSize="sm"
                            ms={{ base: '0px', md: '0px' }}
                            type="text"
                            placeholder="Title"
                            color={textColor}
                            fontWeight="500"
                            size="lg"
                            onChange={formik.handleChange}
                            value={formik.values.title}
                        />

                    </FormControl>
                </GridItem>
                <FormControl>
                    <FormLabel>Issue Decription</FormLabel>
                    <Textarea
                        id="description"
                        name="description"
                        isRequired={true}
                        fontSize="sm"
                        placeholder="Please describe the issue"
                        color={textColor}
                        fontWeight="500"
                        size="lg"
                        onChange={formik.handleChange}
                        value={formik.values.description}
                    />
                </FormControl>

                <GridItem>
                    <FormControl isRequired>
                        <FormLabel>Issue Area</FormLabel>
                        <Select
                            id="area"
                            name="area"
                            onChange={formik.handleChange}
                            value={formik.values.area}
                        >
                            <option value="">Select an Area</option>
                            <option value="Projects">Project</option>
                            <option value="Builder">Builder</option>
                            <option value="Settings">Settings</option>
                            <option value="Users">Users</option>
                            <option value="UI Issue">UI Issue</option>
                            <option value="Enhancement">Enhancement</option>
                            <option value="Security Roles">Security Roles</option>
                            <option value="Other">Other</option>

                        </Select>

                    </FormControl>
                </GridItem>

                <FormControl>
                    <Button type={"submit"} isLoading={isLoading} loadingText="Creating Ticket" color={textColorBrand} variant={'outline'}>Create</Button>
                </FormControl>
            </SimpleGrid>
        </form>
    )
}

SupportForm.propTypes = {
    username: PropTypes.string,
    useremail: PropTypes.string,
    onClose: PropTypes.any
}

export default SupportForm



