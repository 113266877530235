import { useState } from 'react'
import PropTypes from 'prop-types'
import { Divider, Modal, ModalBody, ModalCloseButton, ModalContent, IconButton, ModalHeader, ModalOverlay, Show, Tooltip, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import SharedProjectForm from '../../forms/sharedProject'
import { FaEdit, FaShare } from 'react-icons/fa'

const AddNewSharedProjectButton = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>

            {props.isUpdate && props.sharedProject ?

                (
                    <Tooltip label='Update'>
                        <IconButton icon={<FaEdit />} aria-label={'Edit'} size='sm' colorScheme='brand' variant='outline' onClick={onOpen}  />
                    </Tooltip>

                )

                : (
                    <Tooltip label='Share'>
                        <IconButton icon={<FaShare />} onClick={onOpen} aria-label={'Share'} size='sm' colorScheme='brand' variant='outline' />
                    </Tooltip>

                )
            }


            <Modal closeOnOverlayClick={false} size={'6xl'} isOpen={isOpen} isCentered onClose={onClose} motionPreset='slideInBottom' scrollBehavior={'inside'}>
                <ModalOverlay />
                <ModalContent
                >
                    <ModalHeader>Share Project</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <SharedProjectForm projectId={props.projectId} projectStore={props.projectStore} sharedProject ={props.sharedProject} />
                    </ModalBody>  <Divider />
                    {/* <ModalFooter>
                        <Hide below='md'>
                            <ProjectStepper step={activeStep} />
                        </Hide>
                    </ModalFooter> */}
                </ModalContent>
            </Modal >
        </>
    )
}

AddNewSharedProjectButton.propTypes = {
    projectStore: PropTypes.any,
    projectId: PropTypes.string,
    isUpdate: PropTypes.bool,
    sharedProject: PropTypes.any
}

export default AddNewSharedProjectButton