// Chakra imports
import {
  Flex
} from '@chakra-ui/react';


import ProjectsList from './components/ProjectsList';
import { useContext } from 'react';
import { AuthContext } from '../../../contexts/auth-context';
import { observer } from 'mobx-react';
import { useProjectStore } from '../../../contexts/RootStoreProvider';

const Projects = () => {
  const { currentUser, currentOrgs, currentOrgRoles } = useContext(AuthContext);
  const projectStore = useProjectStore();


  return (
    <Flex pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column" width="stretch">
        
        <ProjectsList currentUser={currentUser} currentOrgs={currentOrgs} currentUserRoles={currentOrgRoles} projectStore={projectStore} />
      
      </Flex>
    </Flex>
  );
}

export default observer(Projects)
