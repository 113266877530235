import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, ButtonGroup, IconButton, Input, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import React, { useState } from "react"

import PropTypes from 'prop-types'

const SceneRemoveButton = props => {


    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()
    const [isLoading, setIsLoading] = useState(false);
   

   
    const handleRemoveScene = async () => {
        setIsLoading(true);
        await props.handleAction().finally(() => {
            setIsLoading(false); onClose();
        })
    }



    return (
        <>

           <Tooltip label={'Remove current Scene'}>

                <Button size={props.size} variant={props.variant} colorScheme={props.colorScheme} onClick={onOpen}>{props.buttonTitle}</Button>
            </Tooltip>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {props.actionButtonText}
                        </AlertDialogHeader>

                        <AlertDialogBody>

                            {props.confirmText} 

                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <ButtonGroup>
                                <Button colorScheme='red' isLoading={isLoading} onClick={() => handleRemoveScene()} ml={3} >
                                    {props.actionButtonText}
                                </Button>
                                <Button ref={cancelRef} onClick={onClose} >
                                    Cancel
                                </Button></ButtonGroup>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

SceneRemoveButton.propTypes = {
    handleAction: PropTypes.any,
    size: PropTypes.string,
    icon: PropTypes.any,
    buttonTitle: PropTypes.string,
    confirmText: PropTypes.string,
    actionButtonText: PropTypes.string,
    colorScheme: PropTypes.string,
    variant: PropTypes.string,
    currentScene: PropTypes.string
}

export default SceneRemoveButton
