// Custom components  
import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import Card from 'components/card/Card';
import PropTypes from 'prop-types'
import { background, Box, Button, Flex, IconButton, Input, InputGroup, InputLeftElement, InputRightAddon, Text, Tooltip, useColorModeValue, useMediaQuery } from '@chakra-ui/react';

//Data Table 
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import 'ag-grid-enterprise';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import ProjectActionsRowRenderer from './ProjectActionsRowRenderer';
// import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import ProgressLoader from '../../../../components/progressLoader';
import AddNewProjectButton from '../../../../components/projects/addNewButton/addNewProjectButton';
import projectDetail from '../../../../components/projects/projectDetail';
import { observer } from 'mobx-react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '@chakra-ui/icons';


const ProjectsList = (props: any) => {


    const [isLoading, setIsLoading] = useState(false);
    const textColor = useColorModeValue("navy.700", "white");
    const detailCellRenderer = useCallback(projectDetail, []);
    const navigate = useNavigate();
    const [useMobileCheck] = useMediaQuery('(max-width: 760px)');
    const gridRef = useRef<AgGridReact>(null);

    const searchIconColor = useColorModeValue('gray.700', 'white');
    const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
    const inputText = useColorModeValue('gray.700', 'gray.100');
    const borderColor = useColorModeValue('secondaryGray.200', 'whiteAlpha.200');

    const detailCellRendererParams = { projectStore: props.projectStore, currentUser: props.currentUser };

    function ViewProjectCellRenderer(props: ICellRendererParams) {
        return (

            <Tooltip label={"View Project"}>
                <Button onClick={() => navigate("/projects/viewer/" + props.data.vtProjectFsid)} colorScheme='brand' variant='link'>
                    {props.value}
                </Button>
            </Tooltip>
        );
    }

    function DateCellRenderer(props: ICellRendererParams) {
        try {
            return new Date(props.value).toLocaleString();
        }
        catch (ex: any) {
            console.log("Date Cell Renderer Error: ", ex);
            return props.value
        }
    }

    const onFilterTextBoxChanged = useCallback(() => {

        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);

    const colDefs = [{
        headerName: "Name", field: "vtProjectName", cellRenderer: 'agGroupCellRenderer', cellRendererParams: {
            innerRenderer: ViewProjectCellRenderer
        }
    }, { headerName: "Summary", field: "vtProjectDesc", flex: 2 },
    { headerName: "Project Code", field: "vtProjectIfaceId", flex: 1, enableRowGroup: true },

    { headerName: "Type", field: "vtProjectType", flex: 1, enableRowGroup: true ,filter: 'agMultiColumnFilter'},
    { headerName: "Organisation", field: "vtProjectOrganisationName", flex: 1, enableRowGroup: true ,filter: 'agMultiColumnFilter' },
    { headerName: "Created On", field: "vtCreatedOn", flex: 1, type: 'dateString', cellRenderer: DateCellRenderer },
    { headerName: "Created By", field: "vtCreatedBy", flex: 1, enableRowGroup: true, filter: 'agMultiColumnFilter' },
    {
        headerName: "Actions", field: "vtProjectFsid", cellRenderer: ProjectActionsRowRenderer, cellRendererParams: {
            projectStore: props.projectStore,
            currentUserRoles: props.currentUserRoles,
        }
    }
    ];

    const colDefsMobile = [{
        headerName: "Name", field: "vtProjectName", cellRenderer: 'agGroupCellRenderer', cellRendererParams: {
            innerRenderer: ViewProjectCellRenderer
        }
    },
    {
        headerName: "Actions", field: "vtProjectFsid", cellRenderer: ProjectActionsRowRenderer, cellRendererParams: {
            projectStore: props.projectStore, currentUser: props.currentUser, currentOrgs: props.currentOrgs

        }
    }
    ];

    useEffect(() => {
        setIsLoading(true);
        props.projectStore.getProjects(props.currentUser.uid).finally(() => {
            setIsLoading(false)
        })
    }, [props.userid, props.Organisations])


    return (
        <Card>
            <Flex direction='column' w='100%' >
                <Flex align={{ lg: 'center' }} justify={{ base: 'space-between' }} w='100%' px='20px' mb='20px'>
                    <Text color={textColor} fontSize='xl' fontWeight='600' lineHeight='100%'>
                        Active Projects
                    </Text>

                    <InputGroup w={{ base: '100%', md: '200px', lg: '600px' }} >
                        <InputLeftElement
                            children={
                                <IconButton
                                    aria-label="search"
                                    bg="inherit"
                                    borderRadius="inherit"
                                    _active={{
                                        bg: 'inherit',
                                        transform: 'none',
                                        borderColor: 'transparent',
                                    }}
                                    _hover={{
                                        background: 'none',
                                    }}
                                    _focus={{
                                        background: 'none',
                                        boxShadow: 'none',
                                    }}
                                    icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
                                />
                            }
                        />
                        <Input

                            onInput={onFilterTextBoxChanged}
                            id="filter-text-box"
                            variant="search"
                            fontSize="sm"
                            bg={inputBg}
                            color={inputText}
                            fontWeight="500"
                            _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                            borderRadius={'30px'}
                            placeholder={'Search Projects...'}
                            border="1px solid"
                            borderColor={borderColor}
                        />
                    </InputGroup>


                    <AddNewProjectButton currentUser={props.currentUser} currentOrgs={props.currentOrgs} />

                </Flex>
                <Box>

                    {isLoading ? (<ProgressLoader loadingText='Loading Projects' />) : (


                        <div
                            className="ag-theme-quartz" // applying the grid theme
                            style={{ height: 650 }} // the grid will fill the size of the parent container
                        >





                            <AgGridReact
                                rowGroupPanelShow={'always'}
                                ref={gridRef}
                                rowData={props.projectStore.projects}
                                masterDetail={true}
                                detailCellRenderer={detailCellRenderer}
                                detailCellRendererParams={detailCellRendererParams}
                                columnDefs={useMobileCheck ? colDefsMobile as any : colDefs as any}
                                pagination
                            />
                        </div>

                    )}

                </Box>
            </Flex>
        </Card>
    )
}

ProjectsList.propTypes = {
    currentUser: PropTypes.any,
    currentOrgs: PropTypes.any,
    projectStore: PropTypes.any,
    currentUserRoles: PropTypes.any
}

export default observer(ProjectsList)