import React from 'react'
import PropTypes from 'prop-types'
import { DeleteIcon, EditIcon, RepeatIcon } from '@chakra-ui/icons'
import { Box, Image, GridItem, IconButton, Progress, Avatar, Badge, ButtonGroup, Card, CardBody, Center, Flex, FormControl, FormLabel, Input, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, SimpleGrid, Tooltip } from '@chakra-ui/react'

const FileUploadItem = (props) => {

    const textColorBrand = 'brand.500';


    const handleRemoveFileItem = () => {
        props.removeFileItem(props.file.File.name)
    }

    const handleEditImage = () => {
        props.editImageItem(props.file.File, props.index)
    }

    

    return (


        <Card variant={'outline'} padding={2}  direction={"row"} flex={'row'}
        >        <Image width={{ base: '100%', sm: '75px', xl: '150px' }}
                src={props.file.Preview}
                alt={props.file.fileName}
            />
            <CardBody>
                <SimpleGrid columns={3} >

                    <GridItem colSpan={2}>

                        <FormControl>
                            <FormLabel>{props.file.File.name} ({props.file.Status})</FormLabel>
                        </FormControl>
                        <FormControl>
                            <ButtonGroup paddingTop={1}>
                                <Tooltip label="Edit Image" aria-label='Edit Image'>
                                    <IconButton size={"sm"} color={textColorBrand} onClick={()=>handleEditImage()} aria-label='Edit' icon={<EditIcon />} /></Tooltip>
                       
                                <Tooltip label="Remove File" aria-label='Remove File'>
                                    <IconButton size={"sm"} color={'red'} aria-label='Remove File' icon={<DeleteIcon />} onClick={() => handleRemoveFileItem()} />
                                </Tooltip>
                            </ButtonGroup>
                        </FormControl>
                    </GridItem>

                    <GridItem colSpan={1}>
                        <Center>
                            <Flex direction={'column'} minWidth='max-content' >
                                <FormLabel>Upload Progress: <Badge color={'Brand'}>{props.file.Progress}%</Badge> </FormLabel>

                                <Progress minWidth='max-content' sx={{
                                    "& > div:first-child": {
                                        transitionProperty: "width",
                                    },
                                }} hasStripe value={props.file.Progress} />
                            </Flex>
                        </Center>
                        {/* </Flex> */}
                    </GridItem>
                </SimpleGrid>

            </CardBody>

        </Card>

    )

}

FileUploadItem.propTypes =
{
    file: PropTypes.any,
    removeFileItem: PropTypes.any,
    editImageItem: PropTypes.any,
    index: PropTypes.any
}

export default FileUploadItem