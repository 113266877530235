import imageCompression from 'browser-image-compression';

//Convert a file to Base64String
export const ConvertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};


//Compress File
//https://www.npmjs.com/package/browser-image-compression
export const CompressFile = async (file) => {
  const imageFile = file;
  const options = {
    maxSizeMB: 2,
    useWebWorker: true,
  };

  try {
    const compressedFile = await imageCompression(imageFile, options);
    return compressedFile;
  } catch (error) {
    return error;
  }
};
