import React from 'react'
import { Button, Text, Divider, Image, Box, Badge, IconButton, Heading } from '@chakra-ui/react'
import { IScene } from '../../../../../../models/scene'
import { FaCheck } from 'react-icons/fa'



interface sceneItemTypes {
    scene: IScene,
    handleLinkScene: any,
    isSelected: any
}


const SceneItem = (props: sceneItemTypes) => {

    return (
        <Box style={{ 'padding': 20 }}>
            <Heading></Heading>
            <Image src={props.scene.previewURL} alt={props.scene.name} loading={'lazy'} w={'full'} />
            <Badge><Text margin={'1'} alignContent={'center'} >{props.scene.name}</Text></Badge>
            <Divider />
            {props.isSelected ? (<IconButton width={'full'} colorScheme='green' icon={<FaCheck/>} aria-label={'Selected'}>
                Selected
            </IconButton>) : (<Button width={'full'} variant={'brand'} onClick={() => props.handleLinkScene(props.scene.name)}>
                Select Scene
            </Button>
            )}

        </Box>
    );

}


export default SceneItem