// Custom components  
import React, { useState, useEffect, useCallback, useRef } from 'react';
import Card from 'components/card/Card';
import PropTypes from 'prop-types'
import { Box, Flex, IconButton, Input, InputGroup, InputLeftElement, Text, useColorModeValue } from '@chakra-ui/react';

//Data Table 
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import 'ag-grid-enterprise';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

import ProgressLoader from '../../../../components/progressLoader';
import { observer } from 'mobx-react';
import { UserDetail } from '../../../../models/user';
import UserOrganisationRowRenderer from './UserOrganisationRowRenderer';
import UserActionsRowRenderer from './UserActionsRowRenderer';
import UserDetailCellRenderer from './UserDetailsRowRenderer';
import AddNewUserButton from '../../../../components/users/addNewUserButton/addNewUserButton';
import { SearchIcon } from '@chakra-ui/icons';

const UsersList = (props: any) => {

    const textColor = useColorModeValue("navy.700", "white");
    const [isLoading, setIsLoading] = useState(false);
    const [users, SetUsers] = useState<UserDetail[]>()

    const gridRef = useRef<AgGridReact>(null);

    const searchIconColor = useColorModeValue('gray.700', 'white');
    const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
    const inputText = useColorModeValue('gray.700', 'gray.100');
    const borderColor = useColorModeValue('secondaryGray.200', 'whiteAlpha.200');

    const detailCellRenderer = useCallback(UserDetailCellRenderer, []);


    const onFilterTextBoxChanged = useCallback(() => {

        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);

    const colDefs =
        [
            { headerName: "Username", field: "username",filter: 'agMultiColumnFilter'},
            { headerName: "First Name", field: "firstName" },
            { headerName: "Last Name", field: "lastName" ,filter: 'agMultiColumnFilter'},

            { headerName: "Organisation", field: "organisationId", cellRenderer: UserOrganisationRowRenderer },
            { headerName: "Organisation Admin", cellDataType: 'boolean', field: "isOrgAdmin" },
            { headerName: "Enterprise Admin", cellDataType: 'boolean', field: "isEnterpriseAdmin" },
            { headerName: "Actions", field: "userId", cellRenderer: UserActionsRowRenderer },
        ];

    useEffect(() => {
        setIsLoading(true);
        props.userStore.GetUserList(props.currentUser.uid).finally(() => {
            SetUsers(props.userStore.users); setIsLoading(false);
        });
    }, [props.currentUser.uid, props.userStore]);


    return (
        <Card>
            <Flex direction='column' w='100%' >
                <Flex align={{ lg: 'center' }} justify={{ base: 'space-between' }} w='100%' px='20px' mb='20px'>
                    <Text color={textColor} fontSize='xl' fontWeight='600' lineHeight='100%'>
                        Active Users
                    </Text>

                    <InputGroup w={{ base: '100%', md: '200px', lg: '600px' }} >
                        <InputLeftElement
                            children={
                                <IconButton
                                    aria-label="search"
                                    bg="inherit"
                                    borderRadius="inherit"
                                    _active={{
                                        bg: 'inherit',
                                        transform: 'none',
                                        borderColor: 'transparent',
                                    }}
                                    _hover={{
                                        background: 'none',
                                    }}
                                    _focus={{
                                        background: 'none',
                                        boxShadow: 'none',
                                    }}
                                    icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
                                />
                            }
                        />
                        <Input

                            onInput={onFilterTextBoxChanged}
                            id="filter-text-box"
                            variant="search"
                            fontSize="sm"
                            bg={inputBg}
                            color={inputText}
                            fontWeight="500"
                            _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                            borderRadius={'30px'}
                            placeholder={'Search Users...'}
                            border="1px solid"
                            borderColor={borderColor}
                        />
                    </InputGroup>

                    <AddNewUserButton currentUser={props.currentUser} currentOrgs={props.currentOrgs} />
                </Flex>
                <Box>

                    {isLoading ? (<ProgressLoader loadingText='Loading Users' />) : (
                        <div
                            className="ag-theme-quartz" // applying the grid theme
                            style={{ height: 650 }} // the grid will fill the size of the parent container
                        >
                            <AgGridReact
                                ref={gridRef}
                                rowData={users}
                                masterDetail={true}
                                detailCellRenderer={detailCellRenderer}
                                columnDefs={colDefs as any}
                                pagination
                            />
                        </div>

                    )}

                </Box>
            </Flex>
        </Card>
    )
}

UsersList.propTypes = {
    currentUser: PropTypes.any,
    currentOrgs: PropTypes.any,
    userStore: PropTypes.any
}

export default observer(UsersList)