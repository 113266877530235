import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { SimpleGrid, GridItem, FormControl, FormLabel, Input, Select, Button } from '@chakra-ui/react'

const AssetForm = props => {

    const [isLoading, setIsLoading] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            Name: '',
            SiteId: null,
            Description: '',
            Type: '',
            Manufacturer: '',
            Model: '',
            SerialNo: ''
        },
        // validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log(values);
            handleCreateAsset(values);
        }
    });

    function handleCreateAsset(values: {
        Name: string;
        SiteId: number;
        Description: string;
        Type: string;
        Manufacturer: string;
        Model: string;
        SerialNo: string;
    }) {
        setIsLoading(true);
    }

    return (
        <SimpleGrid spacing={2} columns={1}>

            <form onSubmit={formik.handleSubmit}>
                <GridItem colSpan={1}>
                    <FormControl isRequired>
                        <FormLabel>Asset Name</FormLabel>
                        <Input
                            isRequired={true}
                            id="Name"
                            name="Name"
                            fontSize="sm"
                            ms={{ base: '0px', md: '0px' }}
                            type="text"
                            placeholder="Asset Name"
                            fontWeight="500"
                            size="lg"
                            onChange={formik.handleChange}
                            value={formik.values.Name}
                        />
                    </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                    <FormControl isRequired>
                        <FormLabel>Asset Type</FormLabel>
                        <Select placeholder='Select option'

                            id="type"
                            name="type"
                            value={formik.values.Type}
                            onChange={formik.handleChange}
                            onError={props.error}
                            defaultValue={null}
                        >
                            <option value='Boiler'>Boiler</option>
                            <option value='Heat Sensor'>Heat Sensor</option>
                            <option value='Basin'>Basin</option>
                        </Select>
                    </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                    <FormControl isRequired>
                        <FormLabel>Manufacturer</FormLabel>
                        <Select placeholder='Select option'>
                            <option value='Baxi'>Baxi</option>
                            <option value='Tefal'>Tefal</option>
                            <option value='Inifiniti'>Inifiniti</option>
                        </Select>
                    </FormControl>
                </GridItem>

                <GridItem colSpan={1}>
                    <FormControl isRequired>
                        <FormLabel>Model</FormLabel>
                        <Input
                            isRequired={true}
                            id="Model"
                            name="Model"
                            fontSize="sm"
                            ms={{ base: '0px', md: '0px' }}
                            type="text"
                            placeholder="Model"
                            fontWeight="500"
                            size="lg"
                            onChange={formik.handleChange}
                            value={formik.values.Model}
                        />
                    </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                    <FormControl isRequired>
                        <FormLabel>Serial No</FormLabel>
                        <Input
                            isRequired={true}
                            id="SerialNo"
                            name="SerialNo"
                            fontSize="sm"
                            ms={{ base: '0px', md: '0px' }}
                            type="text"
                            placeholder="Serial No"
                            fontWeight="500"
                            size="lg"
                            onChange={formik.handleChange}
                            value={formik.values.Name}
                        />
                    </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                    <FormControl isRequired>
                        <FormLabel>Description</FormLabel>
                        <Input
                            multiple={true}
                            isRequired={true}
                            id="Description"
                            name="Description"
                            fontSize="sm"
                            ms={{ base: '0px', md: '0px' }}
                            type="text"
                            placeholder="Description"
                            fontWeight="500"
                            size="lg"
                            onChange={formik.handleChange}
                            value={formik.values.Description}
                        />
                    </FormControl>
                </GridItem>
                {/* <Button colorScheme='brand'> Create</Button> */}
            </form>

        </SimpleGrid>
    )
}

AssetForm.propTypes = {}

export default AssetForm