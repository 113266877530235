import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tooltip, useDisclosure } from '@chakra-ui/react'
import '@pqina/pintura/pintura.css';

import { PinturaEditorModal } from "@pqina/react-pintura";
import { getEditorDefaults } from "@pqina/pintura";


const SceneImageEditorButton = (props: any) => {

    const editorDefaults = getEditorDefaults();
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        console.log(props.currentSceneObj.imageURL)
    }, [props])



    return (
        <>
            <Tooltip label={'Edit current scene'}>
                <Button size={'sm'} variant='outline' colorScheme='blue' onClick={onOpen} >
                    Edit
                </Button>
            </Tooltip>

            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Scene Image Editor (  {props.currentSceneObj?.name}  )</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody pb={6}>
                        {props.currentSceneObj ?

                            (<PinturaEditorModal

                                {...editorDefaults}
                                utils={['finetune', 'redact']}
                                src={props.currentSceneObj.imageURL}
                                onHide={() => onClose()}
                                onProcess={({ dest }) => props.onClickAction(dest)}
                            />

                            ) : (<>Oops... image loader should be here!</>)}

                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="brand" mr={3}>
                            Save
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>



    )
}

SceneImageEditorButton.propTypes = {
    onClickAction: PropTypes.any,
    currentSceneObj: PropTypes.any
}

export default SceneImageEditorButton

