// Chakra imports
import { Flex, Grid,  useColorModeValue } from '@chakra-ui/react';
// Custom components

import { VSeparator } from 'components/separator/Separator';
import WelcomeCard from './components/WelcomeCard';
import { useContext, useEffect, useState } from 'react';
import { DashMetricObj,  GetDashMetricsResponse } from '../../models/admin-api/dto';
import { AuthContext } from '../../contexts/auth-context';
import admin from '../../api/admin';
import ProjectsByOrganisation from './components/ProjectsByOrganisation';
import ProgressLoader from '../../components/progressLoader';


export default function Dashboard() {
  // Chakra Color Mode
  const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');

  const [isLoading, setIsLoading] = useState(false);
  const [userMetrics, SetUserMetrics] = useState<DashMetricObj>();
  const { currentUserDetail } = useContext(AuthContext);

  useEffect(() => {
    if (userMetrics === undefined && currentUserDetail) {
      setIsLoading(true);
      admin.DashMetrics.GetDashMetrics()
        .then((Response: GetDashMetricsResponse) => {
          SetUserMetrics(Response.result);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [userMetrics, currentUserDetail]);


  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
    >
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />
      <Flex direction="column" width="stretch">
        <Grid
          // mb="20px"
          // gridTemplateColumns={{ base: 'repeat(2, 1fr)', '2xl': '720fr 350fr' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >

          <Flex gridArea={{ base: '1 / 1 / 1 / 1', '2xl': '1 / 1 / 1 / 1' }}>
            {isLoading ? <ProgressLoader /> :
              <WelcomeCard myProjectCount={userMetrics?.myProjectsCount} currentUser={currentUserDetail} myProjectWeekCount={userMetrics?.projectsThisMonthCount} myProjectMonthCount={userMetrics?.projectsThisMonthCount} />
          }
          </Flex>

          <Flex gridArea={{ base: '1 / 2 / 1 / 4', '2xl': '1 / 2 / 1 / 4' }}>
            {isLoading ? <ProgressLoader /> :
              <ProjectsByOrganisation allProjectCount={userMetrics?.allProjectsCount} data={userMetrics?.projectsByOrgChart} />

            }

          </Flex>

        </Grid>

        <Grid
          templateColumns={{ base: 'repeat(2, 1fr)', '2xl': '350fr 720fr' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >

        </Grid>
      </Flex>

    </Flex>
  );
}
