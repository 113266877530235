// Chakra imports
import {
  Flex,
  Image,
  Text,
  useColorModeValue,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';

// Assets
import balanceImg from 'assets/img/dashboards/balanceImg.png';


export default function WelcomeCard(props: { [x: string]: any }) {
  const { ...rest } = props;

 

  // Chakra Color Mode
  const balanceBg = useColorModeValue('brand.500', '#1B254B');

  const getGreeting = () => {
    var today = new Date();
    var curHr = today.getHours();
    {
      if (curHr < 12) {
        return 'Good Morning';
      } else if (curHr < 18) {
        return 'Good Afternoon';
      } else {
        return 'Good Evening';
      }
    }
  };

  return (

    <Card flexDirection="column" w="100%" {...rest}>
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="6px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
      >
        <Flex justify="space-between" w="100%">
          <Flex flexDirection="column" justifyItems={"flex-end"} me="20px">
            <Text color="white" fontSize="sm" fontWeight="500">
              {getGreeting()}
            </Text>
            <Text
              color="white"
              fontSize="34px"
              fontWeight="700"
              lineHeight="100%"
            >
              {props.currentUser?.firstName}
            </Text>
          </Flex>
          <Flex direction="column">
            <Image src={process.env.REACT_APP_WELCOME_LOGO} width={"100px"} />
          </Flex>

        </Flex>

      </Flex>
      <Flex direction="column">
        <Text fontSize="md" fontWeight="800">You have Created...</Text>
        <StatGroup padding={3}>
          <Stat>
            <StatLabel>All Time</StatLabel>
            <StatNumber>
              <Text fontSize="40px" fontWeight="800">{props.myProjectCount}</Text></StatNumber>
          </Stat>
          <Stat>
            <StatLabel>This Week</StatLabel>
            <StatNumber><Text fontSize="40px" fontWeight="800">{props.myProjectWeekCount}</Text></StatNumber>
          </Stat>
          <Stat>
            <StatLabel>This Month</StatLabel>
            <StatNumber><Text fontSize="40px" fontWeight="800">{props.myProjectMonthCount}</Text></StatNumber>
          </Stat>

        </StatGroup>

      </Flex>

    </Card>
  );
}
