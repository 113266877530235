import React from 'react'
import PropTypes from 'prop-types'
import { Box, Spinner, Stack } from "@chakra-ui/react"

const ProgressLoader = (props: any) => {
    return (

        <Box width={'full'}>
            <Stack spacing={2}>
                {props.loadingText || "Loading"}
                {/* <Progress size={'lg'} isIndeterminate /> */}

                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='lg'
                />

            </Stack>
        </Box>

    )
}

ProgressLoader.propTypes = {
    loadingText: PropTypes.string
}

export default ProgressLoader