import PropTypes from 'prop-types'
import { Divider, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Show, Tooltip, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import ProjectForm from '../../forms/project/new/content/newProjectForm'
import { FaEdit } from 'react-icons/fa'

const EditProjectButton = (props) => {
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const { isOpen, onOpen, onClose } = useDisclosure()



    return (
        <>
            <Tooltip label='Edit Details'>
                <IconButton icon={<FaEdit />} aria-label={'Edit'} size='sm' colorScheme='brand' variant='outline' onClick={onOpen} />
            </Tooltip>
            <Modal closeOnOverlayClick={false} size={'6xl'} isOpen={isOpen} isCentered onClose={onClose} motionPreset='slideInBottom' scrollBehavior={'inside'}>
                <ModalOverlay />
                <ModalContent
                >
                    <ModalHeader>Edit Project</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <ProjectForm currentUser={props.currentUser} currentOrgs={props.currentOrgs} project={props.project} />
                    </ModalBody>  <Divider />
                </ModalContent>
            </Modal >
        </>
    )
}

EditProjectButton.propTypes = {
    currentUser: PropTypes.any,
    currentOrgs: PropTypes.any,
    project: PropTypes.any
}

export default EditProjectButton