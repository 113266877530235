import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, ButtonGroup, Divider, FormLabel, GridItem, IconButton, Input, SimpleGrid } from '@chakra-ui/react';
import { decodeSpecialCharacters, encodeSpecialCharacters } from '../../../../../helpers/stringHelpers';
import { observer } from 'mobx-react';
import ProgressLoader from '../../../../progressLoader';
import { FaLinkedin, FaVimeo, FaYoutube } from 'react-icons/fa';

const HotspotDialogIframeContent = props => {

    const [Url, setUrl] = useState('');

    useEffect(() => {
        if (
            props.builderStore.currentHotspot?.text === '' ||
            props.builderStore.currentHotspot?.text === undefined ||
            props.builderStore.currentHotspot?.text === 'undefined'
        ) {
            setUrl('');
        } else {
            setUrl(props.builderStore.currentHotspot?.text!);
        }
    }, [props.builderStore.currentHotspot]);

    const handleLinkNote = async (e: string) => {

        await props.builderStore.setHotSpotLinkedData(
            encodeSpecialCharacters(e),
            'Iframe',
            props.userId
        ).then(() => { props.handleClose() });
    };

    const handleConvertYTLink = (Url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = Url.match(regExp);

        return (match && match[2].length === 11)
            ? setUrl('https://www.youtube.com/embed/' + match[2])
            : null;
    }



    function handleChange(e) {
        setUrl(e.target.value);
    }

    return (
        <>
            <SimpleGrid columns={2} spacing={3}>

                {props.builderStore.isLoading ? (<ProgressLoader />) : (<>

                    <GridItem colSpan={1} >
                        <FormLabel>Website URL Address</FormLabel>
                        <Input
                            placeholder={'Enter Web Address URL'}
                            onChange={handleChange}
                            value={decodeSpecialCharacters(Url)}
                        />

                        <Box padding={2} >

                            <FormLabel>Embed Helpers ( Used to Convert web addresses into embed urls)</FormLabel><Divider/>
                            <Button

                                onClick={() => handleConvertYTLink(Url)}
                                colorScheme='red'
                                aria-label='Convert Youtube URL'
                                fontSize='20px'
                                leftIcon={<FaYoutube />}
                            > Youtube</Button>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={1} >
                        <FormLabel>Preview</FormLabel>

                        <iframe width={'100%'} height={'300px'} src={Url}></iframe>
                    </GridItem>
                    <GridItem colSpan={2}>
                        <Button
                            variant={'brand'}
                            bgColor={'brand'}
                            onClick={() => handleLinkNote(Url)}
                            isLoading={props.builderStore.isLoading}
                        >
                            Update Website Url
                        </Button>
                    </GridItem></>)}
            </SimpleGrid>
        </>
    )
}

HotspotDialogIframeContent.propTypes = {
    handleClose: PropTypes.any,
    userId: PropTypes.any,
    builderStore: PropTypes.any,
    selectedHotspot: PropTypes.any
}

export default observer(HotspotDialogIframeContent)