import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SimpleGrid } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import ProgressLoader from '../../../../progressLoader';
import AssetLibrary from '../../../../libraries/asset';
import { IAsset } from '../../../../../models/asset';
import AssetViewer from '../../../../assets/assetViewer';

const HotspotDialogAssetContent = props => {

    const [assetId, setAssetId] = useState<number>()

    useEffect(() => {
        if (
            props.builderStore.currentHotspot?.linkedAsset! === '' ||
            props.builderStore.currentHotspot?.linkedAsset! === undefined ||
            props.builderStore.currentHotspot?.linkedAsset! === 'undefined'
        ) {
            setAssetId(undefined);

        } else {
            setAssetId(props.builderStore.currentHotspot?.linkedAsset!);
            goToAsset();

        }
    }, [props.builderStore.currentHotspot]);


    const goToAsset = () => {

    }

    const handleLinkAsset = async (e: IAsset) => {

        await props.builderStore.setHotSpotLinkedData(
            e.assetId,
            'Asset'
        ).then(() => { 
            // props.handleClose()
        
        });
    };



    return (
        <>
            {props.builderStore.isLoading ? (<ProgressLoader />) :
                (
                    assetId == undefined ?
                        (
                            <>
                                <SimpleGrid columns={2} spacing={3}><AssetLibrary assets={props.builderStore.assets} siteId={props.builderStore.editorProjectSiteId} handleAction={handleLinkAsset} /></SimpleGrid></>)
                        : (<AssetViewer assetId={assetId} />))}
        </>
    );
}


HotspotDialogAssetContent.propTypes = {
    handleClose: PropTypes.any,
    userId: PropTypes.any,
    builderStore: PropTypes.any,
    selectedHotspot: PropTypes.any
}

export default observer(HotspotDialogAssetContent)