
// Chakra imports
import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Text, useColorModeValue, useToast } from '@chakra-ui/react';

// Custom components
import DefaultAuth from 'layouts/auth/variants/Default';

// Assets
import illustration from 'assets/img/logos/logo.png';
import { ChangeEvent, FormEvent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/auth-context';
import { startEnrollMultiFactor } from '../../../../firebase/firebase';
import phone from 'phone';
import { signOut } from 'firebase/auth';


const defaultFormFields = {
	tel: '',
	country: 'GB',
}


function MFaEnrollment() {

	// Chakra color mode
	const textColor = useColorModeValue('navy.700', 'white');
	const brandStars = useColorModeValue('brand.500', 'brand.400');
	const [formFields, setFormFields] = useState(defaultFormFields)

	const { currentUser, signOut } = useContext(AuthContext)
	const { tel } = formFields
	const toast = useToast();
	const navigate = useNavigate()

	if (!currentUser) {
		toast({
			title: 'You need to be logged in to add your phone number!',
			description: "Please sign!",
			status: 'error',
			duration: 2000,
			isClosable: true,
		})

		navigate("/");
	}

	const resetFormFields = () => {
		return (
			setFormFields(defaultFormFields)
		);
	}

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		if (!currentUser) {
			toast({
				title: 'You need to be logged in to add your phone number!',
				description: "Please sign!",
				status: 'error',
				duration: 2000,
				isClosable: true,
			})

			navigate("/auth/sign-in");
		}


		try {
			const phoneNumber = phone(tel, { country: 'GB' })

			if (phoneNumber) {
				await startEnrollMultiFactor(phoneNumber.phoneNumber)
			}

			resetFormFields()
			// Redirect to the MFA route when the phone number is successful.
			navigate('/auth/confirm-mfa-enroll')
		} catch (error) {
			console.log(error);

			toast({
				title: 'Error Enrolling Phone Number!',
				description: "Unable to validate phone number, please ensure you entered the phone number in correct!",
				status: 'error',
				duration: 6000,
				isClosable: true,
			})

		}
	}



	const handleChange = (
		event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		const { name, value } = event.target
		setFormFields({ ...formFields, [name]: value })
	}

	return (
		<DefaultAuth illustrationBackground={illustration} image={illustration}>
			<Flex
				w='100%'
				maxW='max-content'
				mx={{ base: 'auto', lg: '0px' }}
				me='auto'
				h='100%'
				alignItems='start'
				justifyContent='center'
				mb={{ base: '30px', md: '60px', lg: '120px', xl: '60px' }}
				px={{ base: '25px', md: '0px' }}
				mt={{ base: '40px', lg: '18vh', xl: '22vh' }}
				flexDirection='column'>
				<Box me='auto' mb='34px'>
					<Heading color={textColor} fontSize='20px' mb='16px'>
						Additional Step Required - Phone Number
					</Heading>

					<Text color='gray.400' fontSize='md' w='476px' maxW='100%'>
						A phone number is required to access your account
					</Text>
				</Box>
				<Flex
					zIndex='2'
					direction='column'
					w={{ base: '100%', lg: '420px' }}
					maxW='100%'
					background='transparent'
					borderRadius='15px'
					mx={{ base: 'auto', lg: 'unset' }}
					me='auto'
					mb={{ base: '20px', md: 'auto' }}>
					<form onSubmit={handleSubmit}>
						<FormControl>
							<FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
								Phone Number<Text color={brandStars}>*</Text>
							</FormLabel>
							<Input
								type='tel'
								name='tel'
								value={tel}
								onChange={handleChange}
								pattern="^\d{1,15}$" // no more than 15 digits
								isRequired={true}
								variant='auth'
								fontSize='sm'
								placeholder='Your phone number'
								mb='24px'
								size='lg'
							/>
							<Button type='submit' fontSize='14px' variant='brand' fontWeight='500' w='100%' h='50' mb='24px'>
								Submit
							</Button>

							<Text color={textColor} as="span" ms="5px" fontWeight="500" onClick={() => signOut()}>
								Sign Out

							</Text>
							<input
								id='recaptcha'
								type='submit'
								value='Send Phone'
							/>
						</FormControl>
					</form>
				</Flex>

			</Flex>
		</DefaultAuth>
	);
}

export default MFaEnrollment;
