// Chakra imports
import { Flex, useColorModeValue, Image } from '@chakra-ui/react';

// Custom components


;
export function SidebarBrand(props: { mini: boolean; hovered: boolean }) {
  const { mini, hovered } = props;
  
  

  //   Chakra color mode
  let logoColor = useColorModeValue('navy.700', 'white');
  return (
    <Flex alignItems="center" flexDirection="column">
      <Image
        my="10px"
        marginBottom={10}
        color={logoColor}
        maxH={'45px'}
        
        w="175px"
        src={  process.env.REACT_APP_LOGO}
        alt="Theia360"
        display={
          mini === false
            ? 'block'
            : mini === true && hovered === true
              ? 'block'
              : 'none'
        }
      />


    </Flex>
  );
}

export default SidebarBrand;
