


import React from 'react'
import PropTypes from 'prop-types'
import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, useColorModeValue, useDisclosure } from "@chakra-ui/react"
import SupportForm from './form'




const SupportMenu = props => {
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    return (
        <>
            <Button variant={"outline"} color={textColorBrand} ref={btnRef} onClick={onOpen}>
                Support
            </Button>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Create Support Ticket</DrawerHeader>
                    <DrawerBody>
                        <SupportForm useremail={props.useremail} username={props.username} onClose={onClose} />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

SupportMenu.propTypes = {
    username: PropTypes.string,
    useremail: PropTypes.string,

}

export default SupportMenu