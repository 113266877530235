import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Image, Card, CardBody, Flex, IconButton, Input, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, SimpleGrid, Spacer, Text, Tooltip, useColorModeValue, Progress, AspectRatio, Stack, Heading, CardFooter, Button, WrapItem, Avatar, Portal, FormLabel, FormControl, useMediaQuery, Badge, GridItem, Center, ButtonGroup } from '@chakra-ui/react'
import { DeleteIcon, EditIcon, RepeatIcon } from '@chakra-ui/icons'
import MiniSceneViewer from '../../../sceneThumbnail/miniViewer'

const SceneFileListItem = props => {

    const [sceneName, SetSceneName] = useState<string>(props.file.File.name);
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const [useMobileCheck] = useMediaQuery('(max-width: 760px)');


    const onClickUpdateSceneName = (sceneName: string) => {
        props.handleSceneChange(props.file.File.name, sceneName);
    }

    const handleSceneNameChange = (e: any) => {
        var sceneNameText = e.target.value;
        SetSceneName(sceneNameText);
        onClickUpdateSceneName(sceneNameText)
    }

    const onClickRemoveScene = () => {
        props.handleSceneRemove(props.file.File.name, props.file.Preview);
    }

    const onClickEditScene = () => {
        props.handleSceneEdit(props.file.File, props.index)
    }




    useEffect(() => {

    }, [props])


    return (

        <>

            {useMobileCheck ? (

                <Card variant={'elevated'} marginBottom={2} flex={'row'} >
                    <CardBody>
                        <SimpleGrid columns={3} >
                            <GridItem colSpan={3}>
                                <Flex flexDirection="row" >
                                    <Avatar name={sceneName} margin={2} size='lg' src={props.file.Preview} />
                                    <FormControl>
                                        <FormLabel>Scene Name
                                        </FormLabel>

                                        <Input
                                            type={'text'}
                                            defaultValue={sceneName}
                                            onChange={handleSceneNameChange}
                                        />
                                    </FormControl>
                                </Flex>
                            </GridItem>


                            <GridItem colSpan={3}>

                                <Flex direction={'row'}>
                                    <Tooltip label="Remove File" aria-label='Remove File'>
                                        <IconButton size={"sm"} color={'red'} aria-label='Remove File' icon={<DeleteIcon />} onClick={() => onClickRemoveScene()} />
                                    </Tooltip>
                                    <Popover isLazy>
                                        <Tooltip label="View as Scene" aria-label='View as scene'>
                                            <Box display="inline-block">
                                                <PopoverTrigger>
                                                    <IconButton size={"sm"} color={textColorBrand} aria-label='View as Scene' icon={<RepeatIcon />} />
                                                </PopoverTrigger>
                                            </Box>
                                        </Tooltip>
                                        <PopoverContent>
                                            <PopoverArrow />
                                            <PopoverCloseButton />
                                            <PopoverHeader>Scene Viewer</PopoverHeader>
                                            <PopoverBody>
                                                <>
                                                    <MiniSceneViewer fileName={props.file.File.name} imageUrl={props.file.Preview} id={"viewer" + props.index.toString()} />
                                                </>
                                            </PopoverBody>
                                        </PopoverContent>
                                    </Popover>
                                    <Tooltip label="Edit Image" aria-label='Edit Image'>
                                        <IconButton size={"sm"} color={textColorBrand} onClick={onClickEditScene} aria-label='Edit' icon={<EditIcon />} />
                                    </Tooltip>
                                    <GridItem >
                                    <Flex direction={'row'} >
                                            <FormLabel>Upload Progress: <Badge color={'Brand'}>{props.file.Progress}%</Badge> </FormLabel>

                                            <Progress sx={{
                                                "& > div:first-child": {
                                                    transitionProperty: "width",
                                                },
                                            }} hasStripe value={props.file.Progress} />
                                        </Flex>
                                    </GridItem>
                                </Flex>
                            </GridItem>

                        </SimpleGrid>
                    </CardBody>

                </Card >



            ) : (
                <>
                    <Card variant={'outline'} marginBottom={5} direction={"row"} flex={'row'}
                    >        <Image
                            width={{ base: '100%', sm: '100px', xl: '200px' }}
                            src={props.file.Preview}
                            alt={props.file.fileName}
                        />
                        <CardBody>
                            <SimpleGrid columns={3} >

                                <GridItem colSpan={2}>

                                    <FormControl>
                                        <FormLabel>Scene Name ({props.file.Status})  </FormLabel>
                                        <Input
                                            type={'text'}
                                            defaultValue={sceneName}
                                            onChange={handleSceneNameChange}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <ButtonGroup paddingTop={1}>
                                            <Tooltip label="Edit Image" aria-label='Edit Image'>
                                                <IconButton size={"sm"} color={textColorBrand} onClick={onClickEditScene} aria-label='Edit' icon={<EditIcon />} /></Tooltip>
                                            <Popover isLazy>
                                                <Tooltip label="View as Scene" aria-label='View as scene'>
                                                    <Box display="inline-block">
                                                        <PopoverTrigger>
                                                            <IconButton size={"sm"} color={textColorBrand} aria-label='View as Scene' icon={<RepeatIcon />} />
                                                        </PopoverTrigger>
                                                    </Box>
                                                </Tooltip>
                                                <PopoverContent>
                                                    <PopoverArrow />
                                                    <PopoverCloseButton />
                                                    <PopoverHeader>Scene Viewer</PopoverHeader>
                                                    <PopoverBody>
                                                        <>
                                                            <MiniSceneViewer fileName={props.file.File.name} imageUrl={props.file.Preview} id={"viewer" + props.index.toString()} />
                                                        </>
                                                    </PopoverBody>
                                                </PopoverContent>
                                            </Popover>

                                            <Tooltip label="Remove File" aria-label='Remove File'>
                                                <IconButton size={"sm"} color={'red'} aria-label='Remove File' icon={<DeleteIcon />} onClick={() => onClickRemoveScene()} />
                                            </Tooltip>
                                        </ButtonGroup>
                                    </FormControl>
                                </GridItem>

                                <GridItem colSpan={1}>
                                    <Center>
                                        <Flex direction={'column'}  minWidth='max-content' >
                                            <FormLabel>Upload Progress: <Badge color={'Brand'}>{props.file.Progress}%</Badge> </FormLabel>

                                            <Progress  minWidth='max-content' sx={{
                                                "& > div:first-child": {
                                                    transitionProperty: "width",
                                                },
                                            }} hasStripe value={props.file.Progress} />
                                        </Flex>
                                    </Center>
                                    {/* </Flex> */}
                                </GridItem>
                            </SimpleGrid>

                        </CardBody>

                    </Card>
                </>)}


        </>
    )
}

SceneFileListItem.propTypes = {
    file: PropTypes.any,
    handleSceneChange: PropTypes.any,
    handleSceneRemove: PropTypes.any,
    handleSceneEdit: PropTypes.any,
    index: PropTypes.number
}

export default SceneFileListItem