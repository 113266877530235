import { makeAutoObservable } from 'mobx';

import { User } from 'firebase/auth';
import { UserDetail } from '../models/user';
import { IOrgChartNode } from '../models/IOrgChartNode';
import {
  CreateUserRequest,
  CreateUserResponse,
  GetUsersResponse,
  SetUserClaimRequest,
  SetUserClaimResponse,
  UpdateUserResponse,
} from '../models/admin-api/dto';
import admin from '../api/admin';
import { RootStore } from './rootStore';
import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { IUserOrgRole } from '../models/userOrgRole';

export class UserStore {
  authUser: null | User = null;
  isLoadingUsers = false;
  users: UserDetail[] = [];
  orgChartObj: IOrgChartNode | undefined;
  root: RootStore;
  toast = useToast();

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  createUser = async (user: UserDetail, copyFromUser: string | null) => {
    var createUserReq = {
      user: user,

      templateUserId: copyFromUser,
    } as CreateUserRequest;

    var response = await admin.Users.CreateUser(createUserReq)
      .then((resp: CreateUserResponse) => {
        if (resp.isSuccess) {
          this.toast({
            title: 'User Created',
            description:
              'User Created, Please ensure they have the necessary user roles in order to access the correct data.',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });

          return resp.result;
        } else {
          console.log(resp);

          this.toast({
            title: 'Error creating new user',
            description: resp.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      })

      .catch((error: AxiosError) => {
        console.log(error);

        this.toast({
          title: 'Error Creating User',
          description: error.response.data['message'] || error.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      });

    return response;
  };

  createUserClaim = async (userRole: IUserOrgRole) => {
    var createUserRoleReq = {
      userClaim: userRole,
    } as SetUserClaimRequest;

    var response = await admin.Users.CreateUserClaim(createUserRoleReq)
      .then((resp: SetUserClaimResponse) => {
        

        if (resp && resp?.isSuccess) {
          this.toast({
            title: 'User Role Created',
            description:
              'User Role Created - User will have to log out and log back in for their changes to be reflected.',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });

          return resp.result;
        }
      })
      .finally(() => {});
  };

  updateUser = async (userId: string, user: UserDetail) => {
    await admin.Users.UpdateUser(userId, user)
      .then((resp: UpdateUserResponse) => {
        if (resp.isSuccess) {
          this.toast({
            title: 'User Updated',
            description: 'User Updated Successfully',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });

          return resp.result;
        } else {
          console.log(resp);

          this.toast({
            title: 'Error updating user',
            description: resp.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      })
      .catch((error: AxiosError) => {
        console.log(error);

        this.toast({
          title: 'Error Updating User',
          description: error.response.data['message'] || error.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      });
  };

  getUserById(userId: string) {
    if (this.users.length !== 0) {
      var getUserResult = this.users.find((u) => u.userId === userId.trim());
      return getUserResult;
    } else {
      alert('unable to access users');
    }
  }

  //Get User List for User Data Grid
  async GetUserList(userId: string) {
    //Get From Admin API
    await admin.Users.GetUsers().then((response: GetUsersResponse) => {
      this.users = response.result;
    });
  }
}
