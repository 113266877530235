import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useKrpano from 'react-krpano-hooks';

const MiniSceneViewer = props => {

    var sourceXml = '<krpano debugmode="true"><include url="/krpano/skin/vtourskin.xml" /><scene name="firstScene" thumburl="' + props.imageUrl + '" heading="0.0"><preview url="' + props.imageUrl + '" /><image><sphere url="' + props.imageUrl + '" /></image></scene></krpano>';

    var loadSceneXml = "loadscene('firstScene')";

    const { containerRef, callKrpano } = useKrpano({
        embeddingParams: {
            xml: null,
            target: 'react-krpano'
        },
        width: '300px',
        height: '300px'
    }) as any;

    useEffect(() => {
        callKrpano(
            "loadxml('" + sourceXml + "',NULL, MERGE, BLEND(0.5));" + loadSceneXml
        );
    }, [callKrpano])






    return (
        <>
            <div ref={containerRef} />
        </>
    );
}

MiniSceneViewer.propTypes = {
    imageUrl: PropTypes.any,
    fileName: PropTypes.any,
    id: PropTypes.any
}

export default MiniSceneViewer