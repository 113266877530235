// Chakra imports
import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import Chart from "react-apexcharts";
// Custom components
import Card from 'components/card/Card';

// Assets


export default function ProjectsByOrganisation(props: { [x: string]: any }) {

  const { ...rest } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const options = {

    chart: {
      id: 'chart1',
    },
    legend: {
      show: true
    },
    xaxis: {
      categories: props.data?.series
    },

    theme: {
      palette: 'palette2'
    },
    stroke: {
      dashArray: 2,
      width: 3
    },
    dataLabels: {
      enabled: false
    },
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350
      }
    },
    responsive: [{
      breakpoint: undefined,
      options: {},

    }]
  }






  return (
    <Card flexDirection="column" w="100%" {...rest}>
      <Flex justify="space-between" align="start" px="10px" pt="5px" w="100%">
        <Flex flexDirection="row" align="start" me="20px">
          <Flex flexDirection="row" w="100%">
            <Text
              color="secondaryGray.600"
              me="auto"
              fontSize="sm"
              fontWeight="500"
            >
              Total Projects

              <Text
                color={textColor}
                fontSize="34px"
                fontWeight="700"
                lineHeight="100%"
              >
                {props.allProjectCount || ""}
              </Text>

            </Text>

          </Flex>
        </Flex>
      </Flex>
      <Flex justify="space-between" align="start" px="10px" pt="5px" w="100%">
      <Box width={'100%'} >

        <div id="chart1">
          <Chart
            id={"chart1"}
            options={options}
            series={props.data?.data || []}
            type="line"
            width={'100%'} height={'400px'}
          />
        </div>

      </Box>
      </Flex>
    </Card>
  );
}
