import { useEffect, useState } from 'react';
import useKrpano from 'react-krpano-hooks';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useEViewerStore } from '../../contexts/RootStoreProvider';
// import ReactGA from 'react-ga4';

function ExternalTourViewer() {
    let { id } = useParams();
    const [viewingXml, setViewingXml] = useState('');
    const eviewerStore = useEViewerStore();

    const navigate = useNavigate();
    const { containerRef, callKrpano } = useKrpano({
        embeddingParams: {
            xml: null,
            target: 'react-krpano'
        },
        width: '100%'
    }) as any;


    useEffect(() => {
        if (id !== null && id !== undefined) {

            eviewerStore.getExternalViewingData(id).then(() => {
                setViewingXml(eviewerStore.viewData);
            });


        } else {
            alert('No Shared Link Supplied !');
            navigate('/');
        }
    }, [id]);

    useEffect(() => {
        callKrpano("loadxml('" + viewingXml + "',NULL, MERGE, BLEND(0.5));");
    }, [viewingXml]);



    const startViewer = async (id: string) => {




        await eviewerStore.getExternalViewingData(id)
            .then((response: any) => {
                setViewingXml(eviewerStore.viewData);

            })
            .catch((error) => {
                alert("Error")
            });
    }




    return (
        <>

            <div ref={containerRef} style={{ borderRadius: 10 }} />
        </>
    );
}

export default ExternalTourViewer;

