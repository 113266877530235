import { makeAutoObservable } from 'mobx';
import admin from '../api/admin';
import {
  CreateOrgRequest,
  GetOrgChartRequest,
  UpdateOrgRequest
} from '../models/admin-api/dto';
import { IOrgChartNode } from '../models/IOrgChartNode';
import { IOrganisation } from '../models/organisation';
import { RootStore } from './rootStore';

export class OrganisationStore {
  orgChartObj: IOrgChartNode | undefined;
  root: RootStore;



  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  //Get User List for User Data Grid
  createFlowOrgChart = async (userId: string) => {
    var orgRequest = { userId: userId } as GetOrgChartRequest;
    var response = await admin.Organisations.GetOrgChart();
    return response;
  };

  //Creates new Organisation
  createOrganisation = async (orgObj: IOrganisation) => {
    var orgRequest = {
        organisation: orgObj
    } as CreateOrgRequest;

    var response = await admin.Organisations.AddOrg(orgRequest);
    return response;
  };

  updateOrganisation = async (userId: string, orgObj: IOrganisation) => {
    var orgRequest = {
      userId: userId,
      organisation: orgObj
    } as UpdateOrgRequest;

    var response = await admin.Organisations.UpdateOrg(orgRequest);
    return response;
  };
}
