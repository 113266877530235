import { IconButton, Stack, Tooltip } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { CustomCellRendererProps } from "ag-grid-react";
import { FaTrash, FaUser } from "react-icons/fa";
import ProgressLoader from "../../../../components/progressLoader";
import { useState } from "react";
import EditUserButton from "../../../../components/users/editUserButton/editUserButton";

export default (params: CustomCellRendererProps) => {


    const [isLoading, SetIsLoading] = useState(false);
    const navigate = useNavigate();
    const handleOnClickDisable = async (projectId: string) => {
        // SetIsLoading(true);
        // var projectStore = params["projectStore"];
        // var currentUserId = params["currentUserId"];

        // projectStore.deleteProject(currentUserId, params.value).finally(() => {
        //     SetIsLoading(false);
        // });
        alert("Not Implemented")
    };

    return (
        <>
            {isLoading ? <ProgressLoader /> :
                <Stack direction="row" spacing={4}>
                    <EditUserButton user={params.data}  />
                    <Tooltip label='Copy User'>
                        <IconButton icon={<FaUser />} aria-label={'Share'} size='sm' colorScheme='brand' variant='outline' />
                    </Tooltip>
                    <Tooltip label='Disable'>
                        <IconButton icon={<FaTrash />} aria-label={'Delete'} size='sm' colorScheme='red' variant='outline' onClick={() => handleOnClickDisable(params.value)} />
                    </Tooltip>
                </Stack>
            }
        </>
    )
}