
// Chakra imports
import {
  Flex
} from '@chakra-ui/react';


import { useContext } from 'react';
import { AuthContext } from '../../../contexts/auth-context';
import { observer } from 'mobx-react';
import UsersList from './components/UsersList';
import { useUserStore } from '../../../contexts/RootStoreProvider';


const UserManagement = () => {
  const { currentUser, currentOrgs } = useContext(AuthContext);
  const userStore = useUserStore();



  return (
    <Flex pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column" width="stretch">
        <UsersList currentUser={currentUser} currentOrgs={currentOrgs} userStore={userStore} />
      </Flex>
    </Flex>
  );
}

export default observer(UserManagement)
