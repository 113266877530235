import { User } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { createContext, useState, useEffect, ReactNode } from 'react';
import { SignOutUser, userStateListener } from '../firebase/firebase';
import { IOrganisation } from '../models/organisation';
import { GetOrgsResponse, GetUserResponse, GetUserRolesResponse } from '../models/admin-api/dto';
import { UserDetail } from '../models/user';
import { IUserOrgRole } from '../models/userOrgRole';
import admin from '../api/admin';



interface Props {
  children?: ReactNode;
}

export const AuthContext = createContext({
  // "User" comes from firebase auth-public.d.ts
  currentOrgs: [] as IOrganisation[],
  currentOrgRoles: [] as IUserOrgRole[],
  currentUser: {} as User | null,
  currentUserDetail: {} as UserDetail | null,
  setCurrentUser: (_user: User) => { },
  setCurrentUserDetail: (_userDetail: UserDetail) => { },
  signOut: () => { },
});

export const AuthProvider = ({ children }: Props) => {

  const [currentUserDetail, setCurrentUserDetail] = useState<UserDetail | null>(null);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentOrgs, setCurrentOrgs] = useState<IOrganisation[]>([]);
  const [currentOrgRoles, setCurrentOrgRoles] = useState<IUserOrgRole[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = userStateListener((user) => {

      if (user) {

        admin.api.interceptors.request.use(async function (request) {


          if (user) {
            try {
              const token = await user.getIdToken(false); // Force refresh is false
              request.headers['Authorization'] = 'Bearer ' + token;
            } catch (error) {
              console.log('Error obtaining auth token in interceptor, ', error);
            }
          }

          return request;
        });

        setCurrentUser(user); 
        getOrgRoles(user.uid);
        getUserDetail(user.uid);
       
        getOrgs();
      }
    });
    return unsubscribe;
  }, [setCurrentUser]);

  // As soon as setting the current user to null,
  // the user will be redirected to the home page.

  const signOut = () => {
    SignOutUser();
    setCurrentUser(null);
    setCurrentOrgs([]);
    setCurrentOrgRoles([]);
    navigate('/auth/sign-in');
  };

  const getOrgs = () => {
    admin.Organisations.GetOrgs().then(
      (orgDetailsResult: GetOrgsResponse) => {
        console.log("Setting Orgs to User")
        setCurrentOrgs(orgDetailsResult.result)
      })
  }

  const getOrgRoles = (uid) => {
    admin.Users.GetUserRoles(uid).then(
      (orgRolesResult: GetUserRolesResponse) => {
        console.log("Setting Org Roles for User")
        setCurrentOrgRoles(orgRolesResult.result)
      })
  }



  const getUserDetail = (uid) => {

    admin.Users.GetUser(uid).then(
      (userDetailsResult: GetUserResponse) => {
        console.log("Setting UserDetail")
   
        setCurrentUserDetail(userDetailsResult.result)
      })
  }

  const value = {
    currentUser,
    currentOrgs,
    currentUserDetail,
    currentOrgRoles,
    setCurrentOrgs,
    setCurrentUser,
    setCurrentUserDetail,
    signOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};


