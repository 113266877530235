import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button, FormControl, FormLabel, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from '@chakra-ui/react'
import admin from '../../../../api/admin';
import { IMetaData } from '../../../../models/metaData';
import { UpdateMetaRequest, UpdateMetaResponse } from '../../../../models/admin-api/dto';
import { FaEdit } from 'react-icons/fa';
import { error } from 'console';

const EditMetaForm = props => {



    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] = React.useState(false);
    const toast = useToast();


    const validationSchema = yup.object({
        metaKey: yup.string().required('Label is required'),
        metaValue: yup.string().required('Value is required'),

    });

    function handleUpdateCreateMedia(values: {

        metaKey: any;
        metaValue: string;
    }) {
        setIsLoading(true);
        var request = {
            metaData: {
                metaDataId: props.metaData.metaDataId,
                metaKey: values.metaKey,
                metaValue: values.metaValue,
                metaCategory: props.metaData.metaCategory,
                entity: props.metaData.entity,
            } as IMetaData

        } as UpdateMetaRequest

        admin.Meta.UpdateMeta(request, props.metaData.metaDataId)
            .then((response: UpdateMetaResponse) => {

                if (response.isSuccess) {
                    toast({
                        title: 'Success',
                        description: 'Metadata Updated Successfully!',
                        status: 'success',
                        duration: 2000,
                        isClosable: true,
                    });

                    props.refreshMeta();
                    onClose();

                } else {

                    toast({
                        title: 'Error',
                        description: 'Error Updating MetaData! - ' + response.message,
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                    });

                    console.log(response);
                }



            }).catch((error) => {

                toast({
                    title: 'Error',
                    description: 'Error Updating MetaData! - Please try again',
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });

                console.log(error)
            })
            .finally(() => { setIsLoading(false) })

    }

    const formik = useFormik({
        initialValues: {
            metaKey: props.metaData.metaKey || null,
            metaValue: props.metaData.metaValue || null,
        },
        validationSchema: validationSchema,

        onSubmit: (values) => {
            console.log(values);
            handleUpdateCreateMedia(values);
        }
    });


    return (
        <>
            <IconButton size={'sm'} icon={<FaEdit />} colorScheme='brand' aria-label={'Edit'} onClick={onOpen} />

            <Modal

                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>

                    <form onSubmit={formik.handleSubmit}>

                        <ModalHeader>Edit {props.metaData.metaKey}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>

                            <FormControl isRequired>
                                <FormLabel>Label</FormLabel>
                                <Input value={formik.values.metaKey} placeholder='Enter a label' type="text" id={"metaKey"} name={"metaKey"} onChange={formik.handleChange} isRequired={true} />
                            </FormControl>

                            <FormControl mt={4} isRequired>
                                <FormLabel>Value</FormLabel>
                                <Input value={formik.values.metaValue} placeholder='Enter a value' type="text" id={"metaValue"} name={"metaValue"} onChange={formik.handleChange} isRequired={true} />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button type='submit' colorScheme='blue' isLoading={isLoading} loadingText={"Submitting"} mr={3}>
                                Save
                            </Button>
                            <Button onClick={onClose}>Cancel</Button>
                        </ModalFooter>
                    </form >
                </ModalContent>
            </Modal>
        </>
    )
}

EditMetaForm.propTypes = {
    handleAction: PropTypes.any,
    metaData: PropTypes.any,
    refreshMeta:PropTypes.any
}

export default EditMetaForm

