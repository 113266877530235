// Chakra imports
import {
  Portal,
  Box,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
// Layout components

import { useContext } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import routes from 'routes';
import { AuthContext } from '../../contexts/auth-context';
import ProjectBuilder from '../../views/builder';
import BuilderSidebar from '../../views/builder/components/builderSidebar';
import BuilderNavbar from '../../components/navbar/NavbarBuilder';
import { useBuilderStore } from '../../contexts/RootStoreProvider';
import { observer } from 'mobx-react';

// Custom Chakra theme
export default observer(function BuilderLayout(props: { [x: string]: any }) {
  const { ...rest } = props;
  // states and functions
  const { currentUserDetail } = useContext(AuthContext);
  const builderstore = useBuilderStore();

  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== '/app/full-screen-maps';
  };

  const getActiveRoute = (routes: RoutesType[]): string => {
    let activeRoute = 'Project Builder';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: number) => {

      if (route.layout === '/builder') {

        return (
          <Route path={`${route.path}`} element={route.component} key={key} />
        );
      }
      if (route.collapse) {
        return getRoutes(route.items);
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();
  const bg = useColorModeValue('background.100', 'background.900');
  let location = useLocation();

  if (!currentUserDetail || currentUserDetail == undefined) {
    return <Navigate to='/auth/sign-in' replace state={{ redirectTo: location }} />;
  }

  // Pre Loader

  return (
    <Box bg={bg} h="100vh" w="100vw">
      <BuilderSidebar />
      <Box
        float="right"
        minHeight="100vh"
        height="100%"
        overflow="auto"
        position="relative"
        maxHeight="100%"
        w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
        maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
        transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
        transitionDuration=".2s, .2s, .35s"
        transitionProperty="top, bottom, width"
        transitionTimingFunction="linear, linear, ease"
      >

        <Portal>
          <Box>
            <BuilderNavbar
              projectName={builderstore.editorProjectName}
              projectId={builderstore.editorProjectFSId}
              hovered={true}
              setMini={false}
              mini={false}
              onOpen={onOpen}
              logoText={'Theia360'}
              brandText={getActiveRoute(routes)}
              secondary={getActiveNavbar(routes)}
              theme={props.theme}
              setTheme={props.setTheme}
              fixed={true}
              {...rest}
            />
          </Box>
        </Portal>

        {getRoute() ? (
          <Box
            mx="auto"
            p={{ base: '20px', md: '30px' }}
            pe="20px"
            minH="100vh"
            pt="50px"
          >
            <Routes>
              {getRoutes(routes)}
              <Route path=":id" Component={ProjectBuilder} />
            </Routes>

          </Box>
        ) : null}
       
      </Box>
    </Box >
  )

  //   )
}


)
