import  {  useEffect, useState } from 'react'

import useKrpano from 'react-krpano-hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useBuilderStore } from '../../../contexts/RootStoreProvider';
import { AspectRatio, Box, Grid, useToast } from '@chakra-ui/react';
import { observer } from 'mobx-react';


const ProjectViewer = (props: any) => {

    const toast = useToast();
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [viewerDetail, setViewerDetail] = useState<string>(undefined);
    const builderStore = useBuilderStore();

    const { state } = useLocation();
    const [loadSceneText, setLoadSceneText] = useState('');

    const { containerRef, callKrpano } = useKrpano({
        embeddingParams: {
            handleLoaded: krpanoReady,
            xml: null,
            target: 'react-krpano'
        },
        width: '100%',
        height: '85vh'
    }) as any;

    function krpanoReady(krpano) {
        console.log("hello")
        // krpano.call("trace(krpano is ready...)");
    }

    const startViewer = async (id: string) => {

        await builderStore.startViewer(id)
            .then((response: any) => {
                setViewerDetail(response.tourXml);
                
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }


    useEffect(() => {

        //Project Item Preview

        if (state?.sceneId && state?.hotspotId && state?.tourXml) {
            setLoadSceneText(
                "loadscene('" +
                state?.sceneId +
                "');looktohotspot('" +
                state?.hotspotId +
                "')"
            );

            setViewerDetail(state?.tourXml);
        }

        else {

            startViewer(id);

        }




    }, []);


    useEffect(() => {
        if (viewerDetail) {
            callKrpano("loadxml('" + viewerDetail + "',NULL, MERGE, BLEND(0.5));" +
                loadSceneText);
        }
    }, [callKrpano, viewerDetail]);

    return (
        <>

            <Box maxW='100%'>
                <Grid maxW='100%' display={{ base: 'block', lg: 'grid' }} pt={{ base: '130px', md: '80px', xl: '80px' }}>

                    <AspectRatio w='100%' maxW='100%' ratio={1130 / 636}>
                        <>
                            <div ref={containerRef} style={{ borderRadius: 10 }} />
                        </>
                    </AspectRatio>

                </Grid >
            </Box >



        </>
    )
}

ProjectViewer.propTypes = {}

export default observer(ProjectViewer)


