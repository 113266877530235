import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom';
import { Avatar, Flex, Menu, Text, MenuButton, MenuList, useColorModeValue, MenuItem, Box } from '@chakra-ui/react';
import { IOrganisation } from '../../models/organisation';
import { AuthContext } from '../../contexts/auth-context';
import SupportMenu from '../support';
import { SignOutUser } from '../../firebase/firebase';

const UserMenu = props => {

    const shadow = useColorModeValue(
        '0px 1px 11px 0px rgba(28, 35, 50, 0.04)',
        '0px 1px 11px 0px rgba(28, 35, 50, 0.04)',
    );
    let menuBg = useColorModeValue('white', 'navy.800');
    const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');


    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const { currentUser, currentOrgs, currentUserDetail } = useContext(AuthContext);
    const [userOrg, setUserOrg] = useState<IOrganisation>();
    const navigate = useNavigate();

    const GetCurrentUserOrg = () => {

        try {

            var orgId = currentUserDetail.organisationId;
            var currentOrg = currentOrgs.find(
                (org: IOrganisation) => org.organisationId == orgId,
            );
            setUserOrg(currentOrg);
        } catch (error) {
            console.log('Error Obtaining Current Users Organisation', error);
            return null;
        }
    };

    useEffect(() => {
        GetCurrentUserOrg();
    }, [currentUser, currentUserDetail])


    return (
        <>
            <Flex direction={"row"}>
                <Menu>
                    <MenuButton p="0px">
                        <Avatar
                            h="48px"
                            w="48px"
                            _hover={{ cursor: 'pointer' }}
                            name={currentUserDetail?.firstName + " " + currentUserDetail?.lastName}
                            me={'20px'}
                        />
                    </MenuButton>

                    <MenuList
                        boxShadow={shadow}
                        p="0px"
                        mt="10px"
                        borderRadius="16px"
                        bg={menuBg}
                        border="none"
                    >
                        <Flex w="100%" mb="0px">
                            <Text
                                ps="20px"
                                pt="16px"
                                pb="10px"
                                w="100%"
                                borderBottom="1px solid"
                                borderColor={borderColor}
                                fontSize="sm"
                                fontWeight="700"
                                color={textColor}
                            >
                                👋&nbsp; Hey, {currentUserDetail?.firstName + " " + currentUserDetail?.lastName}
                            </Text>
                        </Flex>
                        <Flex flexDirection="column" p="10px">
                            <MenuItem
                                _hover={{ bg: 'none' }}
                                _focus={{ bg: 'none' }}
                                borderRadius="8px"
                                px="14px"
                            >
                                <SupportMenu useremail={currentUser.email} username={currentUserDetail?.firstName + " " + currentUserDetail?.lastName} />
                            </MenuItem>

                            <MenuItem
                                _hover={{ bg: 'none' }}
                                _focus={{ bg: 'none' }}
                                color="red.400"
                                borderRadius="8px"
                                px="14px"
                                onClick={() => SignOutUser().finally(() => navigate('/auth/sign-in'))} >
                                <Text fontSize="sm">Log out</Text>
                            </MenuItem>
                        </Flex>
                    </MenuList>
                </Menu>
            </Flex>

            <Flex mt="5px" justifyContent="right" alignItems="right">
                <Box
                >
                    <Text color={textColor} fontSize="md" fontWeight="700">
                        {currentUserDetail?.firstName + " " + currentUserDetail?.lastName || ""}
                    </Text>
                    <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
                        {userOrg?.name || ""}
                    </Text>
                </Box>
            </Flex>
        </>
    )
}

UserMenu.propTypes = {}

export default UserMenu

