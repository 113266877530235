import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs, TabList, Tab, TabPanels, TabPanel, List, UnorderedList, ListItem } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import admin from '../../../api/admin';
import { IAsset } from '../../../models/asset';
import { GetAssetResponse } from '../../../models/admin-api/dto';
import ProgressLoader from '../../progressLoader';

const AssetViewer = props => {

    const [isLoading, setIsLoading] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState<IAsset>();

    // let { id, location, planurl } = useParams();

    useEffect(() => {



        //const assetId = Number(id);


        if (props.assetId !== undefined) {
            setIsLoading(true);



            admin.Assets.GetAsset(props.assetId).then(
                (res: GetAssetResponse) => {

                    setSelectedAsset(res.result);
                    setIsLoading(false);
                },
                (error) => {
                    alert('Error Retrieving asset!' + error);
                    setIsLoading(false);
                }
            );
        }
        else {
            alert("Missing Asset ID!")
        }
    }
        , [props.assetId]);


    return (
        <>
            {isLoading ? (<ProgressLoader />) :

                (<>
                    <Tabs isFitted variant='enclosed'>
                        <TabList mb='1em'>
                            <Tab>Asset Details</Tab>

                            <Tab>Properties</Tab>
                            <Tab>Work Order History</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <UnorderedList>
                                    <ListItem>{selectedAsset?.name!}</ListItem>
                                    <ListItem>{selectedAsset?.type!}</ListItem>
                                    <ListItem>{selectedAsset?.manufacturer!}</ListItem>
                                    <ListItem>{selectedAsset?.model!}</ListItem>
                                </UnorderedList>
                            </TabPanel>
                            <TabPanel>
                                <p>two!</p>
                            </TabPanel>
                            <TabPanel>
                                <p>two!</p>
                            </TabPanel>
                        </TabPanels>
                    </Tabs></>
                )
            }

        </>
    )
}


AssetViewer.propTypes = {
    assetId: PropTypes.number,

}



export default observer(AssetViewer)