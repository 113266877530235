import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, FormControl, FormLabel, GridItem, Input, SimpleGrid, useColorModeValue, useToast } from '@chakra-ui/react'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useOrgStore } from '../../../../../contexts/RootStoreProvider';
import { observer } from 'mobx-react';
import OrgLookupComponent from '../../../../lookups/orgLookup';
import { IOrganisation } from '../../../../../models/organisation';
import { CreateOrgResponse } from '../../../../../models/admin-api/dto';
import { AuthContext } from '../../../../../contexts/auth-context';


const NewOrganisationForm = props => {

    const textColor = useColorModeValue('navy.700', 'white');
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const orgStore = useOrgStore();
    const { currentUser, currentOrgs } = useContext(AuthContext);


    function handleCreateOrganisation(values: {
        name: string;
        logoUrl: string;
        parentOrganisationId: number;
    }) {
        setIsLoading(true);


        var createUpdateOrg = values as IOrganisation;

        if (props.currentOrg !== undefined) {
            createUpdateOrg.organisationId = props.currentOrg.organisationId;

            orgStore
                .updateOrganisation(currentUser.uid, createUpdateOrg)
                .then((response: CreateOrgResponse) => {
                    toast({
                        title: 'Success',
                        description: 'Organisation successfully updated!',
                        status: 'success',
                        duration: 2000,
                        isClosable: true,
                    });
                })
                .catch((error) => {

                    toast({
                        title: 'error',
                        description: 'Error Updating Organisation! - Please try again',
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                    });

                    console.log(error)
                })
                .finally(() => {
                    setIsLoading(false);
                    window.location.reload();
                });

        } else {
            orgStore
                .createOrganisation(createUpdateOrg)
                .then((response: CreateOrgResponse) => {
                    toast({
                        title: 'Success',
                        description: 'Organisation successfully created!',
                        status: 'success',
                        duration: 2000,
                        isClosable: true,
                    });
                })
                .catch((error) => {
                    toast({
                        title: 'Error',
                        description: 'Error Creating Organisation! - Please try again',
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                    });

                    console.log(error)
                })
                .finally(() => {
                    setIsLoading(false);
                    window.location.reload();
                });
        }
    }



    const validationSchema = yup.object({
        name: yup
            .string()
            // .email('Enter a valid email')
            .required('Please supply the Name'),
            logoUrl: yup
            .string()
            .min(10, 'URL Should have a minimum value of 15')
            .required('Please supply the Logo URL'),
            parentOrganisationId: yup
            .number()
            .min(1, 'Organisation should be selected')
            .notRequired()
    });



    const initialValues = {
           name:
            props.currentOrg !== undefined
                ? (props.currentOrg.name as string)
                : '',
                logoUrl:
            props.currentOrg !== undefined
                ? (props.currentOrg.logoUrl as string)
                : '',
                parentOrganisationId:
            props.currentOrg !== undefined
                ? (props.currentOrg.ParentOrganisationId as number)
                : null
    };


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {

            handleCreateOrganisation(values as any);
        }
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <SimpleGrid columns={{ base: 2, sm: 1, md: 2, lg: 2 }} columnGap={3} rowGap={6} >
                    <GridItem>
                        <FormControl isRequired >
                            <FormLabel>Organisation Name</FormLabel>
                            <Input
                                isRequired={true}
                                id="name"
                                name="name"
                                fontSize="sm"
                                ms={{ base: '0px', md: '0px' }}
                                type="text"
                                placeholder="Organisation Name"
                                color={textColor}
                                fontWeight="500"
                                size="lg"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />

                        </FormControl>
                    </GridItem>

                    <FormControl isRequired >
                        <FormLabel>Logo URL</FormLabel>
                        <Input
                            id="logoUrl"
                            name="logoUrl"
                            isRequired={true}
                            fontSize="sm"
                            placeholder="Logo URL"
                            color={textColor}
                            fontWeight="500"
                            size="lg"
                            onChange={formik.handleChange}
                            value={formik.values.logoUrl}
                        />
                    </FormControl>

                    <GridItem>
                        <FormControl isRequired>
                            {/* <FormLabel>Organisation</FormLabel> */}
                            <OrgLookupComponent
                                id="parentOrganisationId"
                                name="parentOrganisationId"
                                label="Parent Organisation"
                                isRequired={props.currentOrg == undefined}
                                size={"lg"}
                                fontSize={"sm"}
                                fontWeight={"500"}
                                value={formik.values.parentOrganisationId}
                                onChange={formik.handleChange}
                                organisations={currentOrgs}
                            />
                        </FormControl>
                    </GridItem>
                </SimpleGrid>
                <FormControl>
                    <Button type="submit" isLoading={isLoading} loadingText="Creating Organisation" color={brandStars} float={'right'} variant={'outline'} mt={'24px'}> {props.currentOrg ? "Update" : "Create"}   </Button>
                </FormControl>
            </form >

        </>
    )
}

NewOrganisationForm.propTypes = {
    currentUser: PropTypes.any,
    currentOrgs: PropTypes.any,
    currentOrg: PropTypes.any,

}

export default observer(NewOrganisationForm)


