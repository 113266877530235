import { useEffect, useState } from 'react'
import { IProjectItem } from '../../../models/vtProject';
import { GetProjectAuditReponse, GetProjectItemsResponse, GetSharedProjectsResponse } from '../../../models/admin-api/dto';
import { observer } from 'mobx-react';
import admin from '../../../api/admin';

import ProjectLinkedItemsView from '../../projectLinkedItems';
import ProjectAuditItemsView from '../../projectAuditItems';
import ProjectSharedProjectsView from '../../projectSharedProjectsView';

import { IAudit } from '../../../models/audit';

import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import ProgressLoader from '../../progressLoader';

import { ISharedProjectLinkListObj } from '../../../models/SharedProjectLink';

const ProjectDetails = (props: any) => {

    const [SharedProjectItems, setSharedProjectItems] = useState<ISharedProjectLinkListObj[]>([]);
    const [ProjectItems, setProjectItems] = useState<IProjectItem[]>([]);
    const [AuditItems, setAuditItems] = useState<IAudit[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getProjectItems();
        getAuditItems();
        getSharedProjectItems();
    }, []);

    const getProjectItems = async () => {
        setIsLoading(true);
        await admin.VTProjects.GetProjectItems(props.data.vtProjectFsid)
            .then((response: GetProjectItemsResponse) => {
                if (response.isSuccess) {
                    setProjectItems(response.result);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getAuditItems = async () => {
        setIsLoading(true);
        await admin.Audit.GetProjectAudit(props.data.vtProjectFsid)
            .then((response: GetProjectAuditReponse) => {
                if (response.isSuccess) {
                    setAuditItems(response.result);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getSharedProjectItems = async () => {
        setIsLoading(true);
        await admin.SharedProjects.GetSharedProjectsByProjectId(props.data.vtProjectFsid)
            .then((response: GetSharedProjectsResponse) => {
                if (response.isSuccess) {
                    setSharedProjectItems(response.result);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };


    return (
        <><Box margin={3}>
            {isLoading ? (
                <ProgressLoader />
            ) : (

                <Tabs variant='enclosed' isLazy >
                    <TabList >
                        <Tab>Project Items</Tab>
                       <Tab>Shared Projects</Tab>
                        <Tab>Project History</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel >
                            <ProjectLinkedItemsView ProjectStore={props.projectStore} ProjectItems={ProjectItems} currentUserId={props.currentUser.uid} ProjectId={props.data.vtProjectFsid} />
                        </TabPanel>
                     
                        <TabPanel >
                            <ProjectSharedProjectsView ProjectStore={props.projectStore} SharedProjects={SharedProjectItems} currentUserId={props.currentUser.uid} ProjectId={props.data.vtProjectFsid} />
                        </TabPanel>
                        <TabPanel >
                            <ProjectAuditItemsView AuditItems={AuditItems} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>

            )}

        </Box>


























        </>)
}

export default observer(ProjectDetails)