// Chakra imports
import {
  Flex
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useProjectStore } from '../../../contexts/RootStoreProvider';
import SharedProjectsList from './components/SharedProjectsList';
import { AuthContext } from '../../../contexts/auth-context';
import { useContext } from 'react';

const SharedProjects = () => {
  const projectStore = useProjectStore();
  const { currentOrgRoles } = useContext(AuthContext);

  return (
    <Flex pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column" width="stretch">
        <SharedProjectsList projectStore={projectStore} currentUserRoles={currentOrgRoles} />
      </Flex>
    </Flex>
  );
}

export default observer(SharedProjects)
