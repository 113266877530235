import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { IMedia } from '../../models/media';
import { GetProjectMediaResponse } from '../../models/admin-api/dto';
import admin from '../../api/admin';
import MediaCard from './mediaCard';
import ProgressLoader from '../progressLoader';
import { SimpleGrid } from '@chakra-ui/react';
import { stripUrlSasKey } from '../../helpers/sasKeyHelper';

const MediaViewer = props => {

    const [mediaItems, setMediaItems] = useState<IMedia[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        loadMediaItems();
    }, []);


    useEffect(() => {
        loadMediaItems();
    }, [props.refreshLibrary]);


    const loadMediaItems = () => {
        setIsLoading(true);


        admin.Media.GetProjectMedia(props.projectId)
            .then((response: GetProjectMediaResponse) => {
                setMediaItems(response.result);
            })
            .finally(() => setIsLoading(false));
    };

    return (

        isLoading ? (<ProgressLoader />) : (
            <>

                <SimpleGrid spacing={2} templateColumns='repeat(auto-fill, minmax(150px, 1fr))'>
                    {mediaItems.map((m: IMedia, index: number) => (
                        <MediaCard mediaObj={m} key={index} selectedItem={props.selectedItem !== undefined ? stripUrlSasKey(m?.url!) == stripUrlSasKey(props?.selectedItem) : false} handleLinkMedia={() => props.handleLinkMedia(m.thumbnailUrl, m.type || 'media')} />
                    ))}

                </SimpleGrid>
            </>)

    )
}

MediaViewer.propTypes = {
    handleLinkMedia: PropTypes.any,
    projectId: PropTypes.string,
    userId: PropTypes.string,
    refreshLibrary: PropTypes.any,
    selectedItem: PropTypes.string

}

export default MediaViewer