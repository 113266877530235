import React, { useState } from 'react'
import { Button, Checkbox, CheckboxGroup, FormControl, FormLabel, GridItem, Input, SimpleGrid, Stack, useColorModeValue } from '@chakra-ui/react'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useProjectStore } from '../../../contexts/RootStoreProvider';
import PropTypes from 'prop-types'
import { ISharedProjectLink } from '../../../models/SharedProjectLink';



const SharedProjectForm = (props: any) => {


    console.log(props)

    const [isLoading, setIsLoading] = React.useState(false);
    const projectStore = useProjectStore();
    const textColor = useColorModeValue('navy.700', 'white');
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    const [refresh,setRefresh] = useState(false); 

    const validationSchema = yup.object({
        description: yup.string().required('Description is required')
    });

    const handleCreateUpdateSharedProject = async (sharedProjectObj: any) => {
        setIsLoading(true);



        if (props.sharedProject) {

            var updateProject = props.sharedProject;

            updateProject.description = sharedProjectObj.description;
            updateProject.showAssets = sharedProjectObj.showAssets;
            updateProject.showNotes = sharedProjectObj.showNotes;
            updateProject.showMedia = sharedProjectObj.showMedia;
            

            await projectStore
                .updateSharedProject(updateProject,refresh)
                .then((response: any) => {
                    alert(response.result);
                })
                .finally(() => {
                    setIsLoading(false);
                });

        } else {


            sharedProjectObj.sharedProjectId = props.projectId;
            sharedProjectObj.availableUntil = new Date();

            await projectStore
                .createSharedProject(sharedProjectObj)
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const formik = useFormik({
        initialValues: {
            description: props.sharedProject?.description || '',
            showAssets: props.sharedProject?.showAssets || false,
            showNotes: props.sharedProject?.showNotes || false,
            showMedia: props.sharedProject?.showNotes || false,
            availableUntil: props.sharedProject?.availableUntil || null

        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleCreateUpdateSharedProject(values);
        }
    });


    return (

        <form onSubmit={formik.handleSubmit}>

            <SimpleGrid columns={{ base: 2, sm: 1, md: 2, lg: 2 }} columnGap={3} rowGap={6} >
                <GridItem>
                    <FormControl isRequired >
                        <FormLabel>Description</FormLabel>
                        <Input
                            isRequired={true}
                            id="description"
                            name="description"
                            fontSize="sm"
                            ms={{ base: '0px', md: '0px' }}
                            type="text"
                            placeholder="Enter Description Here"
                            color={textColor}
                            fontWeight="500"
                            size="lg"
                            onChange={formik.handleChange}
                            value={formik.values.description}
                        />

                    </FormControl>
                </GridItem>

                <GridItem>
                    <FormLabel>Project Attributes</FormLabel>

                    <CheckboxGroup>
                        <Stack padding={2} spacing={[1, 5]} direction={['column', 'row']}>
                            <Checkbox id="showNotes" onChange={formik.handleChange} name="showNotes" isChecked={formik.values.showNotes} >Include Notes</Checkbox>
                            <Checkbox id="showAssets" onChange={formik.handleChange} name="showAssets" isChecked={formik.values.showAssets}>Include Assets</Checkbox>
                            <Checkbox id="showMedia" onChange={formik.handleChange} name="showMedia" isChecked={formik.values.showMedia}>Include Media</Checkbox>
                        </Stack>
                    </CheckboxGroup>

                    <Checkbox id="regenerateTour" onChange={formik.handleChange} name="regenerateMedia" isChecked={formik.values.showMedia}>Regenerate Changes</Checkbox>

                </GridItem>

            </SimpleGrid>
            <FormControl>
                <Button type="submit" isLoading={isLoading} color={brandStars} float={'right'} variant={'outline'} mt={'24px'}>Create</Button>
            </FormControl>
        </form >



    )
}

SharedProjectForm.propTypes = {
    projectId: PropTypes.string,
    projectStore: PropTypes.any,
    sharedProject: PropTypes.any
}

export default SharedProjectForm