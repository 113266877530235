import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from '@chakra-ui/react'
import admin from '../../../../api/admin';
import { IMetaData } from '../../../../models/metaData';
import { CreateMetaRequest, CreateMetaResponse } from '../../../../models/admin-api/dto';

const AddMetaForm = props => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] = React.useState(false);
    const toast = useToast();


    const validationSchema = yup.object({
        metaKey: yup.string().required('Label is required'),
        metaValue: yup.string().required('Value is required'),

    });

    function handleUpdateCreateMedia(values: {

        metaKey: any;
        metaValue: string;
    }) {

        setIsLoading(true);

        var request = {

            metaData: {
                metaKey: values.metaKey,
                metaValue: values.metaValue,
                metaCategory: props.metaCategory,
                entity: props.entity,
            } as IMetaData


        } as CreateMetaRequest


        admin.Meta.CreateMeta(request)
            .then((response: CreateMetaResponse) => {

                if (response.isSuccess) {
                    toast({
                        title: 'Success',
                        description: 'Metadata Created Successfully!',
                        status: 'success',
                        duration: 2000,
                        isClosable: true,
                    });



                    props.refreshMeta();
                    onClose();

                } else {

                    toast({
                        title: 'Error',
                        description: 'Error Creating MetaData! - ' + response.message,
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                    });

                    console.log(response);
                }

            }).catch((error) => {

                toast({
                    title: 'Error',
                    description: 'Error Creating MetaData! - Please try again',
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });

                console.log(error)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const formik = useFormik({
        initialValues: {
            metaKey: props.metaKey,
            metaValue: props.metaValue,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log(values);
            handleUpdateCreateMedia(values);
        }
    });


    return (
        <>



            <Button onClick={onOpen} size={'sm'} colorScheme={'brand'} >{props.action}</Button>


            <Modal

                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>

                    <form onSubmit={formik.handleSubmit}>

                        <ModalHeader>{props.action} {props.metaCategoryName}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>

                            <FormControl isRequired>
                                <FormLabel>Label</FormLabel>
                                <Input value={formik.values.metaKey} placeholder='Enter a label' type="text" id={"metaKey"} name={"metaKey"} onChange={formik.handleChange} isRequired={true} />
                            </FormControl>

                            <FormControl mt={4} isRequired>
                                <FormLabel>Value</FormLabel>
                                <Input value={formik.values.metaValue} placeholder='Enter a value' type="text" id={"metaValue"} name={"metaValue"} onChange={formik.handleChange} isRequired={true} />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button type='submit' colorScheme='blue' isLoading={isLoading} loadingText={"Submitting"} mr={3}>
                                Save
                            </Button>
                            <Button onClick={onClose}>Cancel</Button>
                        </ModalFooter>
                    </form >
                </ModalContent>
            </Modal>
        </>
    )
}

AddMetaForm.propTypes = {
    action: PropTypes.any,
    metaDataId: PropTypes.number || undefined,
    entity: PropTypes.string,
    metaCategory: PropTypes.string,
    metaKey: PropTypes.string,
    metaValue: PropTypes.string,
    refreshMeta: PropTypes.any,
    metaCategoryName: PropTypes.string
}

export default AddMetaForm

