import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Divider, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Show, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import UserDetail from '../../forms/users/Details';
import { useUserStore } from '../../../contexts/RootStoreProvider';


const AddNewUserButton = (props) => {
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const { isOpen, onOpen, onClose } = useDisclosure()
    const userStore = useUserStore();

    return (
        <>
            <Button onClick={onOpen} variant={'outline'} color={textColorBrand}>New User</Button>
            <Modal closeOnOverlayClick={false} size={'6xl'} isOpen={isOpen} isCentered onClose={onClose} motionPreset='slideInBottom' scrollBehavior={'inside'}>
                <ModalOverlay />
                <ModalContent
                >
                    <ModalHeader>New User</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <UserDetail isNew={true} currentOrgs={props.currentOrgs} currentUser={props.currentUser} userStore={userStore} />
                    </ModalBody>  <Divider />
                    {/* <ModalFooter>
                        <Hide below='md'>
                            <ProjectStepper step={activeStep} />
                        </Hide>
                    </ModalFooter> */}
                </ModalContent>
            </Modal >
        </>
    )
}

AddNewUserButton.propTypes = {
    currentUser: PropTypes.any,
    currentOrgs: PropTypes.any
}

export default AddNewUserButton