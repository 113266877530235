import React, { useContext, useEffect } from 'react'
import MetadataSettings from '../../../components/meta'
import { Box, Card, Divider, Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { AuthContext } from '../../../contexts/auth-context';
import { Navigate } from 'react-router-dom';

const SystemManagement = () => {

    const { currentUserDetail } = useContext(AuthContext);

    if (currentUserDetail == undefined || currentUserDetail.isEnterpriseAdmin == false) { return <Navigate to='/' /> };




    return (
        <Flex pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Flex direction="column" width="stretch">
                <Card>
                    <Flex direction='column' w='100%' >
                        <Box padding={10}>

                            <Tabs>
                                <TabList mb='1em'>
                                    <Tab>Metadata</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                        <Heading size={'sm'}>Metadata</Heading> <Divider />
                                        <MetadataSettings />
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>

                        </Box></Flex></Card></Flex></Flex>

    );

}

SystemManagement.propTypes = {}

export default SystemManagement