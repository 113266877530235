import React from 'react'
import PropTypes from 'prop-types'
import { Button, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, SimpleGrid, useDisclosure } from '@chakra-ui/react'
import SceneCard from './sceneCard'
import { IScene } from '../../models/scene'

const SceneExplorerDrawer = (props: { scenes: IScene[], goSceneOnClick: any }) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { scenes, goSceneOnClick } = props;

    return (

        <><Button size={'sm'} variant='outline' colorScheme='blue'onClick={onOpen}>
            Scene Explorer
        </Button>



            <Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader borderBottomWidth='1px'>Scene Explorer</DrawerHeader>
                    <DrawerBody>
                        <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(200px, 1fr))'>
                            {scenes.map((sc: IScene, index: number) => (
                                <SceneCard sceneObj={sc} key={sc.name} goSceneOnClick={goSceneOnClick} onClose={onClose} />
                            ))}
                        </SimpleGrid>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>

    )
}



export default SceneExplorerDrawer