import './assets/css/App.css';
import { Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import {
  ChakraProvider,
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from './contexts/auth-context';
import SignIn from './views/auth/signIn';
import AppLayout from './layouts/app';
import BuilderLayout from './layouts/builder';
import ForgotPassword from './views/auth/forgotPassword';
import ResetPassword from './views/auth/resetPassword';
import MFaEnrollment from './views/auth/mfa/enroll';
import ConfirmMFAEnrollment from './views/auth/mfa/enroll/confirm';
import MfaSignin from './views/auth/mfa/signin';
import AuthAction from './views/auth/actions';
import AuthUserActions from './views/auth/userActions';
import ConfirmEmail from './views/auth/confirmEmail';
import ExternalTourViewer from './views/externalViewer';
import AssetViewer from './components/assets/assetViewer';

export default function Main() {
  // eslint-disable-next-line
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  const { currentUserDetail } = useContext(AuthContext);
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  type RedirectLocationState = {
    redirectTo: Location;
  };
 

  if (process.env.NODE_ENV === 'production') console.log = function () {};


  useEffect(() => {
    if (currentUserDetail) {
      if (locationState) {
        const { redirectTo } = locationState as RedirectLocationState;
        navigate(`${redirectTo.pathname}${redirectTo.search}`);
      }
    }
  }, [currentUserDetail])

  return (
    <>
      <ChakraProvider theme={currentTheme}>
        <Routes>
          <Route path="auth/sign-in" element={<SignIn />} />
          <Route path="auth/mfa/sign-in" element={<MfaSignin />} />
          <Route path="auth/mfa-enrollment" element={<MFaEnrollment />} />
          <Route path="auth/confirm-mfa-enroll" element={<ConfirmMFAEnrollment />} />
          <Route path="auth/password-reset" element={<ResetPassword />} />
          <Route path="auth/confirm-email" element={<ConfirmEmail />} />
          <Route path="auth/forgot-password" element={<ForgotPassword />} />
          <Route path='auth/action/' element={<AuthAction><AuthUserActions /></AuthAction>} />
          <Route path="/" element={<Navigate to='/dashboard' replace />} />
          <Route path="/*" element={<AppLayout theme={currentTheme} setTheme={setCurrentTheme} />} />
          <Route path="/builder/*" element={<BuilderLayout theme={currentTheme} setTheme={setCurrentTheme} />} />
          <Route path="/360/:id" element={<ExternalTourViewer />} />
          <Route path="/assetViewer/:id" element={<AssetViewer />} />
          </Routes>
      </ChakraProvider >

    </>
  );
}