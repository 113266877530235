

import { DeleteIcon, EditIcon, RepeatIcon, SearchIcon } from '@chakra-ui/icons';
import { Card, CardBody, CardHeader, Text, Image, Flex, Divider, Heading, Center, IconButton, Tooltip, Popover, Box, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody } from '@chakra-ui/react';

import { Handle, Position } from 'reactflow';


const DEFAULT_HANDLE_STYLE = {
  width: 20,
  height: 20
};

function SceneNode({ data, isConnectable }) {



  return (
    <>

      <Card variant={'elevated'}  >
        <Tooltip label="Drag from here to connect">
          <Handle
            type="target"
            position={Position.Left}
            isConnectable={isConnectable}
            style={DEFAULT_HANDLE_STYLE}
          />
        </Tooltip>
        <CardHeader>
          <Center>
            <Heading size={'md'}> {data.label}</Heading>
          </Center>
        </CardHeader>
        <CardBody>

          <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
            <Image width='300px' alt={data.label} src={data.preview!} />
          </Flex>

          <Tooltip label="Remove File" aria-label='Remove File'>
            <IconButton size={"sm"} color={'red'} aria-label='Remove File' icon={<DeleteIcon />} />
          </Tooltip>

          {/* <Tooltip label="Edit Image" aria-label='Edit Image'>
            <IconButton size={"sm"} aria-label='Edit' icon={<EditIcon />} />
          </Tooltip> */}



        </CardBody>
        <Tooltip label="Drag to here to connect">
          <Handle
            type="source"
            position={Position.Right}
            id="b"
            isConnectable={isConnectable}
            style={DEFAULT_HANDLE_STYLE}
          />
        </Tooltip>
      </Card>






    </>
  );
}

export default SceneNode;
