import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, ButtonGroup, IconButton, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useToast } from '@chakra-ui/react'
import { ISharedProjectLinkListObj } from '../../models/SharedProjectLink'
import { FaCopy } from 'react-icons/fa'
import RefreshSharedProjectButton from '../../views/sharedProjects/SharedProjectList/components/ConfirmButtons/refreshSharedProjectButton'

const ProjectSharedProjectsView = (props: any) => {

    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);

    const handleLoadSceneOnClick = async (vtProjId: any) => {
        window.open('/360/' + vtProjId, "_blank")
    };

    const handleOnClickRefresh = async (spLinkId: number) => {
        setIsLoading(true);
        await props.ProjectStore.regenerateSharedProject(spLinkId).finally(() => {
            setIsLoading(false);

        });
    }

    const copyUrlToClipboard = (text) => {
        var url = window.location.origin + '/360/' + text
        navigator.clipboard.writeText(url).finally(() => {


            toast({
                title: 'Success',
                description: 'Shared Project url saved to clipboard!',
                status: 'success',
                duration: 2000,
                isClosable: true,
            });




        });
    }

    return (
        <>
            <Box margin={3} overflowY={'auto'} maxHeight={300}>
                <TableContainer>
                    <Table size={'sm'} variant={'striped'} >
                        <Thead>
                            <Tr>
                                <Th>Name</Th>
                                <Th>Description</Th>
                                <Th>URL</Th>
                                <Th>Views</Th>
                                <Th>Created By</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>

                        <Tbody>


                            {props.SharedProjects.map((sp: ISharedProjectLinkListObj) => (

                                <Tr>
                                    <Td>
                                        <Button onClick={() => handleLoadSceneOnClick(sp.url)} colorScheme='brand' variant='link'>
                                            {sp.sharedProjectName}
                                        </Button>

                                    </Td>
                                    <Td>
                                        {sp.description}
                                    </Td>
                                    <Td>
                                        <ButtonGroup>
                                            <Button onClick={() => handleLoadSceneOnClick(sp.url)} colorScheme='brand' variant='link'>
                                                {sp.url}
                                            </Button>

                                            <IconButton icon={<FaCopy />} onClick={() => copyUrlToClipboard(sp.url)} colorScheme='brand' variant={'ghost'} aria-label={'Copy to Clipboard'} />
                                        </ButtonGroup>

                                    </Td>
                                    <Td>
                                        {sp.viewCount}
                                    </Td>
                                    <Td>  {sp.createdBy}
                                    </Td>
                                    <Td>

                                        <RefreshSharedProjectButton handleAction={() => handleOnClickRefresh(sp.sharedProjectLinkId)} isLoading={isLoading} />

                                    </Td>

                                </Tr>
                            )
                            )}
                        </Tbody>

                    </Table>

                </TableContainer>
            </Box >
        </>
    )
}

ProjectSharedProjectsView.propTypes = {
    SharedProjects: PropTypes.any,
    ProjectStore: PropTypes.any,
    ProjectId: PropTypes.string,
    currentUserId: PropTypes.string
}

export default ProjectSharedProjectsView