import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { IScene } from '../../../../../models/scene';
import { Alert, AlertIcon, Box, Input, InputGroup } from '@chakra-ui/react';
import useEmblaCarousel from 'embla-carousel-react'
import SceneItem from './sceneItem';



const HotspotDialogScenesContent = props => {


    const [searchScenes, setSearchScenes] = useState<IScene[]>([]);
    const [emblaRef] = useEmblaCarousel({ slidesToScroll: 'auto' })


    const handleLinkScene = async (e: string) => {
        const scene = e;
        await props.builderStore.setHotSpotLinkedData(scene, 'HotSpot', props.userId).then(() => { props.handleClose() });
    };

    

    const handleSearchTermChange = (e) => {
        const searchTerm = e.target.value;
        handleSearchScenes(searchTerm)
    };

    const handleSearchScenes = (searchString: string) => {

        var foundScenes = props.builderStore.scenes.filter(
            (sc) =>
                sc.name.toLowerCase().includes(searchString) ||
                sc.name.toUpperCase().includes(searchString)
        );

        if (foundScenes) {
            setSearchScenes(foundScenes);
        } else {
            setSearchScenes(props.builderStore.scenes);
        }
    };

    useEffect(() => {
        setSearchScenes(props.builderStore.scenes);
    }, [])

    return (
        <>
            <InputGroup minWidth={'fit-content'}>
                <Input
                    type={'text'}
                    onChange={handleSearchTermChange}
                    placeholder="Type in the name of the scene to "
                />
            </InputGroup>
            <Box>

                <section className="embla">
                    <div className="embla__viewport" ref={emblaRef}>
                        <div className="embla__container">
                            {searchScenes.length == 0 ? (
                                <Alert status='warning'>
                                    <AlertIcon />
                                    No Scenes Found - Please upload your search criteria!
                                </Alert>
                            ) : (
                                <>
                                    {
                                        searchScenes.map((sc: IScene, index: number) => (
                                            <div className="embla__slide" key={index}>
                                   
                                                <SceneItem scene={sc} handleLinkScene={handleLinkScene} isSelected={props.selectedHotspot.linkedScene == sc.name} />
                                            </div>
                                        ))
                                    }
                                </>
                            )}

                        </div>
                    </div>
                </section>
            </Box>

        </>



    )
}

HotspotDialogScenesContent.propTypes = {
    handleClose: PropTypes.any,
    userId: PropTypes.any,
    builderStore: PropTypes.any,
    selectedHotspot: PropTypes.any
}

export default HotspotDialogScenesContent



