import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { IFileUpload } from '../../../../../models/fileUpload'
import { Box } from '@chakra-ui/react'
import SceneFileListItem from '../sceneFileListItem'


const SceneFileList = (props) => {


  useEffect(() => {

  }, [props])

  return (
    <Box scrollBehavior={"auto"}>
      {props.sceneFiles.map((file: IFileUpload, index: number) =>
      (
        <SceneFileListItem  file={file} index={index} handleSceneChange={props.handleSceneChange} handleSceneRemove={props.handleSceneRemove} handleSceneEdit={props.handleSceneEdit} />
      )
      )}


    </Box >
  )
}

SceneFileList.propTypes = {
  sceneFiles: PropTypes.array,
  handleSceneChange: PropTypes.any,
  handleSceneRemove: PropTypes.any,
  handleSceneEdit: PropTypes.any

}

export default SceneFileList