import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, FormLabel, Select, } from '@chakra-ui/react';
import { AuthContext } from '../../../contexts/auth-context';

const OrgLookupComponent = (props: any) => {

  const [organisations, setOrganisations] = useState([]);
  const { currentOrgs } = useContext(AuthContext);

  useEffect(() => {
    if (props.organisation !== undefined) {
      setOrganisations(props.organisations);
    } else { setOrganisations(currentOrgs) }
  }, [props.organisations, currentOrgs])

  return (
    <>
      <FormControl isRequired={props.isRequired}>
        <FormLabel>{props.label}</FormLabel>
        <Select
          id={props.id}
          name={props.id}
          disabled={props.disabled}
          value={props.value}
          onChange={props.onChange}
          onError={props.error}
          defaultValue={null}

        >
          <option value={null}></option>
          {organisations?.length ? (
            organisations.map((org) => (<option value={org.organisationId as number}>{org.name}</option >))
          ) : (
            <option >loading...</option >
          )}
        </Select>
        {props.error ? (
          <FormHelperText
            sx={{ color: '#bf3333', marginLeft: '16px !important' }}
          >
            {props.helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    </>
  );
};

OrgLookupComponent.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.any,
  organisations: PropTypes.array,
  size: PropTypes.string,
  fontWeight: PropTypes.string,
  fontSize: PropTypes.string

}

export default OrgLookupComponent;
