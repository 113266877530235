import React from 'react';

// chakra imports
import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerOverlay,
    Flex,
    Icon,
    useColorModeValue,
    useDisclosure

} from '@chakra-ui/react';
import {
    renderThumb,
    renderTrack,
    renderView,

} from 'components/scrollbar/Scrollbar';
import { Scrollbars } from 'react-custom-scrollbars-2';

// Assets
import { IoMenuOutline } from 'react-icons/io5';
import BuilderToolboxContent from '../../../components/builder/toolbox/tools';

function BuilderSidebar(props: any) {

    // this is for the rest of the collapses
    let variantChange = '0.2s linear';
    // Chakra Color Mode
    let sidebarMargins = '0px';
    // SIDEBAR
    return (
        <Box
            display={{ sm: 'none', xl: 'block' }}
            position="fixed"
            minH="100%"
        >
            <Box
                bg={'transparent'}
                transition={variantChange}
                w={'285px'}
                ms={{
                    sm: '16px',
                }}
                my={{
                    sm: '16px',
                }}
                h="calc(100vh - 32px)"
                m={sidebarMargins}
                minH="100%"
                overflowX="hidden"
            >
                <Scrollbars
                    autoHide
                    renderTrackVertical={renderTrack}
                    renderThumbVertical={renderThumb}
                    renderView={renderView}
                >

                    <BuilderToolboxContent mini={false} hovered={true} routes={[]} />

                </Scrollbars>
            </Box>
        </Box>
    );
}

// FUNCTIONS
export function BuilderSidebarResponsive(props: {
    routes: RoutesType[];
    [x: string]: any;
}) {
    let menuColor = useColorModeValue('gray.400', 'white');
    let sidebarBg = useColorModeValue('white', 'secondaryGray.800');
    // // SIDEBAR
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();

    const { routes } = props;
    // let isWindows = navigator.platform.startsWith("Win");
    //  BRAND

    return (
        <Flex display={{ sm: 'flex', xl: 'none' }} alignItems="center">
            <Flex ref={btnRef} w="max-content" h="max-content" onClick={onOpen}>
                <Icon
                    as={IoMenuOutline}
                    color={menuColor}
                    my="auto"
                    w="20px"
                    h="20px"
                    me="10px"
                    _hover={{ cursor: 'pointer' }}
                />
                <Drawer
                    isOpen={isOpen}
                    onClose={onClose}
                    placement={document.documentElement.dir === 'rtl' ? 'right' : 'left'}
                    finalFocusRef={btnRef}
                >
                    <DrawerOverlay />
                    <DrawerContent
                        w="px"
                        maxW="300px"
                        ms={{
                            sm: '16px',
                        }}
                        my={{
                            sm: '16px',
                        }}
                        borderRadius="14px"
                        bg={sidebarBg}
                    >
                        <DrawerCloseButton
                            zIndex="3"
                            onClick={onClose}
                            _focus={{ boxShadow: 'none' }}
                            _hover={{ boxShadow: 'none' }}
                        />
                        <DrawerBody maxW="300px" px="0rem" pb="0">
                            <Scrollbars
                                autoHide
                                renderTrackVertical={renderTrack}
                                renderThumbVertical={renderThumb}
                                renderView={renderView}
                            >
                                <BuilderToolboxContent mini={false} hovered={true} routes={[]} />
                            </Scrollbars>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>

            </Flex>

        </Flex>
    );
}
// PROPS

export default BuilderSidebar;
