import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, Container, FormControl, FormLabel, GridItem, HStack, SimpleGrid } from '@chakra-ui/react'
import OrgLookupComponent from '../lookups/orgLookup'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { IUserOrgRole } from '../../models/userOrgRole';

const AddUserRole = (props: any) => {

    const [isProcessing, SetIsProcessing] = useState(false);

    const validationSchema = yup.object({
        organisationId: yup.number().required('Please Select an Organisation'),
    });


    const handleCreateUpdateUserRole = async (userRole: IUserOrgRole) => {
        userRole.userId = props.userId;
        SetIsProcessing(true);
        var userRoleResponse = {} as IUserOrgRole;
        if (!props.userOrgRole) {
            userRoleResponse = await props.userStore.createUserClaim(userRole).finally(() => { SetIsProcessing(false); });
        }
        else {
            alert("Update Yet to be implemented!")
        }

    };

    const formik = useFormik({
        initialValues: {
            organisationId: props.userOrgRole !== undefined ? (props.userOrgRole.organisationId as number) : undefined,
            isAdmin: props.userOrgRole !== undefined ? (props.userOrgRole.isAdmin as boolean) : undefined,
            create: props.userOrgRole !== undefined ? (props.userOrgRole.create as boolean) : undefined,
            view: props.userOrgRole !== undefined ? (props.userOrgRole.view as boolean) : undefined,
            delete: props.userOrgRole !== undefined ? (props.userOrgRole.delete as boolean) : undefined,
            build: props.userOrgRole !== undefined ? (props.userOrgRole.build as boolean) : undefined,
            share: props.userOrgRole !== undefined ? (props.userOrgRole.share as boolean) : undefined
        },
        validationSchema: validationSchema,
        validateOnChange: true,

        onSubmit: (values) => {
            console.log(values);
            handleCreateUpdateUserRole(values as IUserOrgRole);
        }
    });


    return (
        <Container>
            <form onSubmit={formik.handleSubmit}>
                <SimpleGrid columns={2} >
                    <GridItem colSpan={2}>
                        Organisation
                        <OrgLookupComponent
                            id="organisationId"
                            name="organisationId"
                            label=""
                            isRequired={false}
                            size={"lg"}
                            fontSize={"sm"}
                            fontWeight={"500"}
                            value={formik.values.organisationId}
                            onChange={formik.handleChange}
                            organisations={props.currentOrgs}
                        />
                    </GridItem>
                </SimpleGrid>
                <SimpleGrid margin={2} columns={2} spacing='15px'>
                    <GridItem colSpan={1}>
                        <FormLabel>Is Admin</FormLabel>
                        <Checkbox id="isAdmin" onChange={formik.handleChange} name="isAdmin" isChecked={formik.values.isAdmin} ></Checkbox>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <FormLabel>Create</FormLabel>
                        <Checkbox id="create" onChange={formik.handleChange} name="create" isChecked={formik.values.create} ></Checkbox>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <FormLabel>View</FormLabel>
                        <Checkbox id="view" onChange={formik.handleChange} name="view" isChecked={formik.values.view} ></Checkbox>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <FormLabel>Build</FormLabel>
                        <Checkbox id="build" onChange={formik.handleChange} name="build" isChecked={formik.values.build} ></Checkbox>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <FormLabel>Share</FormLabel>
                        <Checkbox id="share" onChange={formik.handleChange} name="share" isChecked={formik.values.share} ></Checkbox>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <FormLabel>Delete</FormLabel>
                        <Checkbox id="delete" onChange={formik.handleChange} name="delete" isChecked={formik.values.delete} ></Checkbox>
                    </GridItem>


                    <GridItem colSpan={2}>

                        <FormControl>
                            <Button type="submit" isLoading={isProcessing} width={'100%'} variant={"brand"} loadingText="Processing"  >{!props.userOrgRole ? "Create" : "Update"} </Button>
                        </FormControl>


                    </GridItem >
                </SimpleGrid>
            </form>
        </Container>
    )
}

AddUserRole.propTypes = {
    currentUser: PropTypes.any,
    currentOrgs: PropTypes.any,
    userStore: PropTypes.any,
    userId: PropTypes.any,
    userOrgRole: PropTypes.any
}

export default AddUserRole