export const encodeSpecialCharacters = (unsafe) => {
  if (unsafe)
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&apos;');
};

export const decodeSpecialCharacters = (safe) => {
  if (safe)
    return safe
      .replace('&amp;', /&/g)
      .replace('&lt;', /</g)
      .replace('&gt;', />/g)
      .replace('&quot;', /"/g)
      .replace('&apos;', /'/g);
};
