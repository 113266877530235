import React, { useContext } from 'react';

// chakra imports
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
} from '@chakra-ui/react';
import Content from 'components/sidebar/components/Content';


// Assets
import { IoMenuOutline } from 'react-icons/io5';

function Sidebar(props: { routes: RoutesType[];[x: string]: any }) {
  const { routes, mini, hovered, setHovered } = props;

  // this is for the rest of the collapses
  let variantChange = '0.2s linear';
  // Chakra Color Mode
  let sidebarMargins = '0px';
  // SIDEBAR
  return (
    <Box
      display={{ base: 'none', xl: 'block' }}
      position="fixed"
      minH="100%"
    // onMouseEnter={() => setHovered(true)}
    // onMouseLeave={() => setHovered(false)}
    >
      <Box
        bg={'transparent'}
        transition={variantChange}
        w={'200px'}
        ms={{
          sm: '10px',
        }}
        my={{
          sm: '16px',
        }}
        h="calc(100vh - 32px)"
        m={sidebarMargins}
        minH="100%"
        overflowX="hidden"
      >
        {/* <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}
        > */}
        <Content mini={false} hovered={hovered} routes={routes}  />
        {/* </Scrollbars> */}
      </Box>
    </Box>
  );
}

// FUNCTIONS
export function SidebarResponsive(props: {
  routes: RoutesType[];
  [x: string]: any;
}) {
  let menuColor = useColorModeValue('gray.400', 'white');
  let sidebarBg = useColorModeValue('white', 'secondaryGray.800');
  // // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const { routes } = props;

  return (
    <Flex display={{ sm: 'flex', xl: 'none' }} alignItems="center">
      <Flex ref={btnRef} w="max-content" h="max-content" onClick={onOpen}>
        <Icon
          as={IoMenuOutline}
          color={menuColor}
          my="auto"
          w="20px"
          h="20px"
          me="10px"
          _hover={{ cursor: 'pointer' }}
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === 'rtl' ? 'right' : 'left'}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          w="300px"
          maxW="300px"
          ms={{
            sm: '10px',
          }}
          my={{
            sm: '10px',
          }}
          borderRadius="14px"
          bg={sidebarBg}
        >
          {/* <DrawerCloseButton
            zIndex="3"
            onClick={onClose}
            _focus={{ boxShadow: 'none' }}
            _hover={{ boxShadow: 'none' }}
          /> */}
          <DrawerBody maxW="300px" px="0rem" pb="0">
            {/* <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={renderView}
            > */}
            <Content mini={false} routes={routes}  />
            {/* </Scrollbars> */}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
// PROPS

export default Sidebar;
