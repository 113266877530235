
// Chakra imports
import {
    Flex
} from '@chakra-ui/react';



import { observer } from 'mobx-react';
import { useUserStore } from '../../../contexts/RootStoreProvider';
import OrganisationViewer from '../../../components/organisations/organisationViewer';
import { AuthContext } from '../../../contexts/auth-context';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';


const OrganisationManagement = () => {
    const userStore = useUserStore();
    const { currentUserDetail } = useContext(AuthContext);


    if (currentUserDetail == undefined || currentUserDetail.isEnterpriseAdmin == false ){return <Navigate to='/' />};


    return (
        <Flex pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Flex direction="column" width="stretch">
                <OrganisationViewer />
            </Flex>
        </Flex>
    );
}

export default observer(OrganisationManagement)
