// chakra imports
import {
    Box,
    Flex,
    Stack,
    Button,
    SimpleGrid,
    Divider,
    Badge,
    FormControl,
    Textarea,
    Tooltip,

} from '@chakra-ui/react';
//   Custom components
import Brand from 'components/sidebar/components/Brand';


import { useEffect, useState } from 'react';

import { useBuilderStore } from '../../../contexts/RootStoreProvider';
import SceneImageEditorButton from './sceneImageEditorButton';
import { IScene } from '../../../models/scene';
import { observer } from 'mobx-react';
import SceneExplorerDrawer from '../../sceneExplorerDrawer';
import ProgressLoader from '../../progressLoader';
import HotspotDialog from '../dialogs/hotspot';

import SceneUploader from './sceneUploader';
import SceneBuilderModal from './sceneBuilder';
import SceneRenameButton from './sceneRename';
import SceneRemoveButton from './sceneRemove';


// FUNCTIONS

function BuilderToolboxContent(props: {
    routes: RoutesType[];
    hovered?: boolean;
    mini?: boolean;
}) {
    const { mini, hovered } = props;

    const [currentScene, setCurrentScene] = useState<IScene>();
    const builderStore = useBuilderStore();

    useEffect(() => {

        var scene = builderStore.scenes.find(
            (sc: IScene) => sc.name === builderStore.currentScene
        );

        if (scene !== undefined) {
            setCurrentScene(scene);
        }
    }, [builderStore.currentScene]);


    const handleSetStartupScene = () => {
        builderStore.setStartupScene();
    };

    const handleRenameScene = async (updatedSceneName) => {

        if (updatedSceneName !== null && updatedSceneName !== currentScene.name) {
            await builderStore.renameCurrentScene(updatedSceneName)
        }
    };

    const handleSetSceneView = () => {
        builderStore.setDefaultView();
    };

    const handleGoToScene = (sceneName: string) => {

        console.log("Go to scene triggered: " + sceneName)
        builderStore.setCurrentScene(sceneName);
    };

    const handleUpdateSceneImage = async (file: any) => {

        await builderStore.uploadSceneImage(
            file,
            currentScene.name,
            builderStore.editorProjectFSId,

        );
    }

    const handleAddHotSpot = () => {
        builderStore.addNewHotSpot('hotspot');
    };

    const handleAddNote = () => {
        builderStore.addNewHotSpot('note');
    };

    const handleAddMedia = () => {
        builderStore.addNewHotSpot('media');
    };

    const handleAddFloorPlan = () => {
        builderStore.addFloorplan();
    };

    const handleAddFloorPlanCameras = (sceneName: string) => {
        debugger;
        builderStore.addNewFpCamera(sceneName);
    };

    const handleAddVideo = () => {
        builderStore.addNewHotSpot('video');
    };

    const handleAddIframe = () => {
        builderStore.addNewHotSpot('weburl');
    };

    const handleRemoveScene = async () => {
        await builderStore.deleteScene(currentScene.name);
    };

    const handleAddAsset = () => {
        builderStore.addNewHotSpot('asset');
    };

    const removeHotspot = () => {
        builderStore.removeHotspot();
    };

    const handleNotImplemented = () => {
        alert("Not Implemented");
    }

    const closeDialogs = () => {
        builderStore.setShowHotspotDialog(false);
        builderStore.clearCurrentHotspot();
    };

    return (
        <>
            <Flex direction="column" height="100%" pt="25px" borderRadius="30px">
                <Brand mini={mini} hovered={hovered} />
                <Stack direction="column" mb="auto" mt="8px">
                    <Box
                        ps={
                            mini === false
                                ? '20px'
                                : mini === true && hovered === true
                                    ? '20px'
                                    : '16px'
                        }
                        pe={{ md: '16px', '2xl': '1px' }}
                        ms={mini && hovered === false ? '-16px' : 'unset'}
                    >
                        <Badge>Current Scene:<Badge size='lg' variant={'subtle'} colorScheme='green'>{builderStore.currentScene || ""}</Badge></Badge>
                        <Badge>Project Summary</Badge>
                        <SimpleGrid columns={1} spacing={2} padding={2}>
                            <FormControl>
                                <Textarea size='sm' noOfLines={3} value={builderStore.editorDescription} readOnly />

                            </FormControl>

                        </SimpleGrid>
                        {/* <Text>Unsaved Changes: {builderStore.hasUnsavedChanges.toString()}</Text> */}

                        <Badge>Scene Menu</Badge>

                        <SimpleGrid columns={2} spacing={2} padding={2}>

                            <Tooltip label={'Set current scene as start up'}>
                                <Button size={'sm'} variant='outline' colorScheme='blue' onClick={() => handleSetStartupScene()}>
                                    Set Startup
                                </Button>
                            </Tooltip>

                            <Tooltip label={'Set current view for current scene'}>
                                <Button size={'sm'} variant='outline' colorScheme='blue' onClick={() => handleSetSceneView()}>
                                    Set View
                                </Button>
                            </Tooltip>

                            {/* Show Button only when the scene has been loaded */}
                            {currentScene ? (<>
                                <SceneRemoveButton size='sm' currentScene={currentScene.name} handleAction={handleRemoveScene} colorScheme='blue' variant='outline' confirmText={'Are you sure you want to remove this scene?'} actionButtonText='Remove Scene' buttonTitle='Remove' />
                            </>) : (<ProgressLoader loadingText='Loading Button' />)}


                            {/* Show Button only when the scene has been loaded */}
                            {currentScene ? (<>
                                <SceneRenameButton size='sm' currentScene={currentScene.name} handleAction={handleRenameScene} colorScheme='blue' variant='outline' confirmText={'Rename the current scene to '} actionButtonText='Rename Scene' buttonTitle='Rename' />
                            </>) : (<ProgressLoader loadingText='Loading Button' />)}


                            {/* Show Button only when the scene has been loaded */}
                            {currentScene ? (<>
                                <SceneImageEditorButton onClickAction={handleUpdateSceneImage} currentSceneObj={currentScene} />
                            </>) : (<ProgressLoader loadingText='Loading Button' />)}


                            <Divider />
                        </SimpleGrid>

                        <Badge>Hotspot Menu</Badge>


                        <SimpleGrid columns={2} spacing={2} padding={2}>

                            <Button size={'sm'} variant='outline' colorScheme='blue' onClick={() => handleAddHotSpot()}>
                                Scene
                            </Button>
                            <Button size={'sm'} variant='outline' colorScheme='blue' onClick={() => handleAddNote()}>
                                Note
                            </Button>
                            <Button size={'sm'} variant='outline' colorScheme='blue' onClick={() => handleAddMedia()}>
                                Image
                            </Button>
                            <Button size={'sm'} variant='outline' colorScheme='blue' onClick={() => handleAddVideo()}>
                                Video
                            </Button>
                            <Button size={'sm'} variant='outline' colorScheme='blue' onClick={() => handleAddIframe()}>
                                Website
                            </Button>

                            {process.env.REACT_APP_ASSET_MODULE == "true"? (
                                <Button size={'sm'} variant='outline' colorScheme='blue' onClick={() => handleAddAsset()}>
                                    Asset
                                </Button>
                            ):(<></>)}


                        </SimpleGrid>

                        <Badge>Tour Menu</Badge>

                        <SimpleGrid columns={1} spacing={2} padding={2}>

                            <SceneBuilderModal projectId={builderStore.editorProjectFSId} builderStore={builderStore} />

                            <SceneUploader projectId={builderStore.editorProjectFSId} builderStore={builderStore} />

                            {/* <SceneBuilder projectId={builderStore.editorProjectFSId} builderStore={builderStore} />  */}

                            {/* Show button when the scenes are definately loaded */}
                            {builderStore.scenes.length > 0 ? (<>
                                <SceneExplorerDrawer goSceneOnClick={handleGoToScene} scenes={builderStore.scenes} />
                            </>) : (<ProgressLoader loadingText='Loading Button' />)}
                            {/* <Button size={'sm'} variant='outline' colorScheme='blue'>
                                Floorplan
                            </Button> */}
                            {/* <Button size={'sm'} variant='outline' colorScheme='blue'>
                                Update Project Details
                            </Button> */}







                        </SimpleGrid>
                        {/* 
<>
                        <Badge>Floor Planners</Badge>

                        <SimpleGrid columns={1} spacing={2} padding={2}>

                            <Button size={'sm'} variant='outline' colorScheme='blue' onClick={() => handleAddFloorPlan()}>
                                Set Flooplan
                            </Button>



                            <SceneCameraAddButton variant={'outline'} colorScheme='blue' size={'sm'} buttonTitle={'Set Cameras'} handleAction={handleAddFloorPlanCameras} />


             

                        </SimpleGrid>
                        </> */}
                    </Box>
                </Stack>
            </Flex>
            <HotspotDialog open={builderStore.showHotSpotDialog} handleClose={closeDialogs} hotspotType={builderStore.hotSpotDialogType} handleRemove={removeHotspot} builderStore={builderStore} />
        </>
    );
}

export default observer(BuilderToolboxContent);
