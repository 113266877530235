import { Icon } from '@chakra-ui/react';
import {
  MdDashboard,
  MdAddHome,
  MdSettings,
  MdPeople,
  MdApartment,
  MdScreenShare
} from 'react-icons/md';

// Admin Imports
import Dashboard from 'views/dashboard';
import Projects from 'views/projects/ProjectList';
import SharedProjects from 'views/sharedProjects/SharedProjectList';
import UserManagement from './views/settings/userManagement';
import OrgManagement from './views/settings/orgManagement';
import SystemManagement from './views/settings/systemManagement';


const routes = [

  // --- Dashboards ---
  {
    name: 'Dashboard',
    layout: '/',
    path: 'dashboard',
    component: <Dashboard />,
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
  },
  {
    name: 'Projects',
    layout: '/',
    path: 'projects',
    component: <Projects />,
    icon: <Icon as={MdAddHome} width="20px" height="20px" color="inherit" />,
  }
  ,
  {
    name: 'Shared Projects',
    layout: '/',
    path: 'shared',
    component: <SharedProjects />,
    icon: <Icon as={MdScreenShare} width="20px" height="20px" color="inherit" />,
  }
  ,
  {
    name: 'Settings',
    path: 'settings',
    layout: '/',

    collapse: true,
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    items: [

      {
        name: 'Users',
        layout: '/',
        path: 'settings/users',
        icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
        component: <UserManagement />,

      },
      {
        name: 'Organisations',
        layout: '/',
        path: 'settings/organisations',
        icon: <Icon as={MdApartment} width="20px" height="20px" color="inherit" />,
        component: <OrgManagement />,
      },
      {
        name: 'System',
        layout: '/',
        path: 'settings/system',
        icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
        component: <SystemManagement />,

      }
    ],
  },







];

export default routes;
