// chakra imports
import {
  Box,
  Flex,
  Stack,
  Image,

} from '@chakra-ui/react';
//   Custom components
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
import UserMenu from '../../UserMenu';


// FUNCTIONS

function SidebarContent(props: {
  routes: RoutesType[];
  hovered?: boolean;
  mini?: boolean;
}) {

  const { routes, mini, hovered } = props;



  // SIDEBAR
  return (
    <Flex direction="column" height="100%" pt="25px" borderRadius="10px">


      <Brand mini={mini} hovered={hovered} />
      <Stack direction="column" mb="auto" mt="8px" >
        <Box
          ps={'20px'}
          pe={{ md: '16px', '2xl': '1px' }}
          ms={'unset'}
        >
          <Links mini={mini} hovered={hovered} routes={routes} />
        </Box>



      </Stack>
      <Stack>
        <Flex alignSelf={"center"} direction={"row"} display={{ sm: 'flex', xl: 'none' }} >
          <UserMenu />
        </Flex>
        <Flex mt="75px" mb="10px"  >
          <Box
            display={'block'}
          >
            <Image padding={5} src={process.env.REACT_APP_CLIENT_LOGO} />  </Box>
        </Flex>

      </Stack>





    </Flex >

  );
}

export default SidebarContent;
