// Chakra Imports
import {
  Flex,
  Hide,
  Show,
} from '@chakra-ui/react';
// Custom Components
import { SidebarResponsive } from 'components/sidebar/Sidebar';


// Assets
import routes from 'routes';
import { BuilderSidebarResponsive } from '../../views/builder/components/builderSidebar';

import UserMenu from '../UserMenu';


export default function HeaderLinks(props: {
  secondary: boolean;
  [x: string]: any;
}) {
  const { secondary, route } = props;




  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg="none"
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      borderRadius="999px"
    >



      {route == "builder" ? (
        <BuilderSidebarResponsive routes={routes} />) : (<SidebarResponsive routes={routes} />)}

      <Flex display={{ sm:'none' , xl: 'flex' }} >
        <UserMenu />
      </Flex>

    </Flex>
  );
}
