import React, { useContext, useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component

import PropTypes from 'prop-types'
import { Box, Button, ButtonGroup, FormControl, FormLabel, GridItem, IconButton, Input, Select, SimpleGrid, Tooltip, useToast } from '@chakra-ui/react'
import admin from '../../../api/admin'
import { GetAllMetaResponse } from '../../../models/admin-api/dto'
import { IMetaData } from '../../../models/metaData'
import ProgressLoader from '../../progressLoader'

import 'ag-grid-enterprise';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { ICellRendererParams } from 'ag-grid-enterprise';
import { FaEdit, FaTrash } from 'react-icons/fa';

import ConfirmButton from '../../confirmButton';
import { DeleteIcon } from '@chakra-ui/icons';
import AddMetaForm from './addNew';
import EditMetaForm from './editMeta';

const MetaManagement = props => {


    const [metaData, setMetaData] = useState<IMetaData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [metaCategory, setMetaCategory] = useState();
    const [metaEntity, setMetaEntity] = useState();

    const toast = useToast();



    const getMetaData = async () => {

        if (metaCategory !== undefined && metaEntity !== undefined) {
            setIsLoading(true);
            await admin.Meta.GetMetaByCategory(metaEntity, metaCategory).then((response: GetAllMetaResponse) => {
                setMetaData(response.result)
            }).finally(() => setIsLoading(false))
        } else {
            toast({
                title: 'Unable to Search',
                description: 'Please provide the Record Type and the Category to retrieve Meta Data!',
                status: 'warning',
                duration: 2000,
                isClosable: true,
            });
        }
    }
    const colDefs = [
        { headerName: "Name", field: "metaKey", flex: 2 }, { headerName: "Name", field: "metaValue", flex: 2 }, { headerName: "Remove", flex: 1, cellRenderer: MetaDataActionButtonRenderer }];


    const handleUpdateMeta = (metaData: IMetaData) => {
    }


    const handleRemoveMeta = async (id: number) => {
        setIsLoading(true);
        await admin.Meta.DeleteMeta(id).then(() => {


            toast({
                title: 'Success',
                description: 'Meta Data has been successfully removed!',
                status: 'success',
                duration: 2000,
                isClosable: true,
            });


        }).catch((error) => {

            toast({
                title: 'Error Removing Metadata',
                description: 'Unable to remove meta data',
                status: 'success',
                duration: 2000,
                isClosable: true,
            });

            console.log("Error:" + error.message);
        }).finally(() => {
            getMetaData();
            setIsLoading(false);
        })
    }

    function MetaDataActionButtonRenderer(props: ICellRendererParams) {
        return (
            <ButtonGroup>
                {isLoading ? (<ProgressLoader />) : (
                    <>

                        <Tooltip label={"Edit"}>
                            <EditMetaForm handleAction={() => handleUpdateMeta(props.data)} metaData={props.data} refreshMeta={() => getMetaData()} />


                        </Tooltip>
                        <Tooltip label={"Remove"}>
                            <ConfirmButton size={'sm'} icon={<DeleteIcon />} handleAction={() => handleRemoveMeta(props.data.metaDataId)} actionButtonText={'Remove'} colorScheme='red' confirmText='Are you sure you want to remove this hotspot?' />
                        </Tooltip>
                    </>
                )}  </ButtonGroup >
        );
    }

    return (
        <Box padding={2}>
            {isLoading ? (<ProgressLoader />) : (

                <SimpleGrid columns={2}>
                    <GridItem padding={2} colSpan={1}>
                        <FormControl paddingTop={4} isRequired>
                            <FormLabel>Record Type</FormLabel>
                            <Select placeholder='Select Record Type' isRequired value={metaEntity} onChange={(e: any) => setMetaEntity(e.target.value || undefined)}>
                                <option value='project'>Project</option>
                            </Select>
                        </FormControl>
                        <FormControl paddingTop={4} isRequired>
                            <FormLabel>Category</FormLabel >
                            <Select placeholder='Select Category' isRequired value={metaCategory} onChange={(e: any) => setMetaCategory(e.target.value || undefined)}>
                                <option value='projectType'>Project Type</option>
                                <option value='projectTags'>Project Tags</option>
                            </Select>
                        </FormControl>
                        <FormControl paddingTop={4}>
                            <ButtonGroup spacing={2}>
                                <Button type='submit' onClick={() => { getMetaData() }} size={'sm'} colorScheme={'brand'}>Search</Button>
                                <AddMetaForm action={'Add'} metaCategory={'projectType'} metaCategoryName={'Project Type'} entity={'Project'} refreshMeta={() => getMetaData()} />
                            </ButtonGroup>
                        </FormControl>
                    </GridItem>
                    <GridItem margin={2} colSpan={1}>
                        <Box>

                            <div
                                className="ag-theme-quartz" // applying the grid theme
                                style={{ height: 500, width: '100%' }} // the grid will fill the size of the parent container
                            >
                                <AgGridReact
                                    rowData={metaData}
                                    columnDefs={colDefs as any}

                                />
                            </div>
                        </Box>
                    </GridItem>

                </SimpleGrid>


            )}

        </Box>


    )
}

MetaManagement.propTypes = {}

export default MetaManagement