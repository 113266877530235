import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react';
import ReactFlow, { Node, Background, Controls, Edge, OnConnect, OnEdgesChange, OnNodesChange, addEdge, applyEdgeChanges, applyNodeChanges, MarkerType, useStore, getStraightPath, BackgroundVariant } from 'reactflow';
import SceneNode from '../forms/project/scenes/sceneBuilder/SceneNode';
import { IScene } from '../../models/scene';
import { IHotspot } from '../../models/hotspot';
import { observer } from 'mobx-react';
import 'reactflow/dist/style.css';



const SceneBuilderContainer = props => {

    const [nodes, setNodes] = useState<Node[]>([]);
    const [edges, setEdges] = useState<Edge[]>([]);


    const nodeTypes = useMemo(() => ({ SceneNode: SceneNode }), []);
    const proOptions = { hideAttribution: true };

    const defaultEdgeOptions = {
        markerStart: { type: MarkerType.ArrowClosed },
        markerEnd: { type: MarkerType.ArrowClosed },
        animated: true,
        style: { strokeWidth: 4, stroke: 'blue' }
    };

    const onNodesChange: OnNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );
    const onEdgesChange: OnEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );
    const onConnect: OnConnect = useCallback(
        (connection) => setEdges((eds) => addEdge(connection, eds)),
        [setEdges]
    );

    useEffect(() => {
        var sceneNodes = props.builderStore.scenes.map((sc: IScene, i) => ({
            id: sc.name,
            type: 'SceneNode',
            data: {
                label: sc.name,
                preview: sc.previewURL,
                renameScene: undefined,
                selectFloor: undefined
            },
            position: { x: i * 250, y: 0 },

        }));

        setNodes(sceneNodes);


        var sceneEdges = [];
        props.builderStore.scenes.map((sc: IScene) => {
            if (sc.hotspots !== undefined) {
                sc.hotspots?.map((hs: IHotspot) => {
                    sceneEdges.push({
                        id: hs.name,
                        source: sc.name,
                        target: hs.linkedScene,
                        type: 'bezier',
                        defaultEdgeOptions: { defaultEdgeOptions },
                        markerStart: { type: MarkerType.ArrowClosed },
                        markerEnd: { type: MarkerType.ArrowClosed },
                        animated: true,
                        style: { strokeWidth: 4, stroke: 'blue' }
                    });
                });
            }
        })





        setEdges(sceneEdges);
    }, [])


    return (

        <Box>

            <div style={{ height: '80vh' }}>
                <ReactFlow
                    nodes={nodes as any}
                    onNodesChange={onNodesChange}
                    edges={edges}
                    onEdgesChange={onEdgesChange}
                    defaultEdgeOptions={defaultEdgeOptions}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    proOptions={proOptions}
                                 >
                    <Background color="#d3d3d3" variant={BackgroundVariant.Cross} />
                    <Controls />
                </ReactFlow>
            </div>
        </Box>
    );

}

SceneBuilderContainer.propTypes = {
    currentProject: PropTypes.any,
    builderStore: PropTypes.any,
    currentUser: PropTypes.any,
    handleClose: PropTypes.any,
    currentProjectId: PropTypes.string,
    source: PropTypes.string
}

export default observer(SceneBuilderContainer)