
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
// Chakra imports
import {
  Button,
  Checkbox,
  Flex,
  Image,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
  Link,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Center,
} from '@chakra-ui/react';
// Custom components

import DefaultAuth from 'layouts/auth/variants/Default';
// Assets
import illustration from 'assets/img/logos/logo.png';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { SignOutUser, getMfaResolver, signInUser, startMfaSignin } from '../../../firebase/firebase';
import { sendEmailVerification } from 'firebase/auth';

const defaultFormFields = {
  email: '',
  password: '',
};

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const toast = useToast();
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [formFields, setFormFields] = React.useState(defaultFormFields);
  const { email, password } = formFields;
  const { isOpen, onOpen, onClose } = useDisclosure()
  const finalRef = React.useRef(null)
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  type RedirectLocationState = {
    redirectTo: Location;
  };

  const resetFormFields = () => {
    return setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setIsLoggingIn(true);
    event.preventDefault();

    try {
      // Send the email and password to firebase
      const userCredential = await signInUser(email, password);


      //Email Validation Check 

      if (userCredential.user.emailVerified) {

        if (process.env.REACT_APP_FORCE_MFA == 'true') {
          //MFA Required 
          console.log("Redirecting to MFA Enrollment Page");
          setIsLoggingIn(false);
          navigate('/auth/mfa-enrollment');
        }
        else {

          if (userCredential) {
            resetFormFields();
            setIsLoggingIn(false);

            if (locationState) {
              // state is any by default
              const { redirectTo } = locationState as RedirectLocationState;
              navigate(`${redirectTo.pathname}${redirectTo.search}`);
            }
            else {
              //Location state was likely the login page so would just redirect otherwise!
              setIsLoggingIn(false);
              navigate('/dashboard');
            }
          }
        }
      } else {
        setIsLoggingIn(false);
        toast({
          title: 'Email Verification Required!',
          description: "We have sent out a email verification, Please check your inbox",
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
        await sendEmailVerification(userCredential.user);
        await SignOutUser();

      }

    } catch (error: any) {
      setIsLoggingIn(false);
      // new code for multifactor
      if (error.code == 'auth/multi-factor-auth-required') {

        console.log("Redirecting to MFA Verification Page")

        // The user is a multi-factor user. 
        // Second factor challenge is required.

        const resolver = getMfaResolver(error);

        // Pass index [0] to get the first multi-factor hint,
        // otherwise create a dropdown menu to allow the user
        // to choose what multi-factor he wants to use.
        await startMfaSignin(resolver.hints[0], resolver.session);

        // Redirect to the MFA form and pass the hint.
        navigate('/auth/mfa/sign-in', { state: { displayName: resolver.hints[0].displayName } })

      } else if (error.code == 'auth/wrong-password') {
        setIsLoggingIn(false);
        // Handle other errors such as wrong password.
        console.log('User Sign In Failed', error.message);

        toast({
          title: 'User Login Failed!',
          description: "Unable to login, please ensure you have the correct details!",
          status: 'error',
          duration: 6000,
          isClosable: true,
        })

      } else {
        setIsLoggingIn(false);
        console.log('User Sign In Failed', error.message);

        toast({
          title: 'User Login Failed!',
          description: "Unable to login, please try again later",
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      }
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <>
      <DefaultAuth illustrationBackground={illustration} image={illustration}>
        <Flex
          maxW={{ base: '100%', md: 'max-content' }}
          w="100%"
          mx={{ base: 'auto', lg: '0px' }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: '30px', md: '60px' }}
          px={{ base: '25px', md: '0px' }}
          mt={{ base: '40px', md: '14vh' }}
          flexDirection="column"
        >
          <Flex>
            <Image
              marginLeft={5}
              marginBottom={10}
              maxH={'100px'}

              src={process.env.REACT_APP_CLIENT_LOGO}
              alt="Welcome to Theia360"
            />
          </Flex>

          <Heading color={textColor} fontSize="25px" >
            Welcome to Theia360, Please Sign In
          </Heading>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: '100%', md: '420px' }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: 'auto', lg: 'unset' }}
            me="auto"
            mb={{ base: '20px', md: 'auto' }}
          >
            <Flex align="center" mb="25px"></Flex>

            <form onSubmit={handleSubmit}>
              <FormControl isRequired>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Email<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  type="email"
                  placeholder="name@domain.com"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  onChange={handleChange}
                  name="email"
                  value={email}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Password<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    isRequired={true}
                    fontSize="sm"

                    mb="24px"
                    size="lg"
                    type={show ? 'text' : 'password'}
                    variant="auth"
                    name="password"
                    onChange={handleChange}
                    value={password}
                  />
                  <InputRightElement display="flex" alignItems="center" mt="4px">
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: 'pointer' }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <Flex justifyContent="space-between" align="center" mb="24px">
                <FormControl isRequired={true} display="flex" alignItems="center">
                  <Checkbox id="terms" colorScheme="brand" me="10px" />
                  <FormLabel
                    htmlFor="terms"
                    mb="0"
                    fontWeight="normal"
                    color={textColor}
                    fontSize="sm"
                  >
                    I accept the <Link color="brand.500" href="#" onClick={onOpen}>Terms and Conditions</Link>
                  </FormLabel>
                </FormControl>

              </Flex>
              <Button
                type="submit"
                fontSize="sm"
                variant="brand"
                isLoading={isLoggingIn}
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
              >
                Sign In
              </Button>
              <Flex
                flexDirection="column"
                alignItems="end"
                maxW="100%"
                mt="0px"
              >
                <NavLink to="/auth/forgot-password">
                  <Text
                    color={textColorBrand}
                    fontSize="md"
                    fontWeight="500"
                  >
                    Forgot password?
                  </Text>
                </NavLink>
              </Flex>
              <div >
                <input id="recaptcha" type="submit" />
              </div>
            </form>
          </Flex>
        </Flex>

      </DefaultAuth>
      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} size={'xl'} scrollBehavior={'inside'} >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>End-User License Agreement (EULA) of Theia360</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            This End-User License Agreement ("EULA") is a legal agreement
            between you and Exactly Tech Ltd. Our EULA was created by EULA
            Template for Theia360. This EULA agreement governs your acquisition
            and use of our Theia360 software ("Software") directly from Exactly
            Tech Ltd or indirectly through a Exactly Tech Ltd authorized
            reseller or distributor (a "Reseller"). Please read this EULA
            agreement carefully before completing the installation process and
            using the Theia360 software. It provides a license to use the
            Theia360 software and contains warranty information and liability
            disclaimers. If you register for a free trial of the Theia360
            software, this EULA agreement will also govern that trial. By
            clicking "accept" or installing and/or using the Theia360 software,
            you are confirming your acceptance of the Software and agreeing to
            become bound by the terms of this EULA agreement. If you are
            entering into this EULA agreement on behalf of a company or other
            legal entity, you represent that you have the authority to bind such
            entity and its affiliates to these terms and conditions. If you do
            not have such authority or if you do not agree with the terms and
            conditions of this EULA agreement, do not install or use the
            Software, and you must not accept this EULA agreement. This EULA
            agreement shall apply only to the Software supplied by Exactly Tech
            Ltd herewith regardless of whether other software is referred to or
            described herein. The terms also apply to any Exactly Tech Ltd
            updates, supplements, Internet-based services, and support services
            for the Software, unless other terms accompany those items on
            delivery. If so, those terms apply. License Grant Exactly Tech Ltd
            hereby grants you a personal, non-transferable, non-exclusive
            licence to use the Theia360 software on your devices in accordance
            with the terms of this EULA agreement. You are permitted to load the
            Theia360 software (for example a PC, laptop, mobile or tablet) under
            your control. You are responsible for ensuring your device meets the
            minimum requirements of the Theia360 software. You are not permitted
            to: Edit, alter, modify, adapt, translate or otherwise change the
            whole or any part of the Software nor permit the whole or any part
            of the Software to be combined with or become incorporated in any
            other software, nor decompile, disassemble or reverse engineer the
            Software or attempt to do any such things Reproduce, copy,
            distribute, resell or otherwise use the Software for any commercial
            purpose Allow any third party to use the Software on behalf of or
            for the benefit of any third party Use the Software in any way which
            breaches any applicable local, national or international law use the
            Software for any purpose that Exactly Tech Ltd considers is a breach
            of this EULA agreement Intellectual Property and Ownership Exactly
            Tech Ltd shall at all times retain ownership of the Software as
            originally downloaded by you and all subsequent downloads of the
            Software by you. The Software (and the copyright, and other
            intellectual property rights of whatever nature in the Software,
            including any modifications made thereto) are and shall remain the
            property of Exactly Tech Ltd. Exactly Tech Ltd reserves the right to
            grant licences to use the Software to third parties. Termination
            This EULA agreement is effective from the date you first use the
            Software and shall continue until terminated. You may terminate it
            at any time upon written notice to Exactly Tech Ltd. It will also
            terminate immediately if you fail to comply with any term of this
            EULA agreement. Upon such termination, the licenses granted by this
            EULA agreement will immediately terminate and you agree to stop all
            access and use of the Software. The provisions that by their nature
            continue and survive will survive any termination of this EULA
            agreement. Governing Law This EULA agreement, and any dispute
            arising out of or in connection with this EULA agreement, shall be
            governed by and construed in accordance with the laws of gb.
          </ModalBody>
          <ModalFooter>
            <Button variant={'brand'} mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SignIn;
