// Custom components  
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Card from 'components/card/Card';
import PropTypes from 'prop-types'
import { Tooltip, ButtonGroup, Button, Flex, Text, IconButton, useColorModeValue, useToast, Box, useMediaQuery, InputGroup, InputLeftElement, Input } from '@chakra-ui/react';

//Data Table 
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import 'ag-grid-enterprise';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

import ProjectActionsRowRenderer from './SharedProjectActionsRowRenderer';
import ProgressLoader from '../../../../components/progressLoader';

import { observer } from 'mobx-react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { useNavigate } from 'react-router-dom';
import { FaCopy } from 'react-icons/fa';
import { SearchIcon } from '@chakra-ui/icons';



const SharedProjectsList = (props: any) => {

    const [isLoading, setIsLoading] = useState(false);
    const searchIconColor = useColorModeValue('gray.700', 'white');
    const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
    const inputText = useColorModeValue('gray.700', 'gray.100');
    const borderColor = useColorModeValue('secondaryGray.200', 'whiteAlpha.200');


    const textColor = useColorModeValue("navy.700", "white");
    const [useMobileCheck] = useMediaQuery('(max-width: 760px)');
    const navigate = useNavigate();
    const toast = useToast();

    const gridRef = useRef<AgGridReact>(null);

    const copyUrlToClipboard = (text) => {
        var url = window.location.origin + '/360/' + text
        navigator.clipboard.writeText(url).finally(() => {


            toast({
                title: 'Success',
                description: 'Shared Project url saved to clipboard!',
                status: 'success',
                duration: 2000,
                isClosable: true,
            });




        });
    }


    function DateCellRenderer(props: ICellRendererParams) {
        try {
            return new Date(props.value).toLocaleString();
        }
        catch (ex: any) {
            console.log("Date Cell Renderer Error: ", ex);
            return props.value
        }
    }

    function ViewSharedProjectCellRenderer(props: ICellRendererParams) {
        return (

            <Tooltip label={"View Shared Project"}>

                <Button onClick={() => window.open("/360/" + props.data.url, "_blank")} colorScheme='brand' variant='link'>
                    {props.value}
                </Button>
            </Tooltip>

        );
    }


    function URLSharedProjectCellRenderer(props: ICellRendererParams) {
        return (
            <ButtonGroup>
                <Button onClick={() => window.open("/360/" + props.data.url, "_blank")} colorScheme='brand' variant='link'>
                    {props.value}
                </Button>

                <Tooltip label={"Copy URL to Clipboard"}>
                    <IconButton icon={<FaCopy />} onClick={() => copyUrlToClipboard(props.value)} colorScheme='brand' variant={'ghost'} aria-label={'Copy to Clipboard'} />
                </Tooltip>
            </ButtonGroup>
        );
    }


    function ViewProjectCellRenderer(props: ICellRendererParams) {
        return (

            <Tooltip label={"View Shared Project"}>
                <Button onClick={() => navigate("/projects/viewer/" + props.data.projectId)} colorScheme='brand' variant='link'>
                    {props.value}
                </Button>
            </Tooltip>

        );
    }

    const colDefs = [
        { headerName: "Name", field: "sharedProjectName", cellRenderer: ViewSharedProjectCellRenderer },
        { headerName: "Summary", field: "description", flex: 2 },
        { headerName: "Organisation", field: "organisation", flex: 2 },
        { headerName: "URL", field: "url", flex: 1, cellRenderer: URLSharedProjectCellRenderer },
        { headerName: "Original Project", field: "projectName", flex: 2, cellRenderer: ViewProjectCellRenderer },
        { headerName: "Views", field: "viewCount", flex: 1 },
        { headerName: "Created By", field: "createdBy", flex: 1 },
        { headerName: "Created On", field: "createdOn", flex: 1, cellRenderer: DateCellRenderer },
        { headerName: "Actions", field: "sharedProjectLinkId", cellRenderer: ProjectActionsRowRenderer, cellRendererParams: { projectStore: props.projectStore, currentUserRoles: props.currentUserRoles, } }
    ];

    const colDefsMobile = [
        { headerName: "Name", field: "sharedProjectName", cellRenderer: ViewSharedProjectCellRenderer },

        { headerName: "Actions", field: "sharedProjectLinkId", cellRenderer: ProjectActionsRowRenderer, cellRendererParams: { projectStore: props.projectStore, currentUserRoles: props.currentUserRoles, } }
    ];

    useEffect(() => {
        setIsLoading(true);
        props.projectStore.getSharedProjects().finally(() => {
            setIsLoading(false)
        })
    }, [])


    const onFilterTextBoxChanged = useCallback(() => {

        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);


    return (
        <Card>
            <Flex direction='column' w='100%' >
                <Flex align={{ lg: 'center' }} justify={{ base: 'space-between' }} w='100%' px='20px' mb='20px'>

                    <Text color={textColor} fontSize='xl' fontWeight='600' lineHeight='100%'>
                        Shared Projects
                    </Text>

                    <InputGroup w={{ base: '100%', md: '200px', lg: '600px' }} >
                        <InputLeftElement
                            children={
                                <IconButton
                                    aria-label="search"
                                    bg="inherit"
                                    borderRadius="inherit"
                                    _active={{
                                        bg: 'inherit',
                                        transform: 'none',
                                        borderColor: 'transparent',
                                    }}
                                    _hover={{
                                        background: 'none',
                                    }}
                                    _focus={{
                                        background: 'none',
                                        boxShadow: 'none',
                                    }}
                                    icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
                                />
                            }
                        />
                        <Input

                            onInput={onFilterTextBoxChanged}
                            id="filter-text-box"
                            variant="search"
                            fontSize="sm"
                            bg={inputBg}
                            color={inputText}
                            fontWeight="500"
                            _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                            borderRadius={'30px'}
                            placeholder={'Search Shared Projects...'}
                            border="1px solid"
                            borderColor={borderColor}
                        />
                    </InputGroup>


                </Flex>
                <Box>

                    {isLoading ? (<ProgressLoader loadingText='Loading Shared Projects' />) : (


                        <div
                            className="ag-theme-quartz" // applying the grid theme
                            style={{ height: 650 }} // the grid will fill the size of the parent container
                        >
                            <AgGridReact
                                rowGroupPanelShow={'always'}
                                rowData={props.projectStore.sharedProjects}
                                ref={gridRef}
                                columnDefs={useMobileCheck ? colDefsMobile as any : colDefs as any}
                                pagination
                            />
                        </div>

                    )}

                </Box>
            </Flex>
        </Card>
    )
}

SharedProjectsList.propTypes = {
    currentUser: PropTypes.any,
    currentOrgs: PropTypes.any,
    projectStore: PropTypes.any,
    currentUserRoles: PropTypes.any,
}

export default observer(SharedProjectsList)