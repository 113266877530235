import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Flex,
  Heading,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
// Custom components

import DefaultAuth from 'layouts/auth/variants/Default';
// Assets
import illustration from 'assets/img/logos/logo.png';
import { confirmUserEmail } from '../../../firebase/firebase';


function ConfirmEmail() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [show, setShow] = React.useState(false);
  const [confirmEmailSuccess, setConfirmEmailSuccess] = useState(false)


  const toast = useToast();

  let oobCode: string | null = searchParams.get('oobCode')

  console.log(oobCode);

  // The hook is running twice when <React.StrictMode> is activated
  // causing the oobCode to be reused.

  useEffect(() => {
    if (oobCode) {
      try {
        confirmUserEmail(oobCode)
        setConfirmEmailSuccess(true)
      } catch (error) {


        toast({
          title: 'Error!',
          description: "Error Verifying Email!",
          status: 'error',
          duration: 4000,
          isClosable: true,
      })

      console.log(error)



      }
    }

    // cleanup function.
    return () => {
      oobCode = null
    }
  }, [])



  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Email Confirmation
          </Heading>

        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <Flex align="center" mb="25px"></Flex>

          {confirmEmailSuccess && oobCode ?
            <div>
              <h3>Thank you for confirming your email address!</h3>
              <button onClick={() => navigate('/')}>Login</button>
            </div>
            :
            <div>
              <h3>There was a problem confirming your email.</h3>
              <button onClick={() => navigate('/')}>Back to Home Page</button>
            </div>
          }

          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          ></Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default ConfirmEmail;
