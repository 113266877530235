import PropTypes from 'prop-types'
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import NewOrganisationForm from '../../forms/organisations/new/content'



const OrgButton = (props) => {
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const { isOpen, onOpen, onClose } = useDisclosure()


    return (
        <>
            <Button onClick={onOpen} width={props.currentOrg ? '100%' : null} fontSize={props.currentOrg ? 'xl' : 'sm'}  variant={'outline'} color={textColorBrand}> {props.buttonLabel}</Button>
            <Modal closeOnOverlayClick={false} size={'6xl'} isOpen={isOpen} isCentered onClose={onClose} motionPreset='slideInBottom' scrollBehavior={'inside'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{props.buttonLabel}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <NewOrganisationForm currentOrg={props.currentOrg} currentUser={props.currentUser} currentOrgs={props.currentOrgs} />
                    </ModalBody>
                </ModalContent>
            </Modal >
        </>
    )
}

OrgButton.propTypes = {
    currentUser: PropTypes.any,
    currentOrg: PropTypes.any,
    currentOrgs: PropTypes.any,
    buttonLabel: PropTypes.string
}

export default OrgButton