import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, FormLabel, GridItem, Input, SimpleGrid, Textarea } from '@chakra-ui/react';
import { decodeSpecialCharacters, encodeSpecialCharacters } from '../../../../../helpers/stringHelpers';
import { observer } from 'mobx-react';
import ProgressLoader from '../../../../progressLoader';

const HotspotDialogNoteContent = props => {

    const [notetext, setNoteText] = useState('');

    useEffect(() => {
        if (
            props.builderStore.currentHotspot?.text! === '' ||
            props.builderStore.currentHotspot?.text! === undefined ||
            props.builderStore.currentHotspot?.text! === 'undefined'
        ) {
            setNoteText('');
        } else {
            setNoteText(props.builderStore.currentHotspot?.text!);
        }
    }, [props.builderStore.currentHotspot]);

    const handleLinkNote = async (e: string) => {
        await props.builderStore.setHotSpotLinkedData(
            encodeSpecialCharacters(e),
            'Note',
            props.userId
        ).then(() => { props.handleClose() });
    };

    function handleChange(e) {
        setNoteText(e.target.value);
    }

    return (
        <>
            <SimpleGrid spacing={3}>

                {props.builderStore.isLoading ? (<ProgressLoader />) : (<>
                    <GridItem >
                        <FormLabel>Note</FormLabel>
                        <Textarea

                            placeholder={'Add your note text here'}
                            onChange={handleChange}
                            value={decodeSpecialCharacters(notetext)}
                        />
                    </GridItem>
                    <GridItem>
                        <Button
                            variant={'brand'}
                            bgColor={'brand'}
                            onClick={() => handleLinkNote(notetext)}
                            isLoading={props.builderStore.isLoading}
                        >
                            Update Note
                        </Button>
                    </GridItem></>)}
            </SimpleGrid>
        </>
    )
}

HotspotDialogNoteContent.propTypes = {
    handleClose: PropTypes.any,
    userId: PropTypes.any,
    builderStore: PropTypes.any,
    selectedHotspot: PropTypes.any
}

export default observer(HotspotDialogNoteContent)