import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Divider, Heading, Link, Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tooltip, Tr, useToast } from '@chakra-ui/react'
import { IProjectItem } from '../../models/vtProject'
import { useBuilderStore } from '../../contexts/RootStoreProvider'
import { useNavigate } from 'react-router-dom'
import { MdImage } from 'react-icons/md'
import { IAudit } from '../../models/audit'

const ProjectAuditItemsView = (props: any) => {

    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const builderStore = useBuilderStore();
    const navigate = useNavigate();

    const handleLoadSceneOnClick = async (
        vtProjId: any,
        sceneId: string,
        hotspotId: string
    ) => {

        setIsLoading(true);

        // var valProjForView = await props.ProjectStore.viewProject(vtProjId);
        // if (valProjForView !== undefined) {

        builderStore.startViewer(vtProjId).then((response: any) => {
            setIsLoading(false);
            navigate('/projects/viewer/' + vtProjId, { state: { sceneId: sceneId, hotspotId: hotspotId, tourXml: response.tourXml } });
        });
        // } else {
        //     toast({
        //         title: 'Error!',
        //         description: 'Missing Project Requirements - Please Try Again!',
        //         status: 'error',
        //         duration: 2000,
        //         isClosable: true,
        //     });
        // }
    };


    return (
        <>
            <Box margin={3} overflowY={'auto'} maxHeight={300}>
                {/* <Heading marginBottom={2} size={'sm'}>Audit History  </Heading><Divider /> */}
                <TableContainer>
                <Table size={'sm'} variant={'striped'} >
                        <Thead>
                            <Tr>
                                <Th>User</Th>
                                <Th>eventDetails</Th>
                                <Th>eventDate</Th>
                                

                            </Tr>
                        </Thead>

                        <Tbody>


                            {props.AuditItems.map((item: IAudit) => (

                                <Tr>
                                    <Td>
                                        {item.userName}
                                    </Td>
                                    <Td>
                                        {item.eventName}
                                    </Td>
                                    <Td>
                                        {new Date(item.eventDate).toLocaleString()}
                                    </Td>
                                    
                                </Tr>
                            )
                            )}
                        </Tbody>

                    </Table>

                </TableContainer>
            </Box >
        </>
    )
}

ProjectAuditItemsView.propTypes = {
    AuditItems: PropTypes.any,
    ProjectId: PropTypes.string
}


export default ProjectAuditItemsView