import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import NewProjectScenes from "../../../forms/project/scenes/index";
import PropTypes from 'prop-types'


const SceneUploader = (props: any) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <Button size={'sm'} variant='outline' colorScheme='blue' onClick={onOpen} >
                Scene Uploader
            </Button>
            <Modal size={'5xl'} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Upload Scenes</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <NewProjectScenes builderStore={props.builderStore} source={'Builder'}  currentProjectId={props.projectId} handleClose={onClose}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme={'brand'} onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

SceneUploader.propTypes = {
    projectId: PropTypes.string,
    builderStore:PropTypes.any
}

export default SceneUploader