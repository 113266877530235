// Chakra imports
import { AspectRatio, Box, Grid, useToast } from '@chakra-ui/react';
import { useLocation, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/auth-context';

import { useBuilderStore } from '../../contexts/RootStoreProvider';
import TourBuilderViewer from '../../components/tourBuilderViewer';


export default function ProjectBuilder() {

	const builderStore = useBuilderStore();
	const { currentUser } = useContext(AuthContext);
	const toast = useToast();


	const [isLoading, setIsLoading] = useState(true);
	const { id } = useParams();
	const { state } = useLocation();

	useEffect(() => {

		setIsLoading(true);
		builderStore.startBuilder(currentUser.uid, id).finally(() => {
			setIsLoading(false);
		}).catch((error: any) => {

			toast({
				title: 'Error!',
				description: 'Error Loading Tour Builder Project! ' + error.message,
				status: 'error',
				duration: 2000,
				isClosable: true,
			})

		});

	}, [])

	return (
		<>
			<Box maxW='100%'>
				<Grid maxW='100%' display={{ base: 'block', lg: 'grid' }} pt={{ base: '130px', md: '80px', xl: '80px' }}>
					<AspectRatio w='100%' maxW='100%' ratio={1130 / 636}>
						<TourBuilderViewer />
					</AspectRatio>

				</Grid >
			</Box >

		</>

	);
}
