import { IUserOrgRole } from '../models/userOrgRole';

export function checkOrgAccess(
  currentOrgRoles: IUserOrgRole[],
  orgId: number,
  role: string,
): boolean {



  if (currentOrgRoles) {
    var orgRole = currentOrgRoles.find(
      (role: IUserOrgRole) => role.organisationId == orgId,
    );

    if (orgRole) {
      switch (role) {
        case 'create':
          return orgRole.create;
        case 'delete':
          return orgRole.delete;
        case 'build':
          return orgRole.build;
        case 'isAdmin':
          return orgRole.isAdmin;
        case 'share':
          return orgRole.share;
        case 'view':
          return orgRole.view;
        default:
          return false;
      }
    } else {
      return false;
    }
  }
}

export function checkAccess(
  currentOrgRoles: IUserOrgRole[],
  role: string,
): boolean {
  var orgRole = undefined;

  switch (role) {
    case 'create':
      orgRole = currentOrgRoles.find(
        (role: IUserOrgRole) => role.create == true,
      );
      break;

    case 'delete':
      orgRole = currentOrgRoles.find(
        (role: IUserOrgRole) => role.delete == true,
      );
      break;
    case 'build':
      orgRole = currentOrgRoles.find(
        (role: IUserOrgRole) => role.build == true,
      );
      break;
    case 'isAdmin':
      orgRole = currentOrgRoles.find(
        (role: IUserOrgRole) => role.isAdmin == true,
      );
      break;
    case 'share':
      orgRole = currentOrgRoles.find(
        (role: IUserOrgRole) => role.share == true,
      );
      break;
    case 'view':
      orgRole = currentOrgRoles.find((role: IUserOrgRole) => role.view == true);
      break;
    default:

    return false;

  }
}
