// Chakra imports
import {
  Portal,
  Box,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import { useContext, useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import routes from 'routes';
import { AuthContext } from '../../contexts/auth-context';
import Projects from '../../views/projects/ProjectList';
import { multiFactor } from 'firebase/auth';
import userManagement from '../../views/settings/userManagement';
import ProjectViewer from '../../views/projects/ProjectViewer';
import orgManagement from '../../views/settings/orgManagement';
import SystemManagement from '../../views/settings/systemManagement';
import { observer } from 'mobx-react';
import { useBuilderStore } from '../../contexts/RootStoreProvider';


// Custom Chakra theme
export default observer(function AppLayout(props: { [x: string]: any }) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(true);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [mini, setMini] = useState(false);
  const [hovered, setHovered] = useState(false);
  const { currentUser,currentUserDetail } = useContext(AuthContext);
  const builderstore = useBuilderStore();

  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== '/app/full-screen-maps';
  };

  const getActiveRoute = (routes: RoutesType[]): string => {
    let activeRoute = 'Main Dashboard';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: number) => {

      if (route.layout === '/') {

        return (
          <Route path={`${route.path}`} element={route.component} key={key} />
        );
      }
      if (route.collapse) {
        return getRoutes(route.items);
      } else {
        return null;
      }
    });
  };


  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();
  const bg = useColorModeValue('background.100', 'background.900');
  let location = useLocation();


  if (!currentUserDetail || currentUserDetail === undefined) {
    //Check Multi Factor Users 
    return <Navigate to='/auth/sign-in' replace state={{ redirectTo: location }} />;
  }

  const MfaEnrollment = multiFactor(currentUser).enrolledFactors;

  if (process.env.REACT_APP_FORCE_MFA && MfaEnrollment.length === 0) {
    //Force MFA Enrollment
    return <Navigate to='/auth/mfa-enrollment' replace state={{ redirectTo: location }} />;
  }

  return (
    <Box bg={bg} h="100vh" w="100vw">
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar
          hovered={hovered}
          setHovered={setHovered}
          mini={mini}
          routes={routes}
          display="none"
          {...rest}
        />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: '100%', xl: 'calc( 100% - 200px )' }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                hovered={hovered}
                setMini={setMini}
                mini={mini}
                projectName={builderstore.currentViewProjectName}
                onOpen={onOpen}
                logoText={'Theia360: Interactive Asset Management'}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                theme={props.theme}
                setTheme={props.setTheme}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box
              mx="auto"
              p={{ base: '20px', md: '30px' }}
              pe="20px"
              minH="100vh"
              pt="50px"
            >
              <Routes>
                {getRoutes(routes)}
                <Route path='projects/*' Component={Projects} />
                <Route path='projects/viewer/:id' Component={ProjectViewer}  />
                <Route path='settings/users' Component={userManagement} />
                <Route path='settings/organisations' Component={orgManagement} />
                <Route path='settings/system' Component={SystemManagement} />
              </Routes>

            </Box>
          ) : null}
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
})
