import { GridItem, Image, Stack } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { CustomCellRendererProps } from "ag-grid-react";

import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../contexts/auth-context";
import { IOrganisation } from "../../../../models/organisation";

export default (params: CustomCellRendererProps) => {

    const navigate = useNavigate();
    const { currentOrgs } = useContext(AuthContext);
    const [org, SetOrg] = useState<IOrganisation>(undefined);

    useEffect(() => {
        getOrg(params.value);
    }, [org])

    const getOrg = (orgId: number) => {
        console.log(orgId)
        var currentOrg = currentOrgs.find((org: IOrganisation) => org.organisationId == orgId);
        SetOrg(currentOrg);
    }

    return (
        <>
            <Stack direction="row" spacing={2}>
               <GridItem>
                    {/* <Image
                          borderRadius='full'  boxSize='50px' sizes={'sm'} src={org?.logoUrl}
                        alt={org?.organisationName}
                    /> */}
                </GridItem>
                <GridItem>
                    {org?.name}
                </GridItem>

            </Stack>

        </>




    )
}