import React from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Heading } from '@chakra-ui/react'
import MetaManagement from './management'

const MetadataSettings = (props:any) => {
  return (
  
    <>
 


    <MetaManagement/>

      </>



  )
}

MetadataSettings.propTypes = {}

export default MetadataSettings