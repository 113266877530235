import React from 'react'
import PropTypes from 'prop-types'
import { Button, FormControl, FormLabel, GridItem, Input, SimpleGrid, Textarea, useColorModeValue, useToast } from '@chakra-ui/react'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useProjectStore } from '../../../../../contexts/RootStoreProvider';
import { IVTProject } from '../../../../../models/vtProject';
import { observer } from 'mobx-react';
import OrgLookupComponent from '../../../../lookups/orgLookup';
import ProjectTypeLookupComponent from '../../../../lookups/projectTypeLookup';
import SiteLookupComponent from '../../../../lookups/siteLookup';



const ProjectForm = (props: any) => {

    const [isLoading, setIsLoading] = React.useState(false);
    const projectStore = useProjectStore();
    const textColor = useColorModeValue('navy.700', 'white');
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    const toast = useToast();

    function handleCreateUpdateProject(values: {
        vtProjectName: string;
        vtProjectIfaceId: string;
        vtProjectOrganisationId: any;
        vtProjectType: string;
        vtProjectDesc: string;
        vtProjectMeta: string;
        vtProjectSiteId: number;
    }) {
        setIsLoading(true);

        if (!props.project) {
            projectStore
                .createProject(values as unknown as IVTProject)
                .then((response: IVTProject) => {

                    props.setActiveStep(2);

                }).catch((error) => {

                    toast({
                        title: 'Error',
                        description: 'Error Creating Project! - Please try again',
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                    });

                    console.log(error)
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        else {

            var updatedProject = props.project;
            updatedProject.vtProjectName = values.vtProjectName;
            updatedProject.vtProjectIfaceId = values.vtProjectIfaceId;
            updatedProject.vtProjectMeta = values.vtProjectMeta;
            updatedProject.vtProjectType = values.vtProjectType;
            updatedProject.vtProjectDesc = values.vtProjectDesc;

            projectStore
                .updateProject(updatedProject)
                .then((response: IVTProject) => {

                    toast({
                        title: 'Success',
                        description: 'Project Updated!',
                        status: 'success',
                        duration: 2000,
                        isClosable: true,
                    });

                    


                }).catch((error) => {

                    toast({
                        title: 'Error',
                        description: 'Error Creating Project! - Please try again',
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                    });

                    console.log(error)
                })
                .finally(() => {
                   


                    projectStore.getProjects().finally(() => {
                        //Clear the uploads, were done
                        setIsLoading(false);
                      })
        


                });

        }
    }

    const validationSchema = yup.object({
        vtProjectName: yup.string().required('Project Name is required'),
        // vtProjectIfaceId: yup.string().required('Project Reference is required'),
        vtProjectOrganisationId: yup.number().required('Please Select an Organisation'),
        vtProjectDesc: yup.string().max(500, 'Please enter a description less than 500 characters')

    });

    const formik = useFormik({
        initialValues: {
            vtProjectName: props.project?.vtProjectName || '',
            vtProjectIfaceId: props.project?.vtProjectIfaceId || null,
            vtProjectOrganisationId: props.project?.vtProjectOrganisationId || '',
            vtProjectType: props.project?.vtProjectType || '',
            vtProjectDesc: props.project?.vtProjectDesc || '',
            vtProjectMeta: '',
            vtProjectSiteId: props.project?.vtProjectSiteId || null
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log(values);
            handleCreateUpdateProject(values);
        }
    });





    return (
        <>

            <form onSubmit={formik.handleSubmit}>

                <SimpleGrid columns={{ base: 2, sm: 1, md: 2, lg: 2 }} columnGap={3} rowGap={6} >
                    <GridItem>
                        <FormControl isRequired >
                            <FormLabel>Project Name</FormLabel>
                            <Input
                                isRequired={true}
                                id="vtProjectName"
                                name="vtProjectName"
                                fontSize="sm"
                                ms={{ base: '0px', md: '0px' }}
                                type="text"
                                placeholder="Project Name"
                                color={textColor}
                                fontWeight="500"
                                size="lg"
                                onChange={formik.handleChange}
                                value={formik.values.vtProjectName}
                            />

                        </FormControl>
                    </GridItem>
                    <FormControl>
                        <FormLabel>Project Reference</FormLabel>
                        <Input
                            id="vtProjectIfaceId"
                            name="vtProjectIfaceId"
                            isRequired={false}
                            fontSize="sm"
                            placeholder="Project Ref"
                            color={textColor}
                            fontWeight="500"
                            size="lg"
                            onChange={formik.handleChange}
                            value={formik.values.vtProjectIfaceId}
                        />
                    </FormControl>

                    <GridItem>
                        <FormControl isRequired>
                            {/* <FormLabel>Organisation</FormLabel> */}
                            <OrgLookupComponent
                                id="vtProjectOrganisationId"
                                name="vtProjectOrganisationId"
                                label="Organisation"
                                isRequired={true}
                                size={"lg"}
                                fontSize={"sm"}
                                fontWeight={"500"}
                                value={formik.values.vtProjectOrganisationId}
                                onChange={formik.handleChange}
                                organisations={props.currentOrgs}
                                disabled={props.project}
                            />
                        </FormControl>
                    </GridItem>
                    {process.env.REACT_APP_SITE_MODULE == "true" ? (
                    <GridItem>
                        <FormControl isRequired>
                            {/* <FormLabel>Organisation</FormLabel> */}
                            <SiteLookupComponent
                                id="vtProjectSiteId"
                                name="vtProjectSiteId"
                                label="Site"
                                isRequired={false}
                                size={"lg"}
                                fontSize={"sm"}
                                fontWeight={"500"}
                                value={formik.values.vtProjectSiteId}
                                onChange={formik.handleChange}
                                organisation={formik.values.vtProjectOrganisationId}
                                disabled={formik.values.vtProjectOrganisationId == '' || formik.values.vtProjectOrganisationId == undefined }
                            />
                        </FormControl>
                    </GridItem>
                    ):(<></>)}

                    <GridItem>
                        <ProjectTypeLookupComponent
                            id="vtProjectType"
                            name="vtProjectType"
                            label="Project Type"
                            disabled={false}
                            isRequired={true}
                            size={"lg"}
                            fontWeight={"500"}
                            value={formik.values.vtProjectType}
                            onChange={formik.handleChange}
                            // error={
                            //     formik.touched.vtProjectType &&
                            //     Boolean(formik.errors.vtProjectType)
                            // }
                            currentUser={props.currentUser}
                        />
                    </GridItem>

                    {/* <GridItem colSpan={2}>
                        <FormControl isRequired>
                            <MetaLookupComponent
                                id="vtProjectMeta"
                                name="vtProjectMeta"
                                label="Categories"
                                disabled={false}
                                isRequired={false}
                                size={"lg"}
                                fontWeight={"500"}
                                value={formik.values.vtProjectMeta}
                                onChange={formik.handleChange}

                            />
                        </FormControl>
                    </GridItem> */}


                    <GridItem colSpan={{ base: 2, sm: 1, md: 2, lg: 2 }}>
                        <FormControl >
                            <FormLabel>Project Description</FormLabel>
                            <Textarea
                                id="vtProjectDesc"
                                name="vtProjectDesc"
                                isRequired={false}
                                fontSize="sm"
                                ms={{ base: '0px', md: '0px' }}
                                placeholder="Project Description"
                                color={textColor}
                                fontWeight="500"
                                size='sm'
                                onChange={formik.handleChange}
                                value={formik.values.vtProjectDesc}
                            />



                        </FormControl>
                    </GridItem >

                    {/* <FormControl isRequired>

                        <HStack spacing={4} ml={'5px'}>
                            {['Void Works', 'Post Inspection'].map((size) => (
                                <Tag
                                    size={'md'}
                                    key={size}
                                    borderRadius='full'
                                    variant='solid'
                                    colorScheme='brand'
                                >
                                    <TagLabel>{size}</TagLabel>
                                    <TagCloseButton />
                                </Tag>

                            ))}
                        </HStack>

                    </FormControl> */}

                </SimpleGrid>
                <FormControl>
                    <Button type="submit" isLoading={isLoading} loadingText="Creating Project" color={brandStars} float={'right'} variant={'outline'} mt={'24px'}> {props.project ? 'Update' : 'Create'}</Button>
                </FormControl>
            </form >

        </>
    )
}

ProjectForm.propTypes = {
    currentUser: PropTypes.any,
    currentOrgs: PropTypes.any,
    setActiveStep: PropTypes.any,
    project: PropTypes.any
}

export default observer(ProjectForm)