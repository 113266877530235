import React from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Input, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import AssetForm from '../../forms/assets'

const AddNewAssetButton = props => {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  const textColorBrand = useColorModeValue('brand.500', 'white');

  return (
    <>


      <Button ref={btnRef} onClick={onOpen} variant={'outline'} color={textColorBrand}>New Asset</Button>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Create an Asset</DrawerHeader>

          <DrawerBody>
           <AssetForm/>
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue'>Save</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

AddNewAssetButton.propTypes = {}

export default AddNewAssetButton