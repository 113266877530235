import { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Divider, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Show, Tooltip, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import UserDetail from '../../forms/users/Details';
import { useUserStore } from '../../../contexts/RootStoreProvider';
import { FaEdit } from 'react-icons/fa';
import { AuthContext } from '../../../contexts/auth-context';


const EditUserButton = (props) => {
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const { isOpen, onOpen, onClose } = useDisclosure()
    const userStore = useUserStore();
    const { currentUser, currentOrgs } = useContext(AuthContext);

    return (
        <>
            <Tooltip label='Edit'>
                <IconButton icon={<FaEdit />} aria-label={'Edit'} size='sm' colorScheme='brand' variant='outline' onClick={onOpen} />
            </Tooltip>
            <Modal closeOnOverlayClick={false} size={'6xl'} isOpen={isOpen} isCentered onClose={onClose} motionPreset='slideInBottom' scrollBehavior={'inside'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader> {props.user ? ("Update User : " + props.user.firstName + " " + props.user.lastName) : ("New User")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <UserDetail isNew={false} user={props.user} currentUser={currentUser} currentOrgs={currentOrgs} userStore={userStore} />
                    </ModalBody>
                    <Divider />
                </ModalContent>
            </Modal >
        </>
    )
}

EditUserButton.propTypes = {
    currentUser: PropTypes.any,
    currentOrgs: PropTypes.any,
    user: PropTypes.any

}

export default EditUserButton