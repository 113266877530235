import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Chakra imports
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    InputGroup,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
// Custom components

import DefaultAuth from 'layouts/auth/variants/Default';
// Assets
import illustration from 'assets/img/logos/logo.png';

import { confirmThePasswordReset } from '../../../firebase/firebase';

const defaultFormFields = {
    password: '',
    confirmPassword: '',
}

function ResetPassword() {
    // Chakra color mode
    const textColor = useColorModeValue('navy.700', 'white');
    const textColorSecondary = 'gray.400';
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [formFields, setFormFields] = useState(defaultFormFields)
    const { password, confirmPassword } = formFields
    const [show, setShow] = React.useState(false);

    const toast = useToast();

    let oobCode: string | null = searchParams.get('oobCode')

    console.log(oobCode);

    const resetFormFields = () => {
        return (
            setFormFields(defaultFormFields)
        )
    }
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (password !== confirmPassword) {


            toast({
                title: 'Error!',
                description: "Passwords do not match!",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return;



        }

        try {
            if (oobCode) {
                await confirmThePasswordReset(oobCode, confirmPassword)
                resetFormFields()




                toast({
                    title: 'Password Reset.',
                    description: "We will now redirect you back to the login page.",
                    status: 'success',
                    duration: 6000,
                    isClosable: true,
                });

                navigate("/");





            } else {

                toast({
                    title: 'Error!',
                    description: "Error obtaining Authorisation Token, please try again later!",
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                })

            }
        } catch (error: any) {
            if (error.code === 'auth/invalid-action-code') {

                toast({
                    title: 'Error!',
                    description: "Error Resetting Password, please try again later!",
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                })

            }
            console.log(error.message)
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormFields({ ...formFields, [name]: value })
    }

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: '100%', md: 'max-content' }}
                w="100%"
                mx={{ base: 'auto', lg: '0px' }}
                me="auto"
                h="100%"
                alignItems="start"
                justifyContent="center"
                mb={{ base: '30px', md: '60px' }}
                px={{ base: '25px', md: '0px' }}
                mt={{ base: '40px', md: '14vh' }}
                flexDirection="column"
            >
                <Box me="auto">
                    <Heading color={textColor} fontSize="36px" mb="10px">
                        Reset Password
                    </Heading>

                </Box>
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{ base: '100%', md: '420px' }}
                    maxW="100%"
                    background="transparent"
                    borderRadius="15px"
                    mx={{ base: 'auto', lg: 'unset' }}
                    me="auto"
                    mb={{ base: '20px', md: 'auto' }}
                >
                    <Flex align="center" mb="25px"></Flex>

                    <form onSubmit={handleSubmit}>
                        <FormControl isRequired>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                Password
                            </FormLabel>
                            <Input
                                isRequired={true}
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: '0px', md: '0px' }}
                                type="password"
                                placeholder="New Password"
                                mb="24px"
                                fontWeight="500"
                                size="lg"
                                onChange={handleChange}
                                name="password"
                                value={password}
                            />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                display="flex"
                            >
                                Confirm Password
                            </FormLabel>
                            <InputGroup size="md">
                                <Input
                                    isRequired={true}
                                    fontSize="sm"
                                    placeholder="Confirm Password"
                                    mb="24px"
                                    size="lg"
                                    type="password"
                                    variant="auth"
                                    name="confirmPassword"
                                    onChange={handleChange}
                                    value={confirmPassword}
                                />
                            </InputGroup>
                        </FormControl>

                        <Button
                            type="submit"
                            fontSize="sm"
                            variant="brand"
                            fontWeight="500"
                            w="100%"
                            h="50"
                            mb="24px"
                        >
                            Update Password
                        </Button>
                        <div>
                            <input id="recaptcha" type="submit" />
                        </div>
                    </form>
                    <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="start"
                        maxW="100%"
                        mt="0px"
                    ></Flex>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default ResetPassword;
