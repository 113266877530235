import { BuilderStore } from './builderStore';
import { ExternalViewerStore } from './evStore';
import { OrganisationStore } from './orgStore';
import { ProjectStore } from './projectStore';
import { UserStore } from './userStore';

export class RootStore {
  projectStore: ProjectStore;
  evStore: ExternalViewerStore;
  builderStore: BuilderStore;
  userStore: UserStore;
  orgStore: OrganisationStore;
  //   assetStore: AssetStore;
  
  constructor() {
    this.projectStore = new ProjectStore(this);
    this.builderStore = new BuilderStore(this);
    this.userStore = new UserStore(this);
    this.orgStore = new OrganisationStore(this);
    this.evStore = new ExternalViewerStore(this);

    // this.assetStore = new AssetStore(this);
    //   this.sharedProjectLinkStore = new SharedProjectLinkStore(this);

    //   hydrate('user', this.UserStore).then(() => {
    //     //console.log('Dev Monster Rehydrated ');
    //   });
  }
}
