import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import admin from '../../../api/admin';
import { IMetaData } from '../../../models/metaData';
import {
    GetMetaByCategoryRequest,
    GetMetaByCategoryResponse
} from '../../../models/admin-api/dto';
import ProgressLoader from '../../progressLoader';
import { FormControl, FormHelperText, FormLabel, Select } from '@chakra-ui/react';

const ProjectTypeLookupComponent = (props: any) => {

    const [types, setTypes] = useState<IMetaData[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        var request = {
           
            metaCategory: 'Project Type'
        } as GetMetaByCategoryRequest;

        admin.Meta.GetMetaByCategory('Project','projectType')
            .then((response: GetMetaByCategoryResponse) => {
                setTypes(response.result);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <>
            {isLoading ? (
                <ProgressLoader />
            ) : (
                <FormControl isRequired={props.isRequired}>
                    <FormLabel >{props.label}</FormLabel>
                    <Select


                        id={props.id}
                        name={props.id}
                        disabled={props.disabled}
                        value={props.value}
                        onChange={props.onChange}
                       
                    >
                              <option value={null}></option>
                        {types?.length ? (
                            types.map((s: IMetaData) => (<option value={s.metaKey}>{s.metaValue}</option >))
                        ) : (
                            <option >loading...</option >
                        )}
                    </Select>
                    {props.error ? (
                        <FormHelperText
                            sx={{ color: '#bf3333', marginLeft: '16px !important' }}
                        >
                            {props.helperText}
                        </FormHelperText>
                    ) : null}
                </FormControl>
            )}
        </>
    );
};

ProjectTypeLookupComponent.propTypes = {
    error: PropTypes.bool,
    helperText: PropTypes.any,
    id: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.any,
    isRequired: PropTypes.any,
    currentUser: PropTypes.any,
    size: PropTypes.string,
    fontWeight: PropTypes.string
};

export default ProjectTypeLookupComponent;
