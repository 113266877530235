import { ButtonGroup, IconButton, Stack, Tooltip } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { CustomCellRendererProps } from "ag-grid-react";
import { FaWrench } from "react-icons/fa";
import ProgressLoader from "../../../../components/progressLoader";
import { useState } from "react";
import AddNewSharedProjectButton from "../../../../components/sharedprojects/addNewButton/addNewProjectButton";
import DeleteProjectButton from "./ConfirmButtons/deleteSharedProjectButton";
import EditProjectButton from "../../../../components/projects/editProjectButton/editProjectButton";
import { checkOrgAccess } from "../../../../helpers/authHelper";


export default (params: CustomCellRendererProps) => {


    const [isLoading, SetIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleOnClickBuild = (projectId: string, projectName: string) => {
        var link = "/builder/" + projectId;
        navigate(link, { state: projectName });
    }

    const handleOnClickDelete = async (projectId: string) => {
        SetIsLoading(true);
        var projectStore = params["projectStore"];
        projectStore.deleteProject(params.value).finally(() => {
            SetIsLoading(false);
        });
    };

    return (
        <>
            {isLoading ? <ProgressLoader /> :
                <Stack direction="row" margin={1} spacing={4}>
                    <ButtonGroup>
                        {
                            checkOrgAccess(params["currentUserRoles"], params.data?.vtProjectOrganisationId, "share") == true ? (
                                <Tooltip label='Build'>
                                    <IconButton icon={<FaWrench />} aria-label={'Build'} size='sm' colorScheme='brand' variant='outline' onClick={() => handleOnClickBuild(params.value, params.data.vtProjectName)} />
                                </Tooltip>) : (<></>)
                        }

                        {
                            checkOrgAccess(params["currentUserRoles"], params.data?.vtProjectOrganisationId, "share") == true ? (
                                <AddNewSharedProjectButton projectId={params.value} projectStore={params["projectStore"]} />) : (<></>)}
                        {
                            checkOrgAccess(params["currentUserRoles"], params.data?.vtProjectOrganisationId, "build") == true ? (
                                <EditProjectButton currentOrgs={params["currentOrgs"]} currentUser={params["currentUser"]} project={params.data} />
                            ) : (<></>)
                        }{
                            checkOrgAccess(params["currentUserRoles"], params.data?.vtProjectOrganisationId, "delete") == true ? (
                                <DeleteProjectButton handleAction={() => handleOnClickDelete(params.value)} />
                            ) : (<></>)
                        }

                    </ButtonGroup>
                </Stack>
            }

        </>




    )
}