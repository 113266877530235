// Chakra imports
import {
    Box,
    Button,
    Flex,
    Image,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { IScene } from '../../../models/scene';


export default function SceneCard(props: {
    sceneObj: IScene;
    goSceneOnClick: any;
    onClose: any;

}) {
    const { sceneObj, goSceneOnClick, onClose } = props;
    const textColor = useColorModeValue('navy.700', 'white');

    const handleGoToScene = () => {
       
        goSceneOnClick(sceneObj.name);
        onClose();
    }

    return (
        <Card p="20px">
            <Flex direction={{ base: 'column' }} justify="center">
                <Box mb={{ base: '20px', '2xl': '20px' }} position="relative">
                    <Image
                        src={sceneObj.previewURL}
                        w={{ base: '100%', '3xl': '100%' }}
                        h={{ base: '100%', '3xl': '100%' }}
                        borderRadius="4px"
                    />
                </Box>
                <Flex flexDirection="column" justify="space-between" h="100%">

                    <Text mb={2} fontWeight="700" fontSize="sm" color={textColor}>
                        {sceneObj.name}
                    </Text>

                    <Button
                        variant="brand"
                        color="white"
                        fontSize="sm"
                        fontWeight="500"
                        borderRadius="6px"
                        onClick={handleGoToScene}
                    >
                        Go to Scene
                    </Button>
                </Flex>
            </Flex>
        </Card>
    );
}
