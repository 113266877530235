import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Box, Button, Divider, Heading, Link, Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tooltip, Tr, useToast } from '@chakra-ui/react'
import { IProjectItem } from '../../models/vtProject'
import { useBuilderStore } from '../../contexts/RootStoreProvider'
import { useNavigate } from 'react-router-dom'
import { MdImage } from 'react-icons/md'

const ProjectLinkedItemsView = (props: any) => {

    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const builderStore = useBuilderStore();
    const navigate = useNavigate();


    const handleLoadSceneOnClick = async (
        vtProjId: any,
        sceneId: string,
        hotspotId: string
    ) => {

        setIsLoading(true);

        // var valProjForView = await props.ProjectStore.viewProject(vtProjId);
        // if (valProjForView !== undefined) {

        builderStore.startViewer(vtProjId).then((response: any) => {
            setIsLoading(false);
            navigate('/projects/viewer/' + vtProjId, { state: { sceneId: sceneId, hotspotId: hotspotId, tourXml: response.tourXml } });
        });
    };


    return (
        <>
            <Box margin={3} overflowY={'auto'} maxHeight={300}>
                {/* <Heading marginBottom={2} size={'sm'}>Project Items  </Heading><Divider /> */}
                <TableContainer>
                    <Table size={'sm'} variant={'striped'} >
                        <Thead>
                            <Tr>
                                <Th>View In Scene</Th>
                                <Th>Preview</Th>
                                <Th>Scene</Th>
                                <Th>Type</Th>
                                <Th>Created By</Th>

                            </Tr>
                        </Thead>

                        <Tbody>


                            {props.ProjectItems.map((item: IProjectItem) => (

                                <Tr>
                                    <Td>
                                        <Tooltip label={"View in Tour"}>
                                            <Button onClick={() => handleLoadSceneOnClick(props.ProjectId, item.projectSceneName, item.projectHotspotName)} colorScheme='brand' variant='link'>
                                                View
                                            </Button>
                                        </Tooltip>
                                    </Td>
                                    <Td>
                                    {item.previewUrl ?  (<Avatar src={item.previewUrl} /> ) : (<MdImage />)}
                                    </Td>
                                    <Td>
                                        {item.projectSceneName}
                                    </Td>
                                    <Td>
                                        {item.type}
                                    </Td>
                                    <Td>
                                        {item.createdBy}
                                    </Td>

                                </Tr>
                            )
                            )}
                        </Tbody>

                    </Table>

                </TableContainer>
            </Box >
        </>
    )
}

ProjectLinkedItemsView.propTypes = {
    ProjectItems: PropTypes.any,
    ProjectStore: PropTypes.any,
    ProjectId: PropTypes.string,
    currentUserId: PropTypes.string
}

export default ProjectLinkedItemsView