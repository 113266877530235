import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Tooltip } from '@chakra-ui/react';
import HotspotDialogScenesContent from './scene';
import { ArrowUpIcon, DeleteIcon } from '@chakra-ui/icons';
import HotspotDialogMediaContent from './media';
import HotspotDialogNoteContent from './note';
import HotspotDialogIframeContent from './iframe';
import ConfirmButton from '../../../confirmButton';
import { observer } from 'mobx-react';
import HotspotDialogAssetContent from './asset';

const HotspotDialog = (props) => {


    useEffect(() => {

        switch (props.hotspotType.toString()) {
            case "hotspot":
                setAssociatedName(props.builderStore.currentHotspot?.linkedScene! || "Not Associated");
                break;
            case "media":
                setAssociatedName(props.builderStore.currentHotspot?.linkedAsset! !== "" ? "Associated" : "Not Associated");
                break;
            case "video":
                setAssociatedName(props.builderStore.currentHotspot?.linkedAsset! !== "" ? "Associated" : "Not Associated");
                break;
            case "asset":
                setAssociatedName(props.builderStore.currentHotspot?.linkedAsset! !== "" ? "Associated" : "Not Associated");
                break;

            case "iframe":
                setAssociatedName(props.builderStore.currentHotspot?.text! !== "" ? "Associated" : "Not Associated");
                break;
            case "weburl":
                setAssociatedName(props.builderStore.currentHotspot?.text! !== "" ? "Associated" : "Not Associated");
                break;
            case "note":
                setAssociatedName(props.builderStore.currentHotspot?.text! !== "" ? "Filled Out" : "Empty");
                break;
            default:
                setAssociatedName("Not Associated");
                break;
        }
    }
        , [props.builderStore.currentHotspot])



    const [associatedName, setAssociatedName] = useState<string>();


    const handleGoToScene = (e: string) => {
        const scene = e;
        if (props.builderStore.isLoading) { alert("please wait") }
        else {
            props.builderStore.setCurrentScene(scene);
            props.handleClose();
        }
    };


    const removeHotspot = () => {
        props.builderStore.removeHotspot();
    };


    const getHotspotComponent = (type: string) => {
        switch (type) {

            case 'hotspot':
                return (
                    <HotspotDialogScenesContent handleClose={props.handleClose} userId={props.userId} builderStore={props.builderStore} selectedHotspot={props.builderStore.currentHotspot} />
                );
            case 'note':
                return (
                    <HotspotDialogNoteContent handleClose={props.handleClose} userId={props.userId} builderStore={props.builderStore} selectedHotspot={props.builderStore.currentHotspot} />
                );
            case 'iframe':
                return (
                    <HotspotDialogIframeContent handleClose={props.handleClose} userId={props.userId} builderStore={props.builderStore} selectedHotspot={props.builderStore.currentHotspot} />
                );
            case 'weburl':
                return (
                    <HotspotDialogIframeContent handleClose={props.handleClose} userId={props.userId} builderStore={props.builderStore} selectedHotspot={props.builderStore.currentHotspot} />
                );
            case 'media':
                return (
                    <HotspotDialogMediaContent handleClose={props.handleClose} userId={props.userId} builderStore={props.builderStore} selectedHotspot={props.builderStore.currentHotspot} />
                );
            case 'video':
                return (
                    <HotspotDialogMediaContent handleClose={props.handleClose} userId={props.userId} builderStore={props.builderStore} selectedHotspot={props.builderStore.currentHotspot} />
                );
            case 'asset':
                return (
                    <HotspotDialogAssetContent handleClose={props.handleClose} userId={props.userId} builderStore={props.builderStore} selectedHotspot={props.builderStore.currentHotspot} />
                );

            default:
                return <>{'Missing Step'}</>;
        }
    };


    return (
        <Modal isOpen={props.open} onClose={props.handleClose} motionPreset="slideInBottom" blockScrollOnMount={false} autoFocus={false} size={'6xl'} returnFocusOnClose={false} isCentered scrollBehavior={'inside'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Edit Hotspot ({associatedName}) </ModalHeader>
                <ModalCloseButton />
                <ModalBody overflowX={'hidden'} >
                    {getHotspotComponent(props.hotspotType)}
                </ModalBody>
                <ModalFooter>
                    <Stack direction='row' spacing={4}>

                        {props.hotspotType == "hotspot" && props.builderStore.currentHotspot?.linkedScene! !== undefined ? (

                            <Tooltip label={'Go to Scene ' + props.hotspotType}>
                                <Button leftIcon={<ArrowUpIcon />} variant={"brand"} onClick={() => handleGoToScene(props.builderStore.currentHotspot?.linkedScene!)} aria-label={'Remove ' + props.hotspotType}> Go To Scene </Button>
                            </Tooltip>
                        ) : (<></>)}


                        <ConfirmButton icon={<DeleteIcon />} handleAction={() => removeHotspot()} actionButtonText={'Remove ' + props.hotspotType} colorScheme='red' confirmText='Are you sure you want to remove this hotspot?' />


                    </Stack>
                </ModalFooter>
            </ModalContent>
        </Modal>

    )
}

HotspotDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.any,
    hotspotType: PropTypes.string,
    handleRemove: PropTypes.any,
    userId: PropTypes.any,
    builderStore: PropTypes.any
}

export default observer(HotspotDialog)