import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, IconButton, Tooltip, useDisclosure } from "@chakra-ui/react"
import React from "react"
import { FaTrash } from "react-icons/fa"
import PropTypes from 'prop-types'

const DeleteSharedProjectButton = props => {


    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()
    return (
        <>


            <Tooltip label='Delete'>
                <IconButton icon={<FaTrash />} aria-label={'Delete'} size='sm' colorScheme='red' variant='outline' onClick={onOpen} />
            </Tooltip>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete Shared Project
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to delete this Shared Project? it.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={() => props.handleAction()} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

DeleteSharedProjectButton.propTypes = {
    handleAction: PropTypes.any
}

export default DeleteSharedProjectButton