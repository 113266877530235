import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup, Flex, IconButton, Input, Spacer, useColorModeValue } from '@chakra-ui/react'
import { AgGridReact } from 'ag-grid-react'
import { SearchIcon } from '@chakra-ui/icons'
import AddNewAssetButton from '../../../assets/addNewAssetButton'

const AssetList = props => {



    const textColorBrand = useColorModeValue('brand.500', 'white');


    const colDefs = [
        { headerName: "Name", field: "name", flex: 2 },
        { headerName: "Description", field: "description", flex: 1, enableRowGroup: true },
        { headerName: "Type", field: "type", flex: 1, enableRowGroup: true },
        { headerName: "Manufacturer", field: "manufacturer", flex: 1 },
        { headerName: "SerialNo", field: "serialNo", flex: 1, enableRowGroup: true },

    ];



    const handleOnSelectAsset = (e: any) => {

        if (e) {
            props.handleAction(e.data)
        }
    }

    return (
        <div
            className="ag-theme-quartz" // applying the grid theme
            style={{ height: 400 }} // the grid will fill the size of the parent container
        >
            <>

                <Flex>
                    <ButtonGroup marginBottom={1}>
                        <Input placeholder='Search for Asset' width={'100%'} /> <IconButton
                            color={textColorBrand}
                            aria-label='Search Assets'
                            icon={<SearchIcon />}
                        />

                    </ButtonGroup>
                    <Spacer />

                    <AddNewAssetButton />

                </Flex>




            </>
            <AgGridReact
                rowData={props.assets}
                columnDefs={colDefs as any}
                onRowClicked={handleOnSelectAsset}
                rowSelection={'single'}

            />
        </div>
    )
}

AssetList.propTypes = {
    assets: PropTypes.any,
    handleAction: PropTypes.any
}

export default AssetList